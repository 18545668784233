//External
import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { catchError, map, Observable, of, Subject, takeUntil } from 'rxjs';

// Internal
import { ModalName, ModalRoutelessService } from '../../../../common/components/ui/ui-modal-routeless/modal.routeless.service';
import { AppsConfigService } from '../../../../common/config/apps.config.service';
import { ConfigService } from '../../../../common/config/config.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { ReferralValuesService } from '../../../../common/values/referral.values.service';
import { SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';
import { ProductsToInstall, ValuesService } from '../../../../common/values/values.service';
import { MessageService } from '../../core/message.service';
import { QueryParamsService } from '../../core/query.params.service';
import { UsefulService } from '../../global/useful/useful.service';
import { UrlProperties } from '../../guards/models/UrlProperties.model';
import { DevicesService } from '../../process/devices/devices.service';
import { ProfilesService } from '../../process/profiles/profiles.service';
import { SettingsService } from '../../process/settings/settings.service';
import { RedeemService } from '../../process/subscriptions/redeem.service';
import { SubscriptionsService } from '../../process/subscriptions/subscriptions.service';
import { SubscriptionsTrialService } from '../../process/subscriptions/subscriptionTrial.service';
import { ConnectReferralService } from '../../requests/connect-referral/connect-referral.service';
import { False, ModalAction } from './models/special.cases.model';
import { SubscriptionInviteService } from '../../requests/subscription-invite-service/subscription-invite.service';
import { ModalSize, AllModalInformation, ModalContainerOptions } from '../../global/modal/Modal.model';
import { InvitesService } from '../../process/subscriptions/invites.service';
import { CommercialIdsMgmtService } from '../../requests/connect-commercial-ids-service/connect-commercial-ids.service';
import { RedeemDataModel } from '../../../models/Services.model';
import { ConversionStatus, SecuredPaymentScenarios } from '../../../models/subscriptions/Subscriptions.model';
import { ISubscriptionsService } from '../../interfaces/isubscriptions/isubscriptions.service';

@Injectable({
    providedIn: 'root'
})

export class SpecialCasesService {

    private readonly onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private readonly profilesService: ProfilesService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly subscriptionsService: SubscriptionsService,
        private readonly settingsService: SettingsService,
        private readonly modalRoutelessService: ModalRoutelessService,
        private readonly appsConfigService: AppsConfigService,
        private readonly deviceDetectorService: DeviceDetectorService,
        private readonly queryParamsService: QueryParamsService,
        private readonly connectReferralService: ConnectReferralService,
        private readonly referralValuesService: ReferralValuesService,
        private readonly messageService: MessageService,
        private readonly usefulService: UsefulService,
        private readonly redeemService: RedeemService,
        private readonly inviteService: InvitesService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly subscriptionsTrialService: SubscriptionsTrialService,
        private readonly subscriptionInviteService: SubscriptionInviteService,
        private readonly commercialIdsMgmtService: CommercialIdsMgmtService,
        private readonly iSubscriptionsService: ISubscriptionsService,
        private readonly devicesService: DevicesService
    ) {}

    /**
     * This function will decide if the account is MSP type and the specific modals will be shown
     * 1. Daca are msp lvl 1, e pe dashboard si e prima oara pe cont -> afisam onboarding
     * 2. Daca are msp lvl 1, e pe dashboard si nu e prima oara pe cont dar are sub -> afisam install
     * 3. Daca are msp lvl 1, e pe dashboard si nu e prima oara pe cont dar nu are sub -> afisam onboarding
     * 4. Daca are msp lvl 2, e prima oara pe cont si are subscriptie managed -> afisam onboarding de msp
     * 5. Daca are msp lvl 2, e prima oara pe cont si nu are subscriptie managed -> nu afisam nimic
     * @param {UrlProperties} urlProperties
     * @return {*}  {(Observable<boolean|UrlTree>)}
     */
    public _decideMspXspState(urlProperties: UrlProperties): Observable<False|ModalAction> {
        return new Observable( subscriber => {
            const hasMspOrXspLevelOne   = this.profilesService.hasMspOrXspLevelOne();
            const hasMspOrXspLevelTwo   = this.profilesService.hasMspOrXspLevelTwo();
            const firstPathSegment      = `/${urlProperties.pathPieces[0]}`;
            const dashboardPath         = this.valuesService.centralPaths.dashboard.path;
            const hasOnboardingMSP      = this.configService.config.onboardingMSP;
            const hasSubscription       = this.subscriptionsService.getFilteredActiveBundles().length;
            const isFirstLogin          = this.settingsService.isFirstLogin();
            const storageFirstLoginMade = sessionStorage.getItem(this.valuesService.sessionStorageModalStatus.firstLoginMade);

            if (hasMspOrXspLevelOne && firstPathSegment === dashboardPath) {
                if (isFirstLogin && hasOnboardingMSP && !storageFirstLoginMade) {
                    this.settingsService.setBoarded()
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe()
                    .add(
                        () => {
                            if (!this.subscriptionsService.getNoBundles()) {
                                setTimeout(() => {
                                    this.subscriptionsService.updateSubscriptions();
                                    this.subscriptionsService.list()
                                    .pipe(takeUntil(this.onDestroy$))
                                    .subscribe()
                                    .add(
                                        () => {
                                            this.subscriptionsService.processSubscriptions();
                                            this.subscriptionsService.populateFilteredSubscriptionsStructures();
                                            this.subscriptionsService.setHasZuora();
                                            this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL, true);
                                            const response: AllModalInformation = {
                                                name: ModalName.onboardingMsp,
                                                containerOptions: {},
                                                contentOptions: {}
                                            };
                                            subscriber.next(response);
                                            subscriber.complete();
                                        });
                                }, 5000);
                            } else {
                                const response: AllModalInformation = {
                                    name: ModalName.onboardingMsp,
                                    containerOptions: {},
                                    contentOptions: {}
                                };
                                subscriber.next(response);
                                subscriber.complete();
                            }
                        }
                    );
                } else {
                    if (hasSubscription && this.appsConfigService.getShowInstallButtonForAllApps()) {
                        const devicesNo = this.devicesService.getDevicesNr();
                        if (devicesNo) {
                            // When devices exist on account we do not show install protection modal
                            subscriber.next(false);
                            subscriber.complete();
                            return;
                        }

                        if (this.modalRoutelessService.getBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL)) {
                            subscriber.next(false);
                            subscriber.complete();
                            return;
                        }

                        const response: AllModalInformation = {
                            name: ModalName.installModal,
                            containerOptions: {
                                size: ModalSize.LG
                            },
                            contentOptions: {}
                        };
                        this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_INSTALL_MODAL, true);
                        subscriber.next(response);
                        subscriber.complete();
                    } else {
                        if (urlProperties.pathPieces[0] === this.valuesService.centralPaths.dashboard.id && hasOnboardingMSP) {
                            if (this.modalRoutelessService.getBufferItem(this.valuesService.modalBufferKeys.MSP_ONBOARDING)) {
                                subscriber.next(false);
                                subscriber.complete();
                            } else {
                                const response: AllModalInformation = {
                                    name: ModalName.onboardingMsp,
                                    containerOptions: {},
                                    contentOptions: {}
                                };
                                this.modalRoutelessService.setBufferItem(this.valuesService.modalBufferKeys.MSP_ONBOARDING, true);
                                subscriber.next(response);
                                subscriber.complete();
                            }
                        } else {
                            subscriber.next(false);
                            subscriber.complete();
                        }
                    }
                }
            } else if (hasMspOrXspLevelTwo && hasOnboardingMSP && isFirstLogin && !storageFirstLoginMade) {
                this.markOnboardingAsDone()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(resp => {
                    subscriber.next(resp);
                    subscriber.complete();
                });
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Mark onboarding as done and decide if onboarding msp modal should be shown
     * @private
     * @memberof SpecialCasesService
     * @returns {Observable}
     */
    private markOnboardingAsDone(): Observable<False|ModalAction> {
        return new Observable(subscriber => {
            this.settingsService.setBoarded()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe()
            .add(() => {
                if (!this.subscriptionsService.getNoBundles()) {
                    setTimeout(() => {
                        this.redoSubscriptionsRequestForOnboarding()
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe(resp => {
                            subscriber.next(resp);
                            subscriber.complete();
                        });
                    }, 7000);
                } else {
                    const response = this.subscriberValueForOnboarding();
                    subscriber.next(response);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * Makes 'list' subscriptions request and decide if onboarding msp modal should be shown
     * @private
     * @memberof SpecialCasesService
     * @returns {Observable}
     */
    private redoSubscriptionsRequestForOnboarding(): Observable<False|ModalAction> {
        return new Observable(subscriber => {
            this.subscriptionsService.updateSubscriptions();
            this.subscriptionsService.list()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.subscriptionsService.processSubscriptions();
                    this.subscriptionsService.populateFilteredSubscriptionsStructures();
                    this.subscriptionsService.setHasZuora();

                    const response = this.subscriberValueForOnboarding();
                    subscriber.next(response);
                    subscriber.complete();
                },
                error: () => {
                    subscriber.next(false);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * Decides if onboarding msp modal should be opened
     * @private
     * @memberof SpecialCasesService
     * @returns {ModalAction|boolean} Returns an object if the modal should be displayed and 'false' otherwise
     */
    private subscriberValueForOnboarding(): False|ModalAction {
        if (this.subscriptionsService.hasMspManagedBundle()) {
            return {
                name: ModalName.onboardingMsp,
                containerOptions: {},
                contentOptions: {}
            };
        }
        return false;
    }

    public _decideReferral(urlProperties: UrlProperties): Observable<boolean|any> {
        return new Observable( subscriber => {
            const referralCode = urlProperties.queryObject.referralCode || this.queryParamsService.get('referral_code');

            if (referralCode) {
                this.connectReferralService.applyReferral(referralCode)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: resp => {
                        this.subscriptionsService.updateSubscriptions();
                        //! Trebuie? Daca da nu aici.. :(
                        this.messageService.sendMessage(this.valuesService.events.triggerStart, {});

                        const response: AllModalInformation = {
                            name: ModalName.referralCongratsModal,
                            containerOptions: {
                                size: ModalSize.SM
                            },
                            contentOptions: {
                                referralResponse: resp,
                                case: this.referralValuesService.referralCongratsCase.CONGRATS_ACTIVATE
                            }
                        };
                        // this.referralCode = null;
                        this.queryParamsService.remove(['referral_code']);
                        subscriber.next(response);
                        subscriber.complete();
                    },
                    error: err => {
                        const modalContent = {
                            case: this.referralValuesService.referralCongratsCase.ERROR_ACTIVATION
                        };

                        // trebuie sa tinem cont si de 'data' pentru ca nu avem un cod diferit pt aceasta eroare
                        // nu se poate schimba in connect pentru ca se strica compatibilitatea cu BMS-ul...
                        const errorCode = this.usefulService.getNested(err, null, 'error', 'data', 'code');
                        const errorMessage = this.usefulService.getNested(err, null, 'error', 'data', 'data');

                        if (errorCode === this.referralValuesService.codeError.code && this.referralValuesService.congratsCaseBasedOnError[errorMessage]) {
                            modalContent.case = this.referralValuesService.congratsCaseBasedOnError[errorMessage];
                        }

                        const response: AllModalInformation = {
                            name: ModalName.referralCongratsModal,
                            containerOptions: {
                                size: ModalSize.SM
                            },
                            contentOptions: modalContent
                        };
                        // this.referralCode = null;
                        this.queryParamsService.remove(['referral_code']);
                        subscriber.next(response);
                        subscriber.complete();

                    }
                });
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    automaticRedeemActivate(redeemCode, checkRedeemResponse, urlProperties) {
        const bundleId = this.usefulService.getNested(checkRedeemResponse, null, 'data', 'bundle_id');
        return this.redeemService.activateRedeemCode(redeemCode)
        .pipe(
            map(
                resp => {
                    const reason   = this.usefulService.getNested(resp, null, 'reason');
                    const response = this.redeemService.handleRedeemResponse(reason);
                    if (response.valid) {
                        let scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION;

                        if (this.valuesService.identityProducts.has(bundleId)) {
                            scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_IDENTITY;
                        }

                        if (bundleId === this.valuesService.bundlePM) {
                            scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_PASSMANAGER;
                        }

                        return {
                            name: ModalName.onboardingModal,
                            containerOptions: {
                                size: ModalSize.LG,
                                backdropDismissable: false
                            },
                            contentOptions: {
                                redeemResponse: {
                                    ...checkRedeemResponse,
                                    service_id: resp?.service_id ?? null
                                },
                                scenario: scenario
                            }
                        } as AllModalInformation;
                    } else {
                        return {
                            name: ModalName.onboardingModal,
                            containerOptions: {
                                size: ModalSize.LG,
                                backdropDismissable: false
                            },
                            contentOptions: {
                                redeemCode: redeemCode,
                                errorObject: response,
                                scenario: this.valuesService.onboardingScenario['ERROR']
                            }
                        } as AllModalInformation;
                    }
                }
            ),
            catchError(err => {
                if (err.message) {
                    const response = this.redeemService.handleRedeemResponse(err.message);
                    if (!response.valid) {
                        const _response: AllModalInformation = {
                            name: ModalName.onboardingModal,
                            containerOptions: {
                                size: ModalSize.LG,
                                backdropDismissable: false
                            },
                            contentOptions: {
                                redeemCode: redeemCode,
                                errorObject: response,
                                scenario: this.valuesService.onboardingScenario['ERROR']
                            }
                        };
                        return of(_response);
                    } else {
                        return of(false);
                    }
                } else {
                    return of(false);
                }
            })
        );
    }

    /**
     * Checks if subscription is eligible for conversion and decides what modal to open based on server response
     * @private
     * @memberof SpecialCasesService
     * @param {RedeemDataModel} redeemResponse The object containing the redeem response data
     * @param {string} redeemCode The redeem code used to activate the subscription
     * @param {boolean} hasCompatibleBundles The flag that shows if the subscription has compatible bundles
     * @returns {Observable} The modal that should be opened
     */
    private getSubscriptionConversion(redeemResponse: RedeemDataModel, redeemCode: string, hasCompatibleBundles: boolean): Observable<any> {
        const openModal: AllModalInformation = {
            name: ModalName.onboardingModal,
            containerOptions: {
                size: ModalSize.LG
            },
            contentOptions: {
                redeemResponse,
                scenario: this.valuesService.onboardingScenario.CONFIRM_ACTIVATION,
                redeemCode
            }
        };

        return this.commercialIdsMgmtService.getConversionStatus(redeemResponse?.data?.source?.partner_id, redeemResponse?.data?.commercial_id)
        .pipe(
            map(response => {
                const bundleValidity = this.iSubscriptionsService.daysRemaining(redeemResponse?.data);
                if ((response === ConversionStatus.OPTIONAL && bundleValidity >= this.subscriptionsValuesService.year)
                    || response === ConversionStatus.MANDATORY) {
                    openModal.name = ModalName.autoRenewalModal;
                    return openModal;
                } else if (hasCompatibleBundles) {
                    return openModal;
                } else {
                    throw of(response);
                }
            }),
            catchError(error => {
                if (hasCompatibleBundles) {
                    return of(openModal);
                } else {
                    throw of(error);
                }
            })
        );
    }

    /**
     * Decides if any modal should open and what flow should be accessed (onboarding/redeemconfirmation/autorenewal)
     * @private
     * @memberof SpecialCasesService
     * @param {string} redeemCode The redeem code used to activate the subscription
     * @param {UrlProperties} urlProperties The object that contains all url properties
     * @returns {Observable} The info about what modal should open, or not
     */
    private _processAutomaticRedeemRedirect(redeemCode: string, urlProperties: UrlProperties): Observable<boolean|any> {
        return new Observable( subscriber => {

            this.redeemService.checkRedeemCode(redeemCode)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {
                    const checkRedeemResponse = {...resp};
                    const reason = resp?.reason;
                    const serviceId = resp?.service_id;
                    const bundle = resp?.data;
                    const bundleId = bundle?.bundle_id;
                    const response = this.redeemService.handleRedeemResponse(reason);

                    if (response?.valid
                        && bundleId
                        && !this.utilsCommonService.isEmptyObject(bundle)) {
                        const isEligibleForConversion = this.subscriptionsService.isEligibleForOfflineToOnlineConversion(bundle);
                        const hasCompatibleBundles = this.redeemService.hasCompatibleBundles(resp);
                        const hasAutoRenewalFlow = this.configService.getAutoRenewalFlow();

                        // Check if subscription has conversion and redirect user to auto renewal flow.
                        // If not, continue to onboarding flow.
                        if (isEligibleForConversion && hasAutoRenewalFlow) {
                            this.getFlowForConversionEligibility(checkRedeemResponse, redeemCode, hasCompatibleBundles, urlProperties)
                            .pipe(takeUntil(this.onDestroy$))
                            .subscribe(_responseConversion => {
                                subscriber.next(_responseConversion);
                                subscriber.complete();
                            });
                        } else if (hasCompatibleBundles) {
                            const _responseOnboarding: AllModalInformation = {
                                name: ModalName.onboardingModal,
                                containerOptions: {
                                    size: ModalSize.LG,
                                    backdropDismissable: false
                                },
                                contentOptions: {
                                    redeemResponse: checkRedeemResponse,
                                    scenario: this.valuesService.onboardingScenario.CONFIRM_ACTIVATION,
                                    redeemCode
                                }
                            };

                            subscriber.next(_responseOnboarding);
                            subscriber.complete();
                        } else {
                            if (serviceId && checkRedeemResponse.data) {
                                checkRedeemResponse.data.service_id = serviceId;
                            }

                            this.automaticRedeemActivate(redeemCode, checkRedeemResponse, urlProperties)
                            .pipe(takeUntil(this.onDestroy$))
                            .subscribe({
                                next: res => {
                                    subscriber.next(res);
                                    subscriber.complete();
                                },
                                error: err => {
                                    subscriber.next(err);
                                    subscriber.complete();
                                }
                            });
                        }
                    } else {
                        let service = null;
                        if (serviceId) {

                            service = this.redeemService.getService(serviceId);
                            if (service && !this.subscriptionsService.isExpired(service)) {

                                const _response: AllModalInformation = {
                                    name: ModalName.redeemConfirmationModal,
                                    containerOptions: {
                                        size: ModalSize.MD
                                    },
                                    contentOptions: {
                                        redeemResponse: {data: service},
                                        alreadyActive: true,
                                        scenario: this.valuesService.onboardingScenario.CONGRATULATIONS_ACTIVATE
                                    }
                                };
                                subscriber.next(_response);
                                subscriber.complete();
                            } else {
                                const expiredResponse = this.redeemService.handleRedeemResponse(this.subscriptionsValuesService.redeemReasons.expired);

                                const _response: AllModalInformation = {
                                    name: ModalName.onboardingModal,
                                    containerOptions: {
                                        size: ModalSize.LG,
                                        backdropDismissable: false
                                    },
                                    contentOptions: {
                                        redeemCode,
                                        errorObject: expiredResponse,
                                        scenario: this.valuesService.onboardingScenario.ERROR
                                    }
                                };
                                subscriber.next(_response);
                                subscriber.complete();
                            }
                        } else {
                            const _response: AllModalInformation = {
                                name: ModalName.onboardingModal,
                                containerOptions: {
                                    size: ModalSize.LG,
                                    backdropDismissable: false
                                },
                                contentOptions: {
                                    redeemCode,
                                    errorObject: response,
                                    scenario: this.valuesService.onboardingScenario.ERROR
                                }
                            };
                            subscriber.next(_response);
                            subscriber.complete();
                        }
                    }
                },
                error: () => {
                    subscriber.next(false);
                    subscriber.complete();
                }
            });
        });
    }

    /**
     * Get flow for bundle eligible for conversion, or continue to existing onboarding flow
     * @private
     * @memberof SpecialCasesService
     * @param {RedeemDataModel} redeemResponse The redeem data object
     * @param {string} redeemCode The redeem code
     * @param {bundle} hasCompatibleBundles Shows if there are compatible bundles with the new one added
     * @param {UrlProperties} urlProperties The object that contains all url properties
     * @returns {Observable} The modal that should be opened
     */
    private getFlowForConversionEligibility(redeemResponse: RedeemDataModel, redeemCode: string, hasCompatibleBundles: boolean, urlProperties: UrlProperties): Observable<any> {
        return new Observable(subscriber => {
            this.getSubscriptionConversion(redeemResponse, redeemCode, hasCompatibleBundles)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {
                    subscriber.next(resp);
                    subscriber.complete();
                },
                error: () => {
                    this.automaticRedeemActivate(redeemCode, redeemResponse, urlProperties)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: res => {
                            subscriber.next(res);
                            subscriber.complete();
                        },
                        error: err => {
                            subscriber.next(err);
                            subscriber.complete();
                        }
                    });
                }
            });
        });
    }

    /**
     * @private
     * @param {UrlProperties} urlProperties
     * @return {*}  {(Observable<boolean|UrlTree>)}
     */
    public _decideAutomaticRedeemRedirect(urlProperties: UrlProperties): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            const service = urlProperties.queryObject.service || this.queryParamsService.get('service');
            const code    = urlProperties.queryObject.code    || this.queryParamsService.get('code');

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (service && code && service === this.valuesService.paramsModalService.ADD_CODE) {
                if (this.profilesService.ownerHasSubsV4()) {
                    this._processAutomaticRedeemRedirect(code, urlProperties)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(res => {
                        this.queryParamsService.remove(['service', 'code']);
                        subscriber.next(res);
                        subscriber.complete();
                    });
                }
                else {
                    this._processAutomaticRedeemRedirect(code, urlProperties)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(res => {
                        this.queryParamsService.remove(['service', 'code']);
                        subscriber.next(res);
                        subscriber.complete();
                    });
                    // Do the same redirect but to the new page.
                }
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    automaticTrialActivate(trialId, subscription, urlProperties) {
        const bundleId = this.usefulService.getNested(subscription, null, 'bundle_id');
        return this.subscriptionsTrialService.activateTrialId(trialId)
        .pipe(
            map(resp => {
                const reason   = this.usefulService.getNested(resp, null, 'reason');
                const response = this.subscriptionsTrialService.handleTrialResponse(reason);
                if (response.valid) {
                    let scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION;

                    if (this.valuesService.identityProducts.has(bundleId)) {
                        scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_IDENTITY;
                    }

                    if (bundleId === this.valuesService.bundlePM) {
                        scenario = this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_PASSMANAGER;
                    }

                    const redeemData: RedeemDataModel = { data: subscription };

                    return {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            redeemResponse: redeemData,
                            trial: true,
                            scenario: scenario
                        }
                    } as AllModalInformation;
                } else {
                    return {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            trialId,
                            errorObject: response,
                            scenario: this.valuesService.onboardingScenario['ERROR_TRIAL']
                        }
                    } as AllModalInformation;
                }
            }),
            catchError(err => {
                const response = this.subscriptionsTrialService.handleTrialResponse(err.reason);
                if (!response.valid) {
                    const _response = {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            trialId,
                            errorObject: response,
                            scenario: this.valuesService.onboardingScenario['ERROR_TRIAL']
                        }
                    } as AllModalInformation;
                    return of(_response);
                } else {
                    return of(false);
                }
            })
        );
    }

    _processAutomaticTrialRedirect(trialId, urlProperties): Observable<boolean|any> {
        return new Observable( subscriber => {

            this.subscriptionsTrialService.checkTrialId(trialId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {

                    this.automaticTrialActivate(trialId, resp, urlProperties)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: resp => {
                            subscriber.next(resp);
                            subscriber.complete();
                        }
                    });
                },
                error: err => {
                    const response = this.subscriptionsTrialService.handleTrialResponse(err.reason);
                    if (!response.valid) {
                        const _response = {
                            name: ModalName.onboardingModal,
                            containerOptions: {
                                size: ModalSize.LG,
                                backdropDismissable: false
                            },
                            contentOptions: {
                                trialId,
                                errorObject: response,
                                scenario: this.valuesService.onboardingScenario['ERROR_TRIAL']
                            }
                        };
                        subscriber.next(_response);
                        subscriber.complete();
                    } else {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                }
            });
        });
    }

    _decideAutomaticTrialRedirect(urlProperties: UrlProperties): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            const service = urlProperties.queryObject.service || this.queryParamsService.get('service');
            const code    = urlProperties.queryObject.code    || this.queryParamsService.get('code');

            if (service && code && service === this.valuesService.paramsModalService.ADD_TRIAL) {
                this.queryParamsService.remove(['service', 'code']);
                const automaticTrialId = code;

                if (this.profilesService.ownerHasSubsV4()) {
                    this._processAutomaticTrialRedirect(automaticTrialId, urlProperties)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(res => {
                        subscriber.next(res);
                        subscriber.complete();
                    });
                } else {
                    subscriber.next(false);
                    subscriber.complete();
                    // Do the same redirect but to the new page.
                }
            }
            else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    /**
     * Process automatic invite redirect and open onboarding modal for corresponding scenario
     *
     * @private
     * @memberof SpecialCasesService
     * @param {String} inviteId representing the invite link sent on email
     * @returns {Observable}
     */
    private _processAutomaticInviteRedirect(inviteId: string): Observable<boolean|any> {
        return new Observable( subscriber => {
            this.subscriptionInviteService.activateInvite(inviteId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: resp => {
                    const _response: AllModalInformation = {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            scenario: this.valuesService.onboardingScenario.AUTOMATIC_ACTIVATION_INVITE,
                            bundleId: resp?.bundle_id,
                            bundleName: resp?.bundle_friendly_name,
                            inviteId
                        }
                    };
                    subscriber.next(_response);
                    subscriber.complete();
                },
                error: err => {
                    const errorObject = err?.error?.data || err?.message?.internal_data;
                    if (errorObject) {
                        const errorData = this.inviteService.handleInviteServiceErrorResponse(errorObject);
                        if (errorData) {
                            const _response = {
                                name: ModalName.onboardingModal,
                                containerOptions: {
                                    size: ModalSize.LG,
                                    backdropDismissable: false
                                },
                                contentOptions: {
                                    inviteId,
                                    errorObject: errorData,
                                    scenario: this.valuesService.onboardingScenario.ERROR_INVITE
                                }
                            };
                            subscriber.next(_response);
                            subscriber.complete();
                        } else {
                            subscriber.next(false);
                            subscriber.complete();
                        }
                    } else {
                        subscriber.next(false);
                        subscriber.complete();
                    }
                }
            });
        });
    }

    /**
     * If user is on 'add_invite' scenario process automatic invite redirect
     *
     * @public
     * @memberof SpecialCasesService
     * @param {UrlProperties} urlProperties
     * @returns {Observable}
     */
    public _decideAutomaticInviteRedirect(urlProperties: UrlProperties): Observable<boolean|UrlTree> {
        return new Observable( subscriber => {
            const service = urlProperties.queryObject.service || this.queryParamsService.get('service');
            const inviteId = urlProperties.queryObject.code || this.queryParamsService.get('invite_id');

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (service && inviteId && service === this.valuesService.paramsModalService.ADD_INVITE) {
                if (this.profilesService.ownerHasSubsV4()) {
                    this._processAutomaticInviteRedirect(inviteId)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(res => {
                        subscriber.next(res);
                        subscriber.complete();
                    });
                } else {
                    subscriber.next(false);
                    subscriber.complete();
                }
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    _decideOnboarding(): Observable<any|boolean> {
        return new Observable( subscriber => {

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            const _storageOnboardingComplete = sessionStorage.getItem(this.valuesService.sessionStorageModalStatus.onboardingComplete);
            if (!this.subscriptionsService.getAllServices().length && !this.settingsService.isOnboardingComplete() && !_storageOnboardingComplete) {
                const _response = {
                    name: ModalName.onboardingModal,
                    containerOptions: {
                        size: ModalSize.LG,
                        backdropDismissable: false
                    },
                    contentOptions: {
                        scenario: this.valuesService.onboardingScenario['ACTIVATION']
                    }
                };
                subscriber.next(_response);
                subscriber.complete();
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    _decideInstallRedirectFromQueryParams(urlProperties): Observable<boolean|any> {
        return new Observable( subscriber => {
            const modal = urlProperties.queryObject.modal || this.queryParamsService.get('modal');
            const hasDeployFlow = this.appsConfigService.showDeployFlow(this.valuesService.appProtection);

            if (modal && modal === this.valuesService.paramsModalType.install && hasDeployFlow) {
                const cid = urlProperties.queryObject.campaign || this.queryParamsService.get('campaign');

                const _response = {
                    name: ModalName.installProtectionModal,
                    containerOptions: {
                        size: ModalSize.LG,
                        exceptionClass: this.deviceDetectorService.isMobile() ? 'light-close' : ''
                    },
                    contentOptions: {
                        scenario: ProductsToInstall.PROTECTION,
                        cidCode: cid
                    }
                };
                subscriber.next(_response);
                subscriber.complete();
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }

    _decideRedirectFromExpiredSubscription(urlProperties: UrlProperties): Observable<any|boolean> {
        return new Observable( subscriber => {
            if (urlProperties.entirePath.indexOf(this.valuesService.centralPaths.privacy.id) !== -1) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            if (this.profilesService.hasMspOrXspLevelOne()) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            const _storageModalStatus = sessionStorage.getItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal);
            if (this.subscriptionsService.hasAllBundlesAreExpired() && !this.modalRoutelessService.getIsModalOpened() && !_storageModalStatus) {
                if (this.subscriptionsService.hasPaidProtectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    const _response = {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            scenario: this.valuesService.onboardingScenario.EXPIRED,
                            paid: true,
                            protection: true
                        }
                    };
                    subscriber.next(_response);
                    subscriber.complete();
                    return;
                }

                if (this.subscriptionsService.hasPaidNonprotectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    const _response = {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            scenario: this.valuesService.onboardingScenario.EXPIRED,
                            paid: true,
                            protection: false
                        }
                    };
                    subscriber.next(_response);
                    subscriber.complete();
                    return;
                }

                if (this.subscriptionsService.hasFreeProtectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    const _response = {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            scenario: this.valuesService.onboardingScenario.EXPIRED,
                            paid: false,
                            protection: true
                        }
                    };
                    subscriber.next(_response);
                    subscriber.complete();
                    return;
                }

                if (this.subscriptionsService.hasFreeNonprotectionBundleExpired()) {
                    sessionStorage.setItem(this.valuesService.sessionStorageModalStatus.expiredOnboardingModal, 'true');
                    const _response = {
                        name: ModalName.onboardingModal,
                        containerOptions: {
                            size: ModalSize.LG,
                            backdropDismissable: false
                        },
                        contentOptions: {
                            scenario: this.valuesService.onboardingScenario.EXPIRED,
                            paid: false,
                            protection: false
                        }
                    };
                    subscriber.next(_response);
                    subscriber.complete();
                    return;
                }
                subscriber.next(false);
                subscriber.complete();
            }
            subscriber.next(false);
            subscriber.complete();
        });
    }

    _decideCongrats(urlProperties: UrlProperties): Observable<boolean|any> {
        return new Observable( subscriber => {

            const newDevicesIds = this.redeemService.getNewDevicesIds();
            if (!newDevicesIds.size) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            for (const deviceId of newDevicesIds) {
                const device = this.devicesService.retrieveDeviceById(deviceId);
                const validProtectionApp = this.usefulService.getNested(device, [], 'processed', 'protectionApp');
                const hasVPN = !!device?.processed?.hasVPN;
                const hasParental = !!device?.processed?.hasParental;
                const hasParentalNCC = !!device?.processed?.hasParentalNCC;
                // verificam daca are vreun app instalat (protection sau vpn)
                if ((validProtectionApp && this.valuesService.protectionApps.has(validProtectionApp))
                    || hasVPN || hasParental || hasParentalNCC) {
                    this.redeemService.removeNewDeviceId(deviceId);

                    setTimeout(() => {
                        this.messageService.sendMessage(this.valuesService.events.openCongratsNewDevice, {deviceId: deviceId});
                    }, 350);

                    const contentOptions = this.getContentOptionsForCongrats(device);
                    if (!contentOptions?.scenario) {
                        continue;
                    }

                    const _response = {
                        name: ModalName.redeemConfirmationModal,
                        containerOptions: {
                            size: ModalSize.MD
                        },
                        contentOptions
                    };
                    subscriber.next(_response);
                    subscriber.complete();
                    return;
                }
            }

            subscriber.next(false);
            subscriber.complete();
        });
    }

    /**
     * Function that decides what scenario to be displayed for 3dsecure redirects
     * @public
     * @memberof SpecialCasesService
     * @param {UrlProperties} urlProperties The object that contains all url properties
     * @returns {Observable} Specifies which modal to open if 'paymentStatus' param is present
     */
    public decideSecuredPayment(urlProperties: UrlProperties): Observable<any> {
        return new Observable(subscriber => {
            const paymentStatus = urlProperties?.queryObject?.[this.valuesService.queryParams.paymentStatus]
                                    || this.queryParamsService.get(this.valuesService.queryParams.paymentStatus);

            if (!paymentStatus) {
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            // If success, open redeem confirmation modal
            // If error, open secured payment modal
            if (paymentStatus === SecuredPaymentScenarios.SUCCESS) {
                const _response = {
                    name: ModalName.redeemConfirmationModal,
                    containerOptions: {
                        size: ModalSize.MD
                    } as ModalContainerOptions,
                    contentOptions: {
                        scenario: this.valuesService.onboardingScenario.SECURED_PAYMENT
                    }
                };
                subscriber.next(_response);
                subscriber.complete();
            } else {
                const _response = {
                    name: ModalName.securedPaymentModal,
                    containerOptions: {
                        size: ModalSize.MD,
                        backdropDismissable: false,
                        buttonDismissable: false
                    } as ModalContainerOptions,
                    contentOptions: {
                        scenario: SecuredPaymentScenarios.ERROR
                    }
                };
                subscriber.next(_response);
                subscriber.complete();
            }
            this.queryParamsService.remove([this.valuesService.queryParams.paymentStatus]);
        });
    }

    private getContentOptionsForCongrats(device) {
        const hasParental = !!device?.processed?.hasParental;
        const hasParentalNCC = !!device?.processed?.hasParentalNCC;
        let scenario = this.valuesService.onboardingScenario.CONGRATULATIONS;

        const aditionalParams: any = {};

        if (device?.profile_id) {
            const profileHash = this.profilesService.getProfileById(device?.profile_id)?.profile_hash;
            if (profileHash) {
                aditionalParams.profileHash = profileHash;
            }
        }

        if (hasParentalNCC && aditionalParams?.profileHash) {
            scenario = this.valuesService.onboardingScenario.PARENTALNCC;
        }

        if (hasParental && aditionalParams?.profileHash) {
            scenario = this.valuesService.onboardingScenario.PARENTAL;
        }
        //Daca nu are profile_id dar are parental nu afisam modala de congrats
        if (!device?.profile_id && (hasParental || hasParentalNCC)) {
            return {
                scenario: false
            };
        }

        return {
            deviceId: device?.device_id,
            scenario,
            ...aditionalParams
        };

    }

    /**
     *
     * Open redeem modal for accounts without msp
     * @param {UrlProperties} urlProperties
     * @return {*}  {(Observable<boolean|any>)}
     * @memberof SpecialCasesService
     */
    public _decideRedeemRedirectFromQueryParams(urlProperties: UrlProperties): Observable<boolean|any> {
        return new Observable( subscriber => {
            const modal = urlProperties.queryObject.modal || this.queryParamsService.get(this.valuesService.queryParams.modal);
            const hasMspOrXsp = this.profilesService.hasMspOrXspAnyLevel();

            if (modal && modal === this.valuesService.paramsModalType.redeem && !hasMspOrXsp) {
                const _response = {
                    name: ModalName.onboardingModal,
                    containerOptions: {
                        size: ModalSize.LG,
                        backdropDismissable: false
                    },
                    contentOptions: {
                        scenario: this.valuesService.onboardingScenario.JUST_ACTIVATION
                    }
                };
                subscriber.next(_response);
                subscriber.complete();
            } else {
                subscriber.next(false);
                subscriber.complete();
            }
        });
    }
}

//  External
import { Injectable } from '@angular/core';

//  Internal
import { ConfigService, DeployType, Hosts } from '../config/config.service';
import { CheckoutSDKMapping } from '../models/CheckoutSdk.model';
import {
    NotificationsTypes,
    NotificationsSubtypes,
    EventDescriptors,
    DeviceStatusIcons,
    NotificationsFunctions,
    EventConditions,
    EventTypes,
    EventIcons
} from '../services/global/events/events.model';

export enum OperatingSystems {
    WINDOWS = 'windows',
    ANDROID = 'android',
    MAC = 'mac',
    IOS = 'ios',
    OSX = 'osx',
    LINUX = 'linux',
    UNKNOWN = 'unknown'
}

export enum DeviceArchitectureTypes {
    ARM = 'arm'
}

export enum DeviceArchitectureBits {
    BIT32 = '32',
    BIT64 = '64'
}

export enum DeviceArchitecture {
    ARM32 = 'arm32',
    ARM64 = 'arm64'
}

export enum Browsers {
    CHROME = 'chrome',
    GOOGLE_CHROME = 'google chrome',
    MS_EDGE_CHROMIUM = 'ms-edge-chromium',
    MICROSOFT_EDGE = 'microsoft edge',
    SAFARI = 'safari',
    FIREFOX = 'firefox'
}

export enum HighEntropyValues {
    ARCHITECTURE = 'architecture',
    BITNESS = 'bitness',
    PLATFORM_VERSION = 'platformVersion'
};

export enum ProductsToInstall {
    PROTECTION = 'protection',
    VPN = 'vpn',
    PARENTAL = 'parental',
    PARENTALNCC = 'nccparental',
    PASSWORDMANAGERSFR = 'passManager',
    PASSWORDMANAGER = 'passwordmanager',
    ALL = 'all'
};

export enum InstallScenarios {
    THIS_DEVICE = 'thisDevice',
    OTHER_DEVICES = 'otherDevices',
    CREATE_PROFILE = 'createProfile'
};

export type ProductsToInstallSubScenarios = {
    [key in InstallScenarios]?: () => void
};

export type ProductsToInstallScenarios = {
    [key in ProductsToInstall]?: ProductsToInstallSubScenarios
};

export const SkipInstallRedirectScenarios = new Set([ProductsToInstall.PARENTAL, ProductsToInstall.PARENTALNCC]);

export const DAYS_IN_WEEK = 7;
export const ONE_DAY_IN_MILISECONDS = 86400000;

export const SIDEMENU_COLLAPSE_POINT = 1280;

@Injectable({
    providedIn: 'root'
})

export class ValuesService {

    constructor(readonly configService: ConfigService,) {}

    //* ------------------------- Core ------------------------- *//
    beta_campaign = 'central_beta_2018';
    jsonrpc = '2.0';
    connectDeviceId = 'web';
    onboardingMinRes = '1199';
    current_year = '2020';

    // or sa se faca maxim 200 de requesturi per batch pt a nu supraincarca connectul
    serverRequestsLimit = 200;

    connectCookie = this.configService.config.partner_id.concat('.connect');

    //* ------------------------- Cookies ------------------------- *//
    cookieEmail = '_cbce';   //  cookie with connect email
    cookieLang = '_cbcl';   //  cookie with connect token
    cookiePath = '_cbcp';   //  cookie with connect user
    cookiePreviousUrl = '_cbcpu';  //  cookie with previous url
    cookieSearch = '_cbcs';   //  cookie to store search params for redirect
    cookieShowAr = '_cbcsa';  //  cookie pentru auto renewal feature
    cookieShowOffers = '_cbcso';  //  cookie to store showOffers value
    cookieTempToken = '_cbctt';  //  cookie with connect temp token
    cookieToken = '_cbct';   //  cookie with connect token
    cookieTokenAlt = '_cbcta';   //  cookie with connect token
    cookieUser = '_cbcu';   //  cookie with connect user
    cookieUserToken = '_cbcut';  //  cookie with user token from login.bitdefender
    cookiesXspNoSubscription = '_cbxb';  // cookie for XSP when we have no subscription
    cookieShowInvoices = '_cbcsi'; // cookie for user invoices
    cookieTheme = '_cbc-theme'; // cookie for user theme

    //* --------------------- Session Storage ---------------------  *//
    sessionStorageAllQueryParams = '_aqp';
    sessionStorageAllQueryParamsTimestamps = '_aqpt';

    sessionStorageModalStatus = {
        firstLoginMade: '_firstLoginMade',
        onboardingComplete: '_onboardingComplete',
        expiredOnboardingModal: '_expiredOnboardingModal'
    };

    // Third Party
    //* -------------------------- Scripts ------------------------- *//
    // #### ZUORA ####
    //! Checkout SDK hostname-key mapping
    //! Fiecare hostname va avea o cheie publica altfel nu va merge SDK-ul.
    //! Cheile de pe Mars functioneaza si pe Venus ( ambele medii de beta )
    //! Daca pe hostname se pune si url se va tine cont de acela. Daca nu se va tine cont de cele din default
    checkoutSDKMapping: CheckoutSDKMapping = {
        [Hosts.PRODUCTION]: {
            [DeployType.BETA]: {
                public_key: ""
            },
            [DeployType.PROD]: {
                public_key: "3c543fe0-bac9-11ea-9698-6dbb57642679"
            }
        },
        [Hosts.STAGE]: {
            [DeployType.BETA]: {
                public_key: ""
            },
            [DeployType.PROD]: {
                url: 'https://checkout-sdk-stage.checkout-app.nmbapp.net/saturn/latest/sdk.js',
                public_key: "0b1f3ca0-3a5b-11ed-ad90-e97c78b40866"
            }
        },
        [Hosts.ALPHA]: {
            [DeployType.BETA]: {
                public_key: "4d6a04d0-ba10-11ea-9066-f92708e94a12"
            },
            [DeployType.PROD]: {
                public_key: "cc059bf0-bad7-11ea-971b-c5c45804f455"
            }
        },
        [Hosts.BETA]: {
            [DeployType.BETA]: {
                public_key: "8f37d780-ba10-11ea-ab54-ddfcba5e3c44"
            },
            [DeployType.PROD]: {}
        },
        [Hosts.TEST_1]: {
            [DeployType.BETA]: {
                public_key: "22dc0770-ba11-11ea-9eec-8365976738c3"
            },
            [DeployType.PROD]: {}
        },
        [Hosts.TEST_2]: {
            [DeployType.BETA]: {
                public_key: "4d6a04d0-ba10-11ea-9066-f92708e94a12"
            },
            [DeployType.PROD]: {
                public_key: "cc059bf0-bad7-11ea-971b-c5c45804f455"
            }
        },
        [Hosts.TEST_3]: {
            [DeployType.BETA]: {
                url: 'https://checkout-sdk-stage.checkout-app.nmbapp.net/mars/latest/sdk.js',
                public_key: "737675e0-ba11-11ea-aada-1193a7cd4dad"
            },
            [DeployType.PROD]: {
                public_key: "1ce27da0-bad8-11ea-92b3-f9a0f5d026ed"
            }
        },
        [Hosts.TEST_4]: {
            [DeployType.BETA]: {
                // url: "https://checkout-sdk-stage.checkout-app.nmbapp.net/mars/latest/sdk.js",
                // public_key: "4b1bd4b0-7b42-11eb-b091-918ac7c73b01"
                url: "https://checkout-dev.bitdefender.biz:442/1.0/sdk.js",
                public_key: "4b1bd4b0-7b42-11eb-b091-918ac7c73b01"
            },
            [DeployType.PROD]: {
                public_key: ""
            }
        },
        [Hosts.LOCAL]: {
            [DeployType.BETA]: {
                public_key: "19d60fb0-ba0f-11ea-a8d5-bd4bc7bef118"
            },
            [DeployType.PROD]: {
                public_key: ""
            }
        },
        [Hosts.DEFAULT]: {
            [DeployType.BETA]: {
                url: "https://checkout-sdk-stage.checkout-app.nmbapp.net/venus/latest/sdk.js",
                public_key: ""
            },
            [DeployType.PROD]: {
                url: "https://checkout-sdk.bitdefender.com/latest/sdk.js",
                public_key: ""
            }
        }
    };

    /**
     * #### VERIFONE ####
     * Checkout SDK hostname-key mapping for Auto Renewal flow (offline to online conversion)
     * It is necessary to avoid situations in which a reedem eligible for the offline to online conversion is activated
     * on an account that has zuora subscriptions
     */
    checkoutSDKMappingAR: CheckoutSDKMapping = {
        [Hosts.ALPHA]: {
            [DeployType.PROD]: {
                public_key: 'ff4d9040-57a9-11ee-9417-ebde43f0e4c0'
            },
            [DeployType.BETA]: {
                public_key: ""
            }
        },
        [Hosts.TEST_2]: {
            [DeployType.PROD]: {
                public_key: 'ff4d9040-57a9-11ee-9417-ebde43f0e4c0'
            },
            [DeployType.BETA]: {
                public_key: ""
            }
        },
        [Hosts.TEST_3]: {
            [DeployType.BETA]: {
                url: 'https://checkout-sdk-stage.checkout-app.nmbapp.net/mars/latest/sdk.js',
                public_key: 'fddfb6d0-59c6-11ed-bb6c-d7f68ccd109c'
            },
            [DeployType.PROD]: {
                public_key: 'fddfb6d0-59c6-11ed-bb6c-d7f68ccd109c'
            }
        },
        [Hosts.TEST_4]: {
            [DeployType.BETA]: {
                url: 'https://checkout-dev.bitdefender.biz:442/1.0/sdk.js',
                public_key: '6a214c10-2ebc-11ed-8a38-4bffb4c23dec'
            },
            [DeployType.PROD]: {
                public_key: '6a214c10-2ebc-11ed-8a38-4bffb4c23dec'
            }
        },
        [Hosts.DEFAULT]: {
            [DeployType.BETA]: {
                url: 'https://checkout-sdk-stage.checkout-app.nmbapp.net/venus/latest/sdk.js',
                public_key: ''
            },
            [DeployType.PROD]: {
                url: 'https://checkout-sdk.bitdefender.com/latest/sdk.js',
                public_key: 'ce802450-57a9-11ee-b669-c981e56c00b7'
            }
        }
    };

    //* --------------------------- APIs --------------------------- *//
    antitheftMgmtService = 'connect/antitheft_mgmt';
    appMgmtService = 'connect/app_mgmt';
    commercial_ids = 'connect/commercial_ids';
    connectFeedbackService = 'connect/feedback';
    connectMgmtService = 'connect/connect_mgmt';
    connectRenewalInfoProxyService = 'connect/renewal_info';
    connectSupportService = 'connect/user_support';
    downloadAgentInfoService = 'connect/download_agent_info';
    downloadAgentService = 'connect/download_agent';
    issuesMgmtService = 'connect/issues_mgmt';
    loginMgmtService = 'connect/login';
    connectnotificationsService = 'connect/notifications';
    connectLoggerService = 'connect/logger';
    offersMgmtService = 'connect/ipmem';
    webmailProtectionMgmtService = 'connect/webmail_protection';
    webmailRegistrationMgmtService = 'connect/webmail_registration';
    v1GmailTokens = 'v1/gmail/tokens';
    v1OutlookTokens = 'v1/outlook/tokens';
    parentalControlNccManagement = 'ncc/mgmt';
    parentalAdvisorAreasService = 'parentaladvisor/areas';
    parentalControlContactsService = 'parentalcontrol/contacts';
    parentalAdvisorReportsService = 'parentaladvisor/reports';
    parentalControlReportsService = 'parentalcontrol/reports';
    parentalAdvisorRestrictionsService = 'parentaladvisor/restrictions';
    parentalAdvisorSchedulesService = 'parentaladvisor/schedules';
    parentalControlAppsService = 'parentalcontrol/apps';
    parentalControlSettingsService = 'parentalcontrol/settings';
    parentalControlLocationService = 'parentalcontrol/location';
    parentalcontrolWebSettingsService = "parentalcontrol/web";

    parentalAdvisorSettingsService = 'parentaladvisor/settings';
    parentalAdvisorStatsService = 'parentaladvisor/stats';
    privacyLidService = 'lid/data_privacy';
    idTheftLidService = 'lid/idtheft';
    secCenter = 'connect/seccenter';
    settingsMgmtService = 'connect/settings';
    //! Nu asa se face! NU inlocuiesti o variabila daca inca este folosita cea veche
    subscriptionMgmtService = 'connect/subscription';
    subscriptionInviteService = 'subscribe/invite_system';
    // subscriptionMgmtService            = 'connect/subscription';
    subscriptionTrial = 'connect/subscription_trial';
    tasksMgmtService = 'connect/tasks_mgmt';
    techassistMgmtService = 'connect/techassist';
    userinfoMgmtService = 'connect/user_info';
    katastifService = 'katastif/manager';
    connectUserReward = 'connect/user_reward';
    connectReferral = 'connect/referral';
    connectPushService = 'connect/push';

    change_lbn_service = 'v1/user/change';
    delete_lbn_service = 'v1/account/delete';
    create_lbn_service = 'user/create';
    external_lbn_service = 'user/external_login';
    feedbackMgmtService = 'beta/feedback';
    forgot_lbn_service = 'user/forgot';

    login_lbn_service = 'user/login';
    reset_lbn_service = 'user/reset';
    commercialIds_webpage = 'https://www.bitdefender.com/links/{country}/homepage.html';

    trackingHttpServerAdobe = "stats.bitdefender.com";
    trackingHttpsServerAdobe = "sstats.bitdefender.com";
    adobeTrackingID = "0E920C0F53DA9E9B0A490D45@AdobeOrg";
    //  ------------------------- Manufacturer and Products -------------------------

    appCL = this.configService.config.partner_id.concat('.cl');
    appAVFM = this.configService.config.partner_id.concat('.avformac');
    appBMS = this.configService.config.partner_id.concat('.bms');
    appIOSBMS = this.configService.config.partner_id.concat('.iosbms');
    appBIS = this.configService.config.partner_id.concat('.iossecurity');
    appBIP = this.configService.config.partner_id.concat('.iosprotection');

    //vpn
    appVPN = this.configService.config.partner_id.concat('.vpn');

    //parental
    appPA = this.configService.config.partner_id.concat('.parentaladvisor');
    appPANCC = this.configService.config.partner_id.concat('.nccparental');

    appDIP = this.configService.config.partner_id.concat('.dataprivacy');

    appIdTheft = this.configService.config.partner_id.concat('.idtheft');

    appWebmailProtection = this.configService.config.partner_id.concat('.webmailprotection');

    appPassManager = this.configService.config.partner_id.concat('.passwordmanager');
    appPassManagerSfr = "passwordmanagersfr";

    appDvi = this.configService.config.partner_id.concat('.device_inventory');
    appOptimizeDvm = this.configService.config.partner_id.concat('.devicemanagement');
    appDvm = this.configService.config.partner_id.concat('.dev_mgmt');
    appAVFREE = this.configService.config.partner_id.concat('.avfree');
    appBOX2 = this.configService.config.partner_id.concat('.boxse');
    appBOX2B = this.configService.config.partner_id.concat('.boxse_bootstrap');
    appBOXMGMT = this.configService.config.partner_id.concat('.box_mgmt');
    appBOX1 = this.configService.config.partner_id.concat('.box');
    appBOXLIGHT = this.configService.config.partner_id.concat('.boxlight');
    appCM = this.configService.config.partner_id.concat('.connect_mgmt');
    appHVA = this.configService.config.partner_id.concat('.hva');
    //-------- aplicatii de premium services care nu se pot instala
    appSFP = this.configService.config.partner_id.concat('.setupforpc');
    appSFM = this.configService.config.partner_id.concat('.setupformac');
    appSFMP = this.configService.config.partner_id.concat('.setupmultiplatform');
    appVR = this.configService.config.partner_id.concat('.virusremoval');
    appSR = this.configService.config.partner_id.concat('.systemrepair');
    appPO = this.configService.config.partner_id.concat('.pcoptimizer');
    appAR = this.configService.config.partner_id.concat('.adwareremoval');
    appCT = this.configService.config.partner_id.concat('.computertuneup');
    appProtection = 'protection';
    appSecurity = 'security';

    //apps installed on a device
    appsSet = new Set([
        this.appCL,
        this.appAVFM,
        this.appBMS,
        this.appBIS,
        this.appBIP,
        this.appAVFREE,
        this.appVPN,
        this.appPA,
        this.appPANCC,
        this.appDIP,
        this.appPassManager,
        this.appIdTheft,
        this.appWebmailProtection,
        this.appOptimizeDvm,
        this.appDvm,
        this.appBOX2,
        this.appBOX2B,
        this.appBOXMGMT,
        this.appBOX1,
        this.appBOXLIGHT,
        this.appCM,
        this.appHVA,
        this.appSFP,
        this.appSFM,
        this.appSFMP,
        this.appVR,
        this.appSR,
        this.appPO,
        this.appAR,
        this.appCT
    ]);

    connectAppId = this.appCM;

    trialPeriods = {
        oneMonth    : 1,
        threeMonths : 3
    };

    availableTrialSections = {
        [this.trialPeriods.oneMonth]    : 'trial30',
        [this.trialPeriods.threeMonths] : 'trial90'
    };

    noAvailableTrialSections = {
        expired: 'expired',
        noTrial: 'notrial'
    };

    trialIds = {
        [this.appCL]: "5df08867-aa46-4142-9ee5-9771dc8b6646",
        [this.appBIP]: "",
        [this.appPassManager]: {
            [this.trialPeriods.oneMonth]    : this.configService.config.passwordManagerOneMonthTrialId,
            [this.trialPeriods.threeMonths] : this.configService.config.passwordManagerThreeMonthsTrialId
        }
    };

    upgradeApps = {
        [this.appAVFREE]: this.appCL,
        [this.appBIS]: this.appBIP
    };

    bundleAV                = this.configService.config.partner_id.concat('.cl.av');
    bundleAVNEW             = this.configService.config.partner_id.concat('.cl.av.v2');
    bundleIS                = this.configService.config.partner_id.concat('.cl.is');
    bundleTS                = this.configService.config.partner_id.concat('.cl.ts');
    bundleBMS               = this.configService.config.partner_id.concat('.bms');
    bundleBIS               = this.configService.config.partner_id.concat('.iossecurity');
    bundleBIP               = this.configService.config.partner_id.concat('.iosprotection');
    bundleAVFM              = this.configService.config.partner_id.concat('.avformac');
    bundlePA                = this.configService.config.partner_id.concat('.parentaladvisor');
    bundlePANCC             = this.configService.config.partner_id.concat('.nccparental');
    bundleTSVPN             = this.configService.config.partner_id.concat('.tsmdvpn');
    bundleDPI               = this.configService.config.partner_id.concat('.dataprivacy');
    bundleHVA               = this.configService.config.partner_id.concat('.hva');
    bundlePSec              = this.configService.config.partner_id.concat('.premiumsecurity');
    bundlePSecNew           = this.configService.config.partner_id.concat('.premiumsecurity.v2');
    bundlePSecPlus          = this.configService.config.partner_id.concat('.premiumsecurityplus');
    bundleSecIdentity       = this.configService.config.partner_id.concat('.secureidentity');
    bundleSTFPC             = this.configService.config.partner_id.concat('.setupforpc');
    bundleSTMP              = this.configService.config.partner_id.concat('.setupmultiplatform');
    bundleSTFM              = this.configService.config.partner_id.concat('.setupformac');
    bundleVR                = this.configService.config.partner_id.concat('.virusremoval');
    bundleSYSR              = this.configService.config.partner_id.concat('.systemrepair');
    bundlePCO               = this.configService.config.partner_id.concat('.pcoptimizer');
    bundleCTU               = this.configService.config.partner_id.concat('.computertuneup');
    bundleVIPS              = this.configService.config.partner_id.concat('.vipsupport');
    bundleIDTPS             = this.configService.config.partner_id.concat('.idtheftstandard');
    bundleIDTPP             = this.configService.config.partner_id.concat('.idtheftpremium');
    bundleUSUS              = this.configService.config.partner_id.concat('.ultimatesecurityus');
    bundleUSUSNEW           = this.configService.config.partner_id.concat('.ultimatesecurityus.v2');
    bundleUSPUS             = this.configService.config.partner_id.concat('.ultimatesecurityplusus');
    bundleUSPUSNEW          = this.configService.config.partner_id.concat('.ultimatesecurityplusus.v2');
    bundleUSEUNEW           = this.configService.config.partner_id.concat('.ultimatesecurityeu.v2'); // replacing PS Plus
    bundleTSMD              = this.configService.config.partner_id.concat('.tsmd');
    bundleTSMDNEW           = this.configService.config.partner_id.concat('.tsmd.v2');
    bundleCLTSMD            = this.configService.config.partner_id.concat('.cl.tsmd');
    bundleFP                = this.configService.config.partner_id.concat('.fp');
    bundleCLFP              = this.configService.config.partner_id.concat('.cl.fp');
    bundleISMD              = this.configService.config.partner_id.concat('.ismd');
    bundleFPAV              = this.configService.config.partner_id.concat('.fp.av');
    bundleFPIS              = this.configService.config.partner_id.concat('.fp.is');
    bundleFPP               = this.configService.config.partner_id.concat('.fp.parental');
    bundleBoxCl             = this.configService.config.partner_id.concat('.box_tsmd');
    bundleIOSBMS            = this.configService.config.partner_id.concat('.iosbms');
    bundleAVFREE            = this.configService.config.partner_id.concat('.avfree');
    bundleAVLITE            = this.configService.config.partner_id.concat('.avlite');
    bundleBox               = this.configService.config.partner_id.concat('.box');
    bundlePAS               = this.configService.config.partner_id.concat('.parentalsecurity');
    bundleVPN               = this.configService.config.partner_id.concat('.vpn');
    bundleVPNPREMIUM        = this.configService.config.partner_id.concat('.vpnpremium');
    bundleVPNSOHO           = this.configService.config.partner_id.concat('.vpnsoho');
    bundleSOHO              = this.configService.config.partner_id.concat('.soho');
    bundleBOX2              = this.configService.config.partner_id.concat('.boxse_tsmd');
    bundleStartPlan         = this.configService.config.partner_id.concat('.start_plan');
    bundlePersonalPlan      = this.configService.config.partner_id.concat('.personal_plan');
    bundleFamilyPlan        = this.configService.config.partner_id.concat('.family_plan');
    bundleUltimatePlan      = this.configService.config.partner_id.concat('.ultimate_plan');
    bundlePersonalVpnPlan   = this.configService.config.partner_id.concat('.personal_vpn_plan');
    bundlePM                = this.configService.config.partner_id.concat('.passwordmanager');
    bundleBOXSE             = this.configService.config.partner_id.concat('.boxse');
    bundlsSUMP              = this.configService.config.partner_id.concat('.setupmp');
    bundleSHS               = this.configService.config.partner_id.concat('.smarthomesecurity');
    bundlesSetup            = this.configService.config.partner_id.concat('.setup');


    // PRODUCTS
    productNameAV               = 'Antivirus Plus';
    productNameIS               = 'Internet Security';
    productNameTS               = 'Suite de Sécurité';
    productNameBMS              = '';
    productNameBIS              = '';
    productNameBIP              = 'Suite de Sécurité Orange';
    productNameAVFM             = 'Antivirus for Mac';
    productNamePA               = 'Contrôle Parental';
    productNamePANCC            = 'Contrôle Parental';
    productNameTSMD             = 'Total Security Multi-Device'; //  old tsmd
    productNameFP               = 'Family Pack';
    productNameFPAV             = 'Family Privacy Pack';
    productNameFPIS             = 'Family Privacy Pack Max';
    productNameISMD             = 'Internet Security';
    productNameBoxCl            = 'BOX 1';
    productNameBox              = 'BOX 1';
    productNamePAS              = 'Parental Security';
    productNameAVFREE           = 'Antivirus Free';
    productNameAVLITE           = 'Antivirus Free';
    productNameBOX2             = 'BOX 2';
    productNameVPNBasic         = 'VPN';
    productNameVPNPremium       = 'Premium VPN';
    productNameDIP              = 'Digital Identity Protection';
    productNameWallet           = 'Wallet';
    productNamePasswordManager  = 'Password Manager';
    productNameIDTP             = 'Identity Theft Protection';
    productNameIDTPP            = 'Identity Theft Protection Premium';
    productNameIDTPS            = 'Identity Theft Protection Standard';
    productNameUSUS             = 'Ultimate Security';
    productNameUSPUS            = 'Ultimate Security Plus';
    productNamePS               = 'Premium Security';
    productNamePSPlus           = 'Premium Security Plus';
    productNameSEC              = 'Suite de Sécurité';
    productNameUSEU             = 'Ultimate Security';
    productNameSecurity         = 'Sécurité';
    productNameSecIdentity      = 'Secure Identity';
    productNameBoxDefault       = 'Box';
    productNameProtection       = 'Protection';
    productNameWebmailProtection = 'Email Protection';

    SECONDS_IN_A_DAY = 60 * 60 * 24;
    SECONDS_IN_A_WEEK = 7 * this.SECONDS_IN_A_DAY;
    MILISECONDS_IN_A_MINUTE = 1000 * 60;
    MILISECONDS_IN_AN_HOUR = 60 * this.MILISECONDS_IN_A_MINUTE;
    MILISECONDS_IN_A_DAY = 24 * this.MILISECONDS_IN_AN_HOUR;
    MILISECONDS_IN_A_WEEK = 7 * this.MILISECONDS_IN_A_DAY;
    MILISECONDS_IN_A_MONTH = 30 * this.MILISECONDS_IN_A_DAY;

    queryParams = {
        product: 'product',
        installCode: 'install_code',
        lang: 'lang',
        adobeMc: 'adobe_mc',
        url: 'url',
        dType: 'dtype',
        format: 'format',
        code: 'code',
        scope: 'scope',
        state: 'state',
        ref: 'ref',
        adobeMcRef: 'adobe_mc_ref',
        cid: 'cid',
        icid: 'icid',
        deleteToken: 'delete_token',
        accessToken: 'access_token',
        error: 'error',
        paymentStatus: 'paymentStatus',
        devicesFilter: 'f',
        userToken: 'user_token',
        inboxProvider: 'inbox_provider',
        modal: 'modal',
        buyLinkBundleType: 't'
    };

    urlParams = {
        breachId: 'breachId'
    };

    persistentQueryParams = new Set([
        this.queryParams.product,
        this.queryParams.installCode,
        this.queryParams.lang,
        this.queryParams.url,
        this.queryParams.dType,
        this.queryParams.format,
        this.queryParams.code,
        this.queryParams.scope,
        this.queryParams.state
    ]);

    persistentQueryParamsTimes = {
        [this.queryParams.product]: this.MILISECONDS_IN_A_DAY,
        [this.queryParams.installCode]: this.MILISECONDS_IN_A_DAY,
        [this.queryParams.lang]: this.MILISECONDS_IN_A_DAY,
        [this.queryParams.url]: this.MILISECONDS_IN_A_MINUTE,
        [this.queryParams.dType]: this.MILISECONDS_IN_A_MINUTE,
        [this.queryParams.format]: this.MILISECONDS_IN_A_MINUTE,
    };
    persistentQueryParamsMaxTime = 3 * this.MILISECONDS_IN_A_MONTH; // 3 months



    // Home settings
    homeVideoURL = `https://www.youtube.com/embed/bB3wqFA-vFM?autoplay=1&fs=1&rel=0&modestbranding=1&origin=${this.configService.getSiteUrl()}`;


    plans = new Set([
        this.bundleStartPlan,
        this.bundlePersonalPlan,
        this.bundleFamilyPlan,
        this.bundleUltimatePlan
    ]);

    evergreenBundles = new Set([this.bundleAVLITE]);

    subscriptionVersion3 = 3;

    /**
     * Bundles whose devices can be reallocated by a superior bundle upon that bundle's activation
     * These are inferior to "bundlesThatReallocateDevices"
     */
    bundlesWhoseDevicesAreReallocated = [
        this.bundleUSUSNEW,
        this.bundleUSUS,
        this.bundlePSecNew,
        this.bundlePSec,
        this.bundleTSMDNEW,
        this.bundleTSMD,
        this.bundleIS,
        this.bundleAVNEW,
        this.bundleAV
    ];

    /**
     * Bundles that upon activation can cause other bundles devices to reallocated to them
     * These are superior to "bundlesWhoseDevicesAreReallocated"
     */
    bundlesThatReallocateDevices = new Set([
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundlePSec,
        this.bundlePSecNew,
        this.bundlePSecPlus,
        this.bundleUSEUNEW,
        this.bundleUSUS,
        this.bundleUSUSNEW,
        this.bundleUSPUS,
        this.bundleUSPUSNEW
    ]);

    /**
     * "Install protection on a new device" will not be shown for these bundles
     */
    bundlesWithoutDeployFlow = new Set([
        this.bundleAVFREE,
        this.bundlePAS,
        this.bundleBoxCl,
        this.bundleHVA,
        this.bundlePANCC
    ]);

    /**
     * Ordered services for "Protect more devices" check
     * Every subarray containes ordered compatible services
     * Multiple arrays exist beacause of multiple platforms
     * The order is ascending, most superior service last
     */
    orderedServices = [
        [
            this.bundleAV,
            this.bundleAVNEW,
            this.bundleIS,
            this.bundleISMD,
            this.bundleTS,
            this.bundleTSMD,
            this.bundleTSMDNEW,
            this.bundleFP,
            this.bundlePSec,
            this.bundlePSecNew,
            this.bundlePSecPlus,
            this.bundleUSEUNEW,
            this.bundleUSUS,
            this.bundleUSUSNEW,
            this.bundleUSPUS,
            this.bundleUSPUSNEW
        ],
        [
            this.bundleAVFM,
            this.bundleTS,
            this.bundleTSMD,
            this.bundleTSMDNEW,
            this.bundleFP,
            this.bundlePSec,
            this.bundlePSecNew,
            this.bundlePSecPlus,
            this.bundleUSEUNEW,
            this.bundleUSUS,
            this.bundleUSUSNEW,
            this.bundleUSPUS,
            this.bundleUSPUSNEW
        ]
    ];

    /**
     * Ordered trial services for the second recommendation card
     * The order is from the highest priority bundle to the lowest priority bundle
     * It is used in the second recommendation card to get the highest priority bundle
     */
    orderedServicesSecondRecommendationCard = [
        this.bundleBMS,
        this.bundleAVFM,
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleIS,
        this.bundleTS,
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundleFP,
        this.bundlePSec,
        this.bundlePSecNew,
        this.bundlePSecPlus,
        this.bundleUSEUNEW
    ];

    /**
     * Ordered services for the first recommendation card
     * The order is from the highest priority bundle to the lowest priority bundle
     * It is used in the first recommendation card to get the bundle fir which to display the 'buy more protection' link
     */
    orderedServicesFirstRecommendationCard = [
        this.bundleAVLITE,
        this.bundleAVFM,
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleIS,
        this.bundleTS,
        this.bundleISMD,
        this.bundleTSMD,
        this.bundleTSMDNEW
    ];

    buyMoreProtectionBundles = new Set([
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleTS,
        this.bundleIS,
        this.bundleAVFM
    ]);

    bundlesWithoutRenewLink = new Set([
        this.bundleHVA,
        this.bundleAVFREE,
        this.bundleIOSBMS,
        this.bundleBIS,
        this.bundleBoxCl,
        this.bundlePAS,
        this.bundleAVLITE,
        this.bundleSecIdentity
    ]);

    bundlesWithProtectMoreDevices = new Set([
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundleIS,
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleAVFM,
        this.bundleISMD,
        this.bundleTS,
        this.bundleAVLITE
    ]);

    typesWithoutProtectMoreDevices = new Set([
        'free',
        'trial',
        'nfr'
    ]);

    boxBundles = new Set([
        this.bundleBoxCl,
        this.bundleBOX2
    ]);

    boxApps = new Set([
        this.appBOX1,
        this.appBOX2,
        this.appBOXLIGHT
    ]);

    deviceSettings = {
        pin: 'antitheft.android.apppin',
        network: 'network',
        automaticUpgrade: 'automatic_upgrade'
    };

    settingsForApps = {
        [this.appBMS]: [this.deviceSettings.pin],
        [this.appBOX2]: [this.deviceSettings.network, this.deviceSettings.automaticUpgrade]
    };

    /**
     * List of device settings that should be updated when 'settings_changed' event is received
     */
    public deviceSettingsToBeUpdated = new Set([
        this.deviceSettings.pin,
        this.deviceSettings.network,
        this.deviceSettings.automaticUpgrade
    ]);

    onboardingCompatibleOptions = {
        noOption: -2,
        activateNew: -1
    };

    vpnServices = new Set([
        this.bundleVPN,
        this.bundleVPNPREMIUM,
        this.bundleVPNSOHO
    ]);

    idTheftProtectionServices = new Set([
        this.bundleIDTPS,
        this.bundleIDTPP
    ]);

    ultimateSecurityProtectionServices = new Set([
        this.bundleUSPUS,
        this.bundleUSPUSNEW,
        this.bundleUSUS,
        this.bundleUSUSNEW,
    ]);

    tsServices = new Set([
        this.bundleTS,
        this.bundleTSVPN,
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundleTSMDNEW
    ]);

    iconActivate = this.configService.config.pathImages.concat('onboarding/icon_activate.svg');

    subscriptions = {
        'end_user': {
            name: 'subscription.main.subscription'
        },
        'trial': {
            name: 'subscription.main.trial'
        },
        'free': {
            name: 'subscription.main.free'
        },
        'nfr': {
            name: 'subscription.main.nfr'
        },
        'lifetime': {
            name: 'subscription.main.lifetime'
        },
    };

    subscriptionsLevels = {
        "basic": "basic",
        "premium": "premium"
    };

    notToBeCached = [
        'set_one',
        'editAppRestriction',
        'editScheduleData',
        'editDailyTimeLimit',
        'removeScheduleData',
        'removeDailyTimeLimit',
        'pauseProfile',
        'set_one',
        'remove_device',
        'unregister_box',
        'remove_device',
        'redeem',
        'execute_task',
        'fix_device_issues',
        'set_dhcp_settings',
        'rm_dhcp_settings',
        'change_account',
        'get_threats_v2',
        'count_threats',
        'connect'
    ];

    onboardingBundleTypes = {
        protection: 'protection',
        vpn: 'vpn',
        parental: 'parental',
        parentalNcc: 'nccparental',
        dip: 'dip',
        premium: 'premium',
        passManager: 'passManager',
        identity: 'identity',
        idTheftProtection: 'idTheftProtection',
        deviceReallocation: 'deviceReallocation',
        deviceReallocationFamilyPlan: 'deviceReallocationFamilyPlan',
        autoRenewal: 'autoRenewal',
        secureIdentity: 'secureIdentity'
    };

    /**
     * Apps that either do not have any device assigned or
     * you can't re-assign their devices via interface
     * Used in Manage Device Allocation modal
     */
    appsNotShownInManageDeviceAllocation = new Set([
        this.appOptimizeDvm,
        this.appSR,
        this.appPO,
        this.appSFMP,
        this.appAR,
        this.appBOX2,
        this.appBOX1,
        this.appPassManager,
        this.appIdTheft,
        this.appDIP,
        this.appWebmailProtection
    ]);

    /**
     * Apps that should not be included in redeem app description
     * Used in Redeem Confirmation Modal
     */
    appsNotShownInRedeemConfirmation = new Set([
        this.appWebmailProtection
    ]);

    /**
     * Apps that do not have any device assigned to them
     * Used in View Service Details modal
     */
    appsWithoutActiveDevices = new Set([
        this.appPassManager,
        this.appIdTheft,
        this.appDIP
    ]);

    appsNotShownServiceDetails = new Set([
        this.appOptimizeDvm,
        this.appSR,
        this.appPO,
        this.appSFMP,
        this.appAR,
        this.appBOX1,
        this.appWebmailProtection
    ]);

    profileTypes = {
        USER: "user",
        CHILD: "child",
        HOUSEHOLD: "household",
        GUEST: "guest",
        SOHO: "soho"
    };

    daysWeek = [
        'activity.sunday',
        'activity.monday',
        'activity.tuesday',
        'activity.wednesday',
        'activity.thursday',
        'activity.friday',
        'activity.saturday'
    ];

    threatTypes = {
        malware       : 'dashboard.threats.card.malware',
        phishing      : 'dashboard.threats.card.phishing',
        fraud         : 'dashboard.threats.card.fraud',
        pua           : 'dashboard.threats.card.pua',
        untrusted     : 'dashboard.threats.card.untrusted',
        exploit       : 'dashboard.threats.card.exploit',
        attack        : 'dashboard.threats.card.attack',
        privacy       : 'dashboard.threats.card.privacy',
        anomaly       : 'dashboard.threats.card.anomaly',
        bot           : 'dashboard.threats.card.bot',
        certificate   : 'dashboard.threats.card.certificate',
        spam          : 'dashboard.threats.card.spam',
        miner         : 'dashboard.threats.card.miner',
        ransomware    : 'dashboard.threats.card.ransomware',
        trojan        : 'dashboard.threats.card.trojan',
        malicious_host: 'dashboard.threats.card.malicious_host'
    };

    //*
    //* EVENT NAMES
    //*
    events = {
        offersSeen: 'offersSeen',
        themeChange: 'themeChange',
        drawer: 'drawer',
        locationToRedirect: "locationToRedirect",
        languageChange: 'languageChange',
        languageChangedRequestSuccess: 'languageChangedRequestSuccess',
        languageChangedRequestError: 'languageChangedRequestError',
        ownerChange: 'ownerChange',
        searchSupport: 'searchSupport',
        triggerStart: 'triggerStart',
        sendLocate: "sendLocate", // se concateneaza cu concat("-", device_id) si se trimite din notificari cand vine notif cu locatia neschimbata
        dataResolverDone: 'dataResolverDone',
        premiumScheduleChange: 'premiumScheduleChange',
        uiModalClose: 'uiModalClose',
        targetActivityLoaded: 'targetActivityLoaded',
        subscriptionChanged: 'subscriptionsChanged',
        openCongratsNewDevice: 'openCongratsNewDevice',
        notificationsCounterChange: 'notificationsCounterChange',
        adobeNavigationSent: 'adobeNavigationSent',
        devices: {
            setActiveDevice: 'activeDevice',
            issuesChanged: 'issuesChanged',
            locationChanged: 'locationChanged',
            settingsChanged: 'settingsChanged',
            snapPhotoChanged: 'snapPhotoChanged'
        },
        errorsReset: 'errorsReset',
        profileCreated: 'profileCreated',
        parentalContentFilteringUpdating: 'parentalContentFilteringUpdating',
        parentalContentFilteringFinishedUpdating: 'parentalContentFilteringFinishedUpdating',
        parentalDevicePageReload: 'parentalDevicePageReload',
        profileChanges: 'profilechanges',
        parentalOrganiserReloaded: 'parentalOrganiserReloaded',
        privacy: {
            changeImpersonationStatus: 'changeImpersonationStatus',
            setImpersonationActionStatus: 'setImpersonationActionStatus',
            setIssueAsImpersonation: 'setIssueAsImpersonation',
        },
        eventsOptionCases : {
            onLangChange: 'onLangChange'
        },
        childCreatedParental: 'childCreatedParental',
        sessionExpired: 'sessionExpired',
        saferpassStatusChanged: 'saferpassStatusChanged',
        idTheftEnrollmentChanged: 'idTheftEnrollmentChanged'
    }

    omnitureEvents = {
        detailsThreatExpanded: 'detailsThreatExpanded',
        protectDevicesWithSuperiorSubSkipped: 'ProtectDevicesWithSuperiorSubSkipped',
        protectDevicesWithSuperiorSub: 'ProtectDevicesWithSuperiorSub',
        installPasswordManagerExtension: 'installPasswordManagerExtension',
        installPasswordManagerDownloadOptions: 'installPasswordManagerDownloadOptions',
        askTheCommunityMenuEntry: 'AskTheCommunityMenuEntry',
        sessionExpired: 'sessionExpired',
        dipRenewSubscription: 'RenewSubscriptionRegion'
    };

    protectionStatus = {
        PROTECTED: 1,
        AT_RISK: 2,
        UNPROTECTED: 3,
        NO_SUBSCRIPTION: 4,
        DISCONNECTED: 5
    };

    processServiceState = {
        WAITING: 'waiting',
        INPROGRESS: 'in_progress',
        DONE: 'done'
    };

    threatsChartScenarios = {
        dashboard: "dashboard",
        devicesDetails: "devicesDetails"
    };

    subsciptionsV3Path = "/subscriptionsv3";

    subscriptionsPaths = {
        "/subscriptions/services": { path: this.subsciptionsV3Path, id: false },
        "/subscriptions/payments": { path: "/payments", id: false },
        "/subscriptions/payments/details": { path: "/paydetails", id: true }
    };

    publicCentralPaths = {
        download: { id: 'download', label: '', path: '/download' },
        cookies: { id: 'cookies', label: '', path: '/cookies'},
    };


    /**
     * For redirect_url we need the flag 'whitelisted' at the end of the whitelisted path
     * And also, if the redierect_url has query parameters, param: <param> property is needed
     * This property tells you if you need to make put some part of the url as a query parameter
     */
    centralPaths = {
        '404'          : { id: '404', label: '', path: '/404', authenticated: false },
        '500'          : { id: '500', label: '', path: '/500', authenticated: false },
        home           : { id: 'home', label: '', path: '/home', authenticated: false },
        dashboard      : { id: 'dashboard', label: '', path: '/dashboard', authenticated: true, openModalPriority: 1,
                                passwordmanager: { id: 'passwordmanager', label: '', path: '/passwordmanager'},
                                threats        : { id: 'threats', label: '', path: '/threats' }
        },
        account        : { id: 'account', label: '', path: '/account', authenticated: true,
                                sessions : { id: 'sessions',label: '',path: '/sessions' },
                                settings : { id: 'settings',label: '',path: '/settings' },
                                profile : { id: 'profile',label: '',path: '/profile' },
                                changepass : { id: 'changepass',label: '',path: '/changepass' },
                                admin : { id: 'admin', label: '', path: '/admin'},
                                delete : { id: 'delete',label: '',path: '/delete'},
        },
        admin          : { id: 'admin', label: '', path: '/account/admin', authenticated: true },
        devices        : { id: 'devices', label: '', path: '/devices',  openModalPriority: 3, authenticated: true,
                            "details": { id: 'details',label: '', path: '/details' }
        },
        offers         : { id: 'offers',label: '',path: '/offers',  openModalPriority: 4, authenticated: true },
        parentaladvisor: { id: 'parentaladvisor',label: '', path: '/parentaladvisor', authenticated: true },
        parental       : { id: 'parental', label: '', path: '/parental',  openModalPriority: 7, authenticated: true,
                                screentime   : { id: 'screentime', label: '', path: '/screentime',
                                    limits : { id: 'limits', label: '', path: '/limits' },
                                    schedule : { id: 'schedule', label: '', path: '/schedule' },
                                    history : { id: 'history', label: '', path: '/history' }
                                },
                                applications : { id: 'applications', label: '', path: '/applications' },
                                websites     : { id: 'websites', label: '', path: '/websites',
                                    categories: { id: 'categories', label: '', path: '/categories' }
                                },
                                location     : { id: 'location', label: '', path: '/location' },
                                contacts     : { id: 'contacts', label: '', path: '/contacts' },
                                devices      : { id: 'devices', label: '', path: '/devices' },
                                settings     : { id: 'settings', label: '', path: '/settings' },
        },
        parentalncc     : { id: 'parentalncc', label: '', path: '/parentalncc', authenticated: true,
                            devices: { id: 'devices', label: '', path: '/devices'},
                            content: { id: 'content', label: '', path: '/content'},
                            usage: { id: 'usage', label: '', path: '/usage'},
                            blockedcontent: { id: 'blockedcontent', label: '', path: '/blockedcontent'},
                            dtl: { id: 'dtl', label: '', path: '/dtl'},
                            routines: { id: 'routines', label: '', path: '/routines',
                                        focustime: { id: 'focustime', label: '', path: '/focustime'},
                                        bedtime: { id: 'bedtime', label: '', path: '/bedtime'},
                                        dinnertime: { id: 'dinnertime', label: '', path: '/dinnertime'}
                                    },
                            dashboard: { id: 'dashboard', label: '', path: '/dashboard'}
        },
        paydetails     : { id: 'paydetails', label: '', path: '/paydetails', authenticated: true },
        privacy : {
            id: 'privacy', label: '', path: '/privacy', openModalPriority: 8, authenticated: true,
            activity: {

                id: 'activity', label: '', path: '/activity',
                risk: { id:'risk', label: '', path: '/risk',
                        details: { id:'details', label: '', path: '/details' }
                    },
                increaseScore: { id:'increaseScore', label: '', path: '/increase-score',
                                details: { id:'details', label: '', path: '/details' }
                },
                score: { id:'score', label: '', path: '/score',
                        details: {id: 'details', label: '', path: '/details'}
                },
            },
            footprint: {
                id: 'footprint', label: '', path: '/footprint',
                details: {id: 'details', label: '', path: '/details'}
            },
            breaches: {
                id: 'breaches', label: '', path: '/breaches',
                details: { id: 'details',label: '', path: '/details' }
            },
            impersonations: { id: 'impersonations', label: '', path: '/impersonations'},
            brokers: { id: 'brokers', label: '', path: '/brokers'},
            education: {
                id: 'education', label: '', path: '/education',
                article: { id: 'article', label: '', path: '/article', param: "articleId" }
            },
            history: { id: 'history', label: '', path: '/history'},
            monitor: { id: 'monitor', label: '', path: '/monitor'},
        },
        premium        : { id: 'premium',label: '',path: '/premium', openModalPriority: 5, authenticated: true },
        subscriptions  : { id: 'subscriptions', label: '', path: '/subscriptions', openModalPriority: 2, authenticated: true,
                                services: { id: 'services', label: '', path: '/services', openModalPriority: 2 },
                                billing:  { id: 'billing',  label: '', path: '/billing' },
                                invoices: { id: 'invoices', label: '', path: '/invoices' },
                                payments: { id: 'payments', label: '', path: '/payments',
                                    details: {id: 'details', label: '', path: '/details'}
                                }
                            },
        subscriptionsv3 : { id: 'subscriptionsv3', label: '', path: '/subscriptionsv3', authenticated: true,
                                services: { id: 'services', label: '', path: '/services',
                                    details: {id: 'details', label: '', path: '/details'}
                                },
                                billing:  { id: 'billing',  label: '', path: '/billing' },
                                invoices: { id: 'invoices', label: '', path: '/invoices' },
                                payments: { id: 'payments', label: '', path: '/payments',
                                    details: {id: 'details', label: '', path: '/details'}
                                }
                            },
        support        : { id: 'support',label: '',path: '/support',  openModalPriority: 6, authenticated: true,
                                results: {id: 'results', label: '', path: '/results'},
                                form: { id: 'form', label: '', path: '/form' },
                                article: { id: 'article', label: '', path: '/article' }
                            },
        services       : { id: 'services', label: '', path: '/services', authenticated: false,
                            webmailprotection   : {id: 'webmailprotection', label: '', path: '/webmailprotection'},
                            lottery   : {id: 'lottery', label: '', path: '/lottery'},
                            thirdparty: { id: 'thirdparty', label: '', path: '/thirdparty'},
                            vpn: { id: 'vpn', label: '', path: '/vpn',
                                    'account': { id: 'account', label: '', path: '/account' }
                                    },
                            delete: { id: 'delete', label: '', path: '/delete',
                                        pending: { id: 'pending', label: '', path: '/pending'},
                                        confirm: { id: 'confirm', label: '', path: '/confirm'}
                            }},
        referral       : { id: 'referral', label: '', path: '/referral', authenticated: true },
        passwordmanager: { id: 'passwordmanager', label: '', path: '/passwordmanager', authenticated: true },
        vpn            : { id: 'vpn', label: '', path: '/vpn', authenticated: true },
        idtheftprotection: { id: 'idtheftprotection', label: '', path: '/idtheftprotection', authenticated: true },
        security : {
            id: 'security', label: '', path: '/security', authenticated: true,
            overview: { id: 'overview', label: '', path: '/overview'},
            webmailprotection: { id: 'webmailprotection', label: '', path: '/webmailprotection' }
        },
    };

    mainPaths = new Set([
        this.centralPaths.dashboard.id,
        this.centralPaths.devices.id,
        this.centralPaths.offers.id,
        this.centralPaths.parental.id,
        this.centralPaths.parentalncc.id,
        this.centralPaths.vpn.id,
        this.centralPaths.privacy.id,
        this.centralPaths.premium.id,
        this.centralPaths.subscriptions.id,
        this.centralPaths.support.id,
        this.centralPaths.referral.id,
        this.centralPaths.passwordmanager.id,
        this.centralPaths.idtheftprotection.id,
        this.centralPaths.security.id
    ]);

    exceptionsQueryParamsPath = [
        '/services/safe_zone'
    ];

    //  -------------------- themeable logos ----------------------
    centralLogos = {
        manufacturer: {
            path: this.configService.config.manufacturerLogo,
            alt: this.configService.config.manufacturerName,
            class: 'manufacturer',
            icon: 'brand-squircle-hollow'
        },
        app: {
            path: this.configService.config.logo,
            alt: `${this.configService.config.manufacturerName} ${this.configService.config.siteName}`,
            class: 'app',
            icon: 'brand-squircle-hollow'
        },
        alpha: {
            path: this.configService.config.pathImages.concat('logo/logoAlpha.svg'),
            alt: `${this.configService.config.manufacturerName} ${this.configService.config.siteName}`,
            class: 'alpha',
            icon: 'brand-squircle-hollow'
        },
        beta: {
            path: this.configService.config.logo,
            alt: `${this.configService.config.manufacturerName} ${this.configService.config.siteName}`,
            class: 'beta',
            icon: 'brand-squircle-hollow'
        }
    }

    //  ------------------------- Images -------------------------
    centralImages = {
        // Not changable poweredBy logos
        poweredByLight: {
            link: this.configService.config.pathImages.concat('PoweredBy-Bitdefender-light.svg'),
            alt: ''
        },
        poweredByDark: {
            link: this.configService.config.pathImages.concat('PoweredBy-Bitdefender-dark.svg'),
            alt: ''
        },
        appIcon: {
            link: this.configService.config.pathImages.concat('app-icon.svg'),
            alt: ''
        },
        // to be deleted (images from paths also) when install-parental-modal will be replaced with install-protection-modal
        illustrationInstallProtection: {
            link: this.configService.config.pathImages.concat('parental/illustration_install_protection.svg'),
            alt: 'devices.details.action.install.image.alt'
        },
        illustrationOtherDevices: {
            link: this.configService.config.pathImages.concat('parental/illustration_other_devices.svg'),
            alt: ''
        },
        illustrationThisDevice: {
            link: this.configService.config.pathImages.concat('parental/illustration_this_device.svg'),
            alt: ''
        },
        illustrationInstallSendProtection: {
            link: this.configService.config.pathImages.concat('parental/illustration_send.svg'),
            alt: 'install.parental.modal.send.protection.image.alt'
        },
        illustrationPerson: {
            link: this.configService.config.pathImages.concat('parental/person-illustration.svg'),
            alt: ''
        },
        illustrationMultipleDevices: {
            link: this.configService.config.pathImages.concat('parental/multiple-devices-illustration.svg'),
            alt: ''
        },
        illustrationToy: {
            link: this.configService.config.pathImages.concat('parental/horse-toy-illustration.svg'),
            alt: ''
        },
        illustrationDone: {
            link: this.configService.config.pathImages.concat('install/illustration_done.svg'),
            alt: ''
        },
        illustrationInstallParental: {
            link: this.configService.config.pathImages.concat('parental/devices-parental2X.png'),
            alt: ''
        },
        illustrationWaitingInstallParental: {
            link: this.configService.config.pathImages.concat('parental/devices-waiting2X.png'),
            alt: ''
        },
        devicesWaiting: {
            link: this.configService.config.pathImages.concat('parental/devices-waiting2X.png'),
            alt: 'install.parental.modal.installation.pennding.image.alt'
        },
        devicesDone: {
            link: this.configService.config.pathImages.concat('parental/devices-done@2x.png'),
            alt: 'install.parental.modal.installation.done.image.alt'
        },
        computerWaiting: {
            link: this.configService.config.pathImages.concat('parental/computer-waiting2X.png'),
            alt: 'install.parental.modal.computer.waiting.image.alt'
        },
        computerDone: {
            link: this.configService.config.pathImages.concat('parental/computer-done2X.png'),
            alt: 'install.parental.modal.computer.done.image.alt'
        },
        mobileWaiting: {
            link: this.configService.config.pathImages.concat('parental/mobile-waiting2X.png'),
            alt: 'install.parental.modal.mobile.waiting.image.alt'
        },
        mobileDone: {
            link: this.configService.config.pathImages.concat('parental/mobile-done2X.png'),
            alt: 'install.parental.modal.mobile.done.image.alt'
        },
        // end to be deleted
        illustrationCelebrate: {
            link: this.configService.config.pathImages.concat('subscriptions/ill_celebrate.svg'),
            alt: ''
        },
        illustrationChurnPreventionCelebrate: {
            link: this.configService.config.pathImages.concat('subscriptions/ill_celebrate@2x.png'),
            alt: 'onboarding.congrats.install.protection.image.alt'
        },
        illustrationSplitTunneling: {
            link: this.configService.config.pathImages.concat('vpn/ill_split_tunneling.svg'),
            alt: 'vpn.about.image.alt'
        },
        illustrationReferralPage: {
            link: this.configService.config.pathImages.concat('referral/ill_referral_page.png'),
            alt: 'generic.referral.image.alt'
        },
        illustrationDataBrokers: {
            link: this.configService.config.pathImages.concat('privacy/ill_data_brokers.png'),
            alt: 'privacy.brokers.image.alt'
        },
        illustrationPrivacyCta: {
            link: this.configService.config.pathImages.concat('privacy/ill_install_cta.svg'),
            alt: 'install.device.modal.privacy.cta.title'
        },
        illustrationSharedFamilyPlan: {
            link: this.configService.config.pathImages.concat('privacy/ill_dip_family_plan.svg'),
            alt: 'privacy.activity.alert.shared.plan.title'
        },
        illustrationIll404: {
            link: this.configService.config.pathImages.concat('illustrations/ill_404.svg'),
            alt: 'page.404.title'
        },
        illustrationIll500: {
            link: this.configService.config.pathImages.concat('illustrations/ill_500.svg'),
            alt: 'page.500.title'
        },
        installCentral: {
            link: this.configService.config.pathImages.concat('dashboard/community.svg'),
            alt: 'generic.join.community.image.alt'
        },
        installProtection: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png'),
            alt: 'devices.details.action.install.image.alt'
        },
        installVPN: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/vpn-standard.png'),
            alt: 'vpn.action.install.image.alt'
        },
        installParental: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/parentalcontrolsecurity.png'),
            alt: 'install.parental.modal.install.image.alt'
        },
        thisDevice: {
            link: this.configService.config.pathImages.concat('install/device-gradient.svg'),
            alt: 'install.device.modal.this.device.image.alt'
        },
        otherDevice: {
            link: this.configService.config.pathImages.concat('install/other-devices-gradient.svg'),
            alt: 'install.device.modal.other.devices.image.alt'
        },
        errorDownload: {
            link: this.configService.config.pathImages.concat('install/ilustration_warning.svg'),
            alt: 'dashboard.devices.error'
        },
        multipleDevices: {
            link: this.configService.config.pathImages.concat('dashboard/illustration_multiple_devices.svg'),
            alt: 'generic.multipleDevices.image.alt'
        },
        thisDeviceNew: {
            link: this.configService.config.pathImages.concat('dashboard/ill_protect_1-device.svg'),
            alt: 'install.device.modal.this.device.image.alt'
        },
        thisDeviceMobile: {
            link: this.configService.config.pathImages.concat('dashboard/ill_protect-mobile-device.svg'),
            alt: 'install.device.modal.this.device.image.alt'
        },
        downloadProtection: {
            link: this.configService.config.pathImages.concat('dashboard/ill_download_protection.svg'),
            alt: 'generic.downloadProtection.image.alt'
        },
        dipProtection: {
            link: this.configService.config.pathImages.concat('privacy/ill_protecting_your_identity.svg'),
            alt: 'redeem.modal.protect.identity.image.alt'
        },
        dipProtectionTimeout: {
            link: this.configService.config.pathImages.concat('privacy/ill_idt_timeout.svg'),
            alt: 'privacy.protect.identity.timeout.alt',
        },
        vpnMap: {
            link: this.configService.config.pathImages.concat('vpn/vpn-map.svg'),
            alt: 'vpn.map.image.alt'
        },
        emptyStateParental: {
            link: this.configService.config.pathImages.concat('parental/empty-state.png'),
            alt: 'parental.empty.state.image.alt'
        },
        parentalProfile: {
            link: this.configService.config.pathImages.concat('dashboard/ill_central_upsell.png'),
            alt: 'generic.parentalProfile.image.alt'
        },
        welcome: {
            link: this.configService.config.pathImages.concat('onboarding/ill_welcome.svg'),
            alt: 'generic.welcome.image.alt'
        },
        benefits: {
            link: this.configService.config.pathImages.concat('onboarding/ill_benefits.svg'),
            alt: 'generic.benefits.image.alt'
        },
        trial: {
            link: this.configService.config.pathImages.concat('onboarding/ill_try.svg'),
            alt: 'onboarding.trial.image.alt'
        },
        activation: {
            link: this.configService.config.pathImages.concat('onboarding/ill_activate_subscription.svg'),
            alt: 'onboarding.activation.image.alt'
        },
        autoRenewal: {
            link: this.configService.config.pathImages.concat('onboarding/ill_auto_renewal.svg'),
            alt: 'auto.renewal.image.alt'
        },
        protectAllDevices: {
            link: this.configService.config.pathImages.concat('onboarding/ill_protect_all_devices.svg'),
            alt: 'onboarding.redeem.protection.devices.image.alt'
        },
        reactivateProtection: {
            link: this.configService.config.pathImages.concat('onboarding/ill_reactivate_subscription.svg'),
            alt: 'subscriptions.reactivate.protection.image.alt'
        },
        expiredSubscription: {
            link: this.configService.config.pathImages.concat('onboarding/ill_subscription_expired.svg'),
            alt: 'onboarding.subscription.expired.image.alt'
        },
        expiredTrial: {
            link: this.configService.config.pathImages.concat('onboarding/ill_trial_expired.svg'),
            alt: 'onboarding.trial.expired.image.alt'
        },
        'appStorePng': {
            link: this.configService.config.pathImages.concat('unused/appstore-black-btn.png'),
            alt: 'App Store'
        },
        googlePlayPng: {
            link: this.configService.config.pathImages.concat('unused/gplay-black-btn.png'),
            alt: 'App Store'
        },
        'appStoreSvg': {
            link: this.configService.config.pathImages.concat('common/icon_App_Store.svg'),
            alt: 'App Store'
        },
        'appStoreComingSoonSvg': {
            link: this.configService.config.pathImages.concat('common/icon_App_Store_coming_soon.svg'),
            alt: 'App Store'
        },
        googlePlaySvg: {
            link: this.configService.config.pathImages.concat('common/icon_Google_Play.svg'),
            alt: 'Google Play'
        },
        osxSvg: {
            link: this.configService.config.pathImages.concat('common/icon_osx.svg'),
            alt: 'osx'
        },
        windowsSvg: {
            link: this.configService.config.pathImages.concat('common/icon_windows.svg'),
            alt: 'Windows'
        },
        visaPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/visa.png'),
            alt: 'visa'
        },
        mcPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/mc.png'),
            alt: 'mc'
        },
        amexPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/amex.png'),
            alt: 'amex'
        },
        discoverPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/discover.png'),
            alt: 'discover'
        },
        idealPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/ideal.png'),
            alt: 'ideal'
        },
        sepaPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/sepa.png'),
            alt: 'sepa'
        },
        paypalPng: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/paypal.png'),
            alt: 'paypal'
        },
        applepaySvg: {
            link: this.configService.config.pathImages.concat('subscriptions/cards/apple.svg'),
            alt: 'ApplePay'
        },
        removeDeviceAnimation: {
            link: this.configService.config.pathImages.concat('devices/remove_device.png'),
            alt: 'generic.remove.device.animation.image.alt'
        },
        requestErrorSvg: {
            link: this.configService.config.pathImages.concat('illustrations/request-error.svg'),
            alt: 'generic.request.error.image.alt'
        },
        // password manager
        'passManagerDashboard': {
            link: this.configService.config.pathImages.concat('dashboard/password-manager.svg'),
            alt: 'password.manager.title.complete'
        },
        passManagerWallet: {
            link: this.configService.config.pathImages.concat('dashboard/password-manager-wallet.svg'),
            alt: 'password.manager.wallet.image.alt'
        },
        passManagerVault: {
            link: this.configService.config.pathImages.concat('dashboard/password-manager_loader.svg'),
            alt: 'install.device.modal.this.device.keep.passwords'
        },
        illExportData: {
            link: this.configService.config.pathImages.concat('password-manager/ill_export_data.svg'),
            alt: ''
        },
        illCreateCentralAccount: {
            link: this.configService.config.pathImages.concat('password-manager/ill_create_central_account.svg'),
            alt: ''
        },
        trialWallet: {
            link: this.configService.config.pathImages.concat('password-manager/wallet-checked.svg'),
            alt: 'generic.trial.wallet.image.alt'
        },
        illPmFamilyPlan: {
            link: this.configService.config.pathImages.concat('password-manager/ill_pm_family_plan.svg'),
            alt: 'password.manager.family.plan.image.alt'
        },
        // browsers
        chromeBrowser: {
            link: this.configService.config.pathImages.concat('browser-icons/chrome_256x256.png'),
            alt: 'Google Chrome'
        },
        firefoxBrowser: {
            link: this.configService.config.pathImages.concat('browser-icons/firefox_256x256.png'),
            alt: 'Mozilla Firefox'
        },
        edgeBrowser: {
            link: this.configService.config.pathImages.concat('browser-icons/edge_256x256.png'),
            alt: 'Microsoft Edge'
        },
        safariBrowser: {
            link: this.configService.config.pathImages.concat('browser-icons/safari_256x256.png'),
            alt: 'Safari'
        },
        newDevice: {
            link: this.configService.config.pathImages.concat('devices/newdevice_illustration.svg'),
            alt: 'devices.new.device.install.image.alt'
        },
        // Lottery
        lotteryMainPic: {
            link: this.configService.config.pathImages.concat('lottery/main_img.png'),
            alt: 'lottery.reviwer.image.alt'
        },
        lotteryMainPicMobile: {
            link: this.configService.config.pathImages.concat('lottery/main_img_mobile.png'),
            alt: 'lottery.reviwer.image.alt'
        },
        lotteryErrorIllustration: {
            link: this.configService.config.pathImages.concat('illustrations/ill_error.svg'),
            alt: 'generic.load.error.alt'
        },
        // Password manager LP
        passwordManagerLp: {
            link: this.configService.config.pathImages.concat('landingpage/passwordManager/lp-bg.png'),
            alt: 'password.manager.landingPage.image.alt'
        },
        passwordManagerLpData: {
            link: this.configService.config.pathImages.concat('landingpage/passwordManager/data-security.svg'),
            alt: 'password.manager.column.1.alt'
        },
        passwordManagerLpBrowser: {
            link: this.configService.config.pathImages.concat('landingpage/passwordManager/browsers.svg'),
            alt: 'password.manager.column.2.alt'
        },
        passwordManagerLpExport: {
            link: this.configService.config.pathImages.concat('landingpage/passwordManager/import-export.svg'),
            alt: 'password.manager.column.3.alt'
        },
        passwordManagerLpEolWallet: {
            link: this.configService.config.pathImages.concat('landingpage/passwordManager/lp-eolwallet-bg.png'),
            alt: 'password.manager.landingPage.eolWallet.image.alt'
        },
        // social icons
        facebookPng: {
            link: this.configService.config.pathImages.concat('social/facebook.png'),
            alt: 'Facebook'
        },
        instagramPng: {
            link: this.configService.config.pathImages.concat('social/instagram.png'),
            alt: 'Instagram'
        },
        linkedinPng: {
            link: this.configService.config.pathImages.concat('social/linkedin.png'),
            alt: 'LinkedIn'
        },
        twitterPng: {
            link: this.configService.config.pathImages.concat('social/twitter.png'),
            alt: 'Twitter'
        },
        // subscriptions
        loadingConvertion: {
            link: this.configService.config.pathImages.concat('subscriptions/billing_loader@2x.png'),
            alt: 'Billing Loader'
        },
        shoppingCart: {
            link: this.configService.config.pathImages.concat('subscriptions/ill_shopping_cart.png'),
            alt: 'Shopping Cart'
        },
        passwordManagerBundle: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/passManager.png'),
            alt: 'password.manager.bundle.image.alt'
        },
        ts: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/ts.png'),
            alt: 'Total Security'
        },
        idTheft: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/idTheftStandard.png'),
            alt: ''
        },
        idTheftPremium: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/idTheftPremium.png'),
            alt: ''
        },
        ultimateSecurity: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/ultimateSecurity.png'),
            alt: ''
        },
        ultimateSecurityLp: {
            link: this.configService.config.pathImages.concat('landingpage/ultimateSecurity/lp-bg.png'),
            alt: ''
        },
        illInviteSent: {
            link: this.configService.config.pathImages.concat('subscriptions/ill_invite_sent.svg'),
            alt: 'shared.subscription.invite.sent.image.alt'
        },
        illNoSubscription: {
            link: this.configService.config.pathImages.concat('subscriptions/no_subscription_ill.svg'),
            alt: 'subscriptions.empty'
        },
        // privacy LP
        privacyLp: {
            link: this.configService.config.pathImages.concat('landingpage/dataPrivacy/lp-bg.png'),
            alt: 'privacy.emptypage.image.alt'
        },
        privacyLp360View: {
            link: this.configService.config.pathImages.concat('landingpage/dataPrivacy/360-view.png'),
            alt: 'privacy.emptypage.column.1.image.alt'
        },
        privacyLpMonitor: {
            link: this.configService.config.pathImages.concat('landingpage/dataPrivacy/monitor.png'),
            alt: 'privacy.emptypage.column.2.image.alt'
        },
        privacyLpQuickActions: {
            link: this.configService.config.pathImages.concat('landingpage/dataPrivacy/quick-actions.png'),
            alt: 'privacy.emptypage.column.3.image.alt'
        },
        installParentalNCC: {
            link: this.configService.config.pathImages.concat('subscriptions/bundles/nccparental.png'),
            alt: 'parental.action.install.image.alt'
        },
        dailyTimeLimit: {
            link: this.configService.config.pathImages.concat('parental/daily-time-ill.svg'),
            alt: 'parental.daily.time.limit.title'
        },
        restrictionsEmptyPage: {
            link: this.configService.config.pathImages.concat('parental/exclusions-empty.svg'),
            alt: ''
        },
        childProfile: {
            link: this.configService.config.pathImages.concat('parental/child_profile.png'),
            alt: ''
        },
        contentFilter: {
            link: this.configService.config.pathImages.concat('parental/content-filtering.svg'),
            alt: ''
        },
        contentFilteringAllowed : {
            link: this.configService.config.pathImages.concat('parental/icon-allowed.svg'),
            alt: 'parental.contentfiltering.allowed'
        },
        contentFilteringBlocked : {
            link: this.configService.config.pathImages.concat('parental/icon-blocked.svg'),
            alt: 'parental.contentfiltering.blocked'
        },
        contentFilteringTopic: {
            link: this.configService.config.pathImages.concat('parental/exception-topic.svg'),
            alt: ''
        },
        contentFilteringWebsite: {
            link: this.configService.config.pathImages.concat('parental/exception-website.svg'),
            alt: ''
        },
        focustime: {
            link: this.configService.config.pathImages.concat('parental/focus_time.png'),
            alt: 'parental.routines.title.focustime'
        },
        dinnertime: {
            link: this.configService.config.pathImages.concat('parental/dinner_time.png'),
            alt: 'parental.routines.title.dinnertime'
        },
        bedtime: {
            link: this.configService.config.pathImages.concat('parental/bedtime.png'),
            alt: 'parental.routines.title.bedtime'
        },
        addSchedule: {
            link: this.configService.config.pathImages.concat('parental/bed_zero_routine.png'),
            alt: 'parental.screentime.setSchedule'
        },
        pauseInternet: {
            link: this.configService.config.pathImages.concat('parental/pauseInternet.png'),
            alt: ''
        },
        noDevices: {
            link: this.configService.config.pathImages.concat('parental/mobile_setup_ill.svg'),
            alt: ''
        },
        noSubscription: {
            link: this.configService.config.pathImages.concat('parental/no-subscription.svg'),
            alt: ''
        },
        noDevicesParentalNcc: {
            link: this.configService.config.pathImages.concat('parental/no-devices-assign.svg'),
            alt: ''
        },
        iosDevices: {
            link: this.configService.config.pathImages.concat('parental/ill_welcome.svg'),
            alt: ''
        },
        emptyStateBlockedContent: {
            link: this.configService.config.pathImages.concat('parental/empty_state_blocked_content.svg'),
            alt: ''
        },
        iosUsage: {
            link: this.configService.config.pathImages.concat('parental/ill_iosUsage.svg'),
            alt: ''
        },
        hpDevicesMockup: {
            link: this.configService.config.pathImages.concat('landingpage/homepage/devices-mock-up.png'),
            alt: ''
        },
        illSendFeedback: {
            link: this.configService.config.pathImages.concat('/security/ill-send-feedback.svg'),
            alt: 'security.webmailprotection.illSendFeedback.image.alt'
        },
        illWebmailProtection: {
            link: this.configService.config.pathImages.concat('/security/ill-webmail-protection.svg'),
            alt: 'security.webmailprotection.illWebmailProtection.image.alt'
        },
        illError: {
            link: this.configService.config.pathImages.concat('/security/ill-error.svg'),
            alt: 'security.webmailprotection.illError.image.alt'
        },
        illEmailProtected: {
            link: this.configService.config.pathImages.concat('/security/ill-email-protected.svg'),
            alt: 'security.webmailprotection.illEmailProtected.image.alt'
        },
        illMissingPermissions: {
            link: this.configService.config.pathImages.concat('/security/ill-missing-permissions.svg'),
            alt: 'security.webmailprotection.illMissingPermissions.image.alt'
        },
        illNoActivity: {
            link: this.configService.config.pathImages.concat('/security/ill-no-activity.svg'),
            alt: 'security.webmailprotection.illWebmailProtection.image.alt'
        },
        providerOutlook: {
            link: this.configService.config.pathImages.concat('/security/provider-outlook.svg'),
            alt: 'Outlook'
        },
        providerGmail: {
            link: this.configService.config.pathImages.concat('/security/provider-gmail.svg'),
            alt: 'Gmail'
        },
        illArticles: {
            link: this.configService.config.pathImages.concat('/security/overview/ill_articles.svg'),
            alt: ''
        },
        illProtectDevices: {
            link: this.configService.config.pathImages.concat('/security/overview/ill_protected_devices.svg'),
            alt: ''
        },
        servicesError: {
            link: this.configService.config.pathImages.concat('/security/services-error.svg'),
            alt: ''
        }
    };

    qrCodeTypes = ["svg", "png", "pdf"];

    offersSeenValue = "false";
    offersNotSeenValue = "true";

    offersCampaign = {
        date: {
            start: () => window['_glCnfg']?.offersCampaign?.start,
            end: () => window['_glCnfg']?.offersCampaign?.end
        },
        pageEntry: {
            showTitle: false,
            bgColor: '#000'
        },
        menuIcon: {
            type: 'feature',
            name: 'black-friday-v2'
        },
        headerEntry: {
            label: 'offers.BfCampaign.header.label',
            image: this.configService.config.pathImages.concat('illustrations/BF-label.svg'),
            cta: {
                label: 'offers.BfCampaign.header.cta.label',
                model: 'solid',
                color: 'danger',
                size: 'md',
                icid: 'button|c|central|myoffers-page|bf'
            }
        }
    };

    loginTimestamp = "loginTimestamp";

    // THIS must be used in all project !!!
    connectIcons = {
        password: 'connect_icon_password',
        account: 'connect_icon_account',
        session: 'connect_icon_session',
        settings: 'connect_icon_settings',
        logout: 'connect_icon_logout',
        arrowBack: 'connect_icon_arrow-back',
        user: 'connect_icon_user',
        ceas: 'connect_icon_ceas',
        timeLimit: 'connect_icon_time_limit',
        notification: 'connect_icon_notification',
    };


    singleAppBundlesFeatures = {
        [this.bundleDPI]: [
            "subscriptions.features.dip1",
            "subscriptions.features.dip2",
            "subscriptions.features.dip3",
            "subscriptions.features.dip4"
        ],
        [this.bundleAVFREE]: [
            "subscriptions.features.avfree1",
            "subscriptions.features.avfree2",
            "subscriptions.features.avfree3"
        ],
        [this.bundleAVLITE]: [
            "subscriptions.features.avlite1",
            "subscriptions.features.avlite2",
            "subscriptions.features.avlite3"
        ],
        [this.bundleBIP]: [
            "subscriptions.features.iosprotection1",
            "subscriptions.features.iosprotection2",
            "subscriptions.features.iosprotection3"
        ],
        [this.bundleVPN]: [
            "subscriptions.features.vpnpremium1",
            "subscriptions.features.vpnpremium2",
            "subscriptions.features.vpnpremium3"
        ],
        [this.bundleHVA]: [
            "subscriptions.features.hva1",
            "subscriptions.features.hva2",
            "subscriptions.features.hva3"
        ],
        [this.bundlePM]: [
            "subscriptions.features.passmanager1",
            "subscriptions.features.passmanager2",
            "subscriptions.features.passmanager3",
            "subscriptions.features.passmanager4"
        ],
        [this.bundleIDTPS]: [
            "subscriptions.features.idtheftprotectionstandard1",
            "subscriptions.features.idtheftprotectionstandard2",
            "subscriptions.features.idtheftprotectionstandard3",
            "subscriptions.features.idtheftprotectionstandard4",
            "subscriptions.features.idtheftprotectionstandard5",
            "subscriptions.features.idtheftprotectionstandard6",
        ],
        [this.bundleIDTPP]: [
            "subscriptions.features.idtheftprotectionpremium1",
            "subscriptions.features.idtheftprotectionpremium2",
            "subscriptions.features.idtheftprotectionpremium3",
            "subscriptions.features.idtheftprotectionpremium4",
            "subscriptions.features.idtheftprotectionpremium5",
            "subscriptions.features.idtheftprotectionpremium6",
        ]
    }

    defaultAppIcon = this.configService.config.pathImages.concat('subscriptions/bundles/default-protection.png');

    ta_subs = [
        this.bundlePCO,
        this.bundleSTMP,
        this.bundleSYSR,
        this.bundleVR,
        this.bundleCTU,
        this.bundleSTFPC
    ];

    sohoBundles = [
        this.bundleSOHO,
        this.bundleVPNSOHO
    ];

    // ! To be deleted after everything is ok
    knownBundles = new Set([
        this.bundleSOHO,
        this.bundleVPN,
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundleFP,
        this.bundleFPP,
        this.bundleISMD,
        this.bundleFPAV,
        this.bundleFPIS,
        this.bundleBoxCl,
        this.bundleAVFM,
        this.bundleBMS,
        this.bundleBIS,
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleIS,
        this.bundleTS,
        this.bundlePAS,
        this.bundlePA,
        this.bundlePANCC,
        this.bundleAVFREE,
        this.bundleBOX2,
        this.bundleVPNSOHO,
        this.bundlePSec,
        this.bundlePSecNew,
        this.bundlePSecPlus,
        this.bundleUSEUNEW,
        this.bundleIOSBMS,
        this.bundleBIP,
        this.bundleTSVPN,
        this.bundleDPI,
        this.bundleHVA,
        this.bundleStartPlan,
        this.bundlePersonalPlan,
        this.bundleFamilyPlan,
        this.bundleUltimatePlan,
        this.bundlePM,
        this.bundleAVLITE,
        this.bundleIDTPS,
        this.bundleIDTPP,
        this.bundleUSUS,
        this.bundleUSUSNEW,
        this.bundleUSPUS,
        this.bundleUSPUSNEW,
        this.bundleSecIdentity
    ]);

    /**
     * List of standalone bundles that are eligible to share the subscription using 'invite_system'
     * Is different from subscription sharing for zuora
     */
    sharedSubscriptionStandaloneBundles = new Set([
        this.bundlePM
        // TODO: add DIP
    ]);

    /**
     * List of Family Plan bundles that are eligible to share the subscription using 'invite_system'
     * Is different from subscription sharing for zuora
     */
    sharedSubscriptionFamilyPlanBundles = new Set([
        this.bundleTSMDNEW,
        this.bundlePSecNew,
        this.bundleUSEUNEW,
        this.bundleUSUSNEW,
        this.bundleUSPUSNEW
    ]);

    // List of error codes with different error messages for 'invite_system'
    inviteSystemErrorCodes = {
        processingError: 39001,
        communicationError: 32802
    };

    // List of possible messages retrieved from 'invite_system' based on error code
    inviteSystemErrorMessages = {
        [this.inviteSystemErrorCodes.processingError]: {
            invalidInviteLink: 'Invalid invite_link!',
            differentUser: 'The invite was created for another user!',
            sameUser: 'You can\'t activate your invite!',
            userActive: 'Managed user is already tied to the service!',
            differentPartnerId: 'The invite was created for another partner!',
            lostRights: 'The inviter lost his rights on the service!',
            generalProblem: 'The service your were invited on has a problem!',
            inviteAlreadySent: 'An invite was already sent.',
            userAlreadyAdded: 'The invited user is a secondary of the service.'
        },
        [this.inviteSystemErrorCodes.communicationError]: {
            sendFailed: 'Error at invite sending.'
        }
    };

    // Corresponding error details objects for each possible error from onboarding flow
    inviteSystemOnboardingErrors = {
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].differentPartnerId]: {
            title: 'onboarding.invite.error.mismatch.title',
            description: 'onboarding.invite.error.mismatch.partnerId.description',
            button: 'onboarding.invite.error.button.gotit',
            buttonAction: 'done'
        },
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].differentUser]: {
            title: 'onboarding.invite.error.mismatch.title',
            description: 'onboarding.invite.error.mismatch.user.description',
            button: 'onboarding.invite.error.button.gotit',
            buttonAction: 'done'
        },
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].generalProblem]: {
            title: 'onboarding.invite.error.general.title',
            description: 'onboarding.invite.error.general.description',
            button: 'onboarding.invite.error.button.buyPassManager',
            buttonAction: 'buyPassManager'
        },
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].invalidInviteLink]: {
            title: 'onboarding.invite.error.invalid.link.title',
            description: 'onboarding.invite.error.invalid.link.description',
            button: 'onboarding.invite.error.button.close',
            buttonAction: 'done'
        },
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].lostRights]: {
            title: 'onboarding.invite.error.lost.rights.title',
            description: 'onboarding.invite.error.lost.rights.description',
            button: 'onboarding.invite.error.button.close',
            buttonAction: 'done'
        },
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].sameUser]: {
            title: 'onboarding.invite.error.same.user.title',
            description: 'onboarding.invite.error.same.user.description',
            button: 'onboarding.invite.error.button.gotit',
            buttonAction: 'done'
        },
        [this.inviteSystemErrorMessages[this.inviteSystemErrorCodes.processingError].userActive]: {
            title: 'onboarding.invite.error.already.active.title',
            description: 'onboarding.invite.error.already.active.description',
            button: 'onboarding.invite.error.button.goToSubscriptions',
            buttonAction: 'goToSubscriptions'
        },
        default: {
            title: 'onboarding.invite.error.default.title',
            description: 'onboarding.invite.error.default.description',
            button: 'onboarding.invite.error.button.tryagain',
            buttonAction: 'tryAgainInvite'
        }
    };

    devicesPerRequest = 30;

    appLevels = {
        EPP: "epp",
        EPP_PRIVACY: "epp_privacy",
        TS: "ts",
        LITE: "lite"
    };

    agentStatus = {
        NOT_INSTALLED: 0,
        PENDING_INSTALL: 1,
        INSTALLED: 2,
        PENDING_UNINSTALL: 3,
        UNINSTALLED: 4
    };

    installParentalFlag = "installParental";

    MobileOS = {
        ANDROID: OperatingSystems.ANDROID,
        IOS: OperatingSystems.IOS
    };

    downloadPageErrors = {
        UNSUPPORTED: "unsupported",
        NOCODE: "noCode"
    };

    SupportedOS = new Set([
        OperatingSystems.WINDOWS,
        OperatingSystems.ANDROID,
        OperatingSystems.OSX,
        OperatingSystems.IOS
    ]);

    compatibleOsInfo = {
        [OperatingSystems.WINDOWS]: {
            display: "Windows",
            request: OperatingSystems.WINDOWS,
            appId: this.appCL
        },
        [OperatingSystems.MAC]: {
            display: "macOS",
            request: OperatingSystems.OSX,
            appId: this.bundleAVFM
        },
        [OperatingSystems.OSX]: {
            display: "macOS",
            request: OperatingSystems.OSX,
            appId: this.bundleAVFM
        },
        [OperatingSystems.ANDROID]: {
            display: "Android",
            request: OperatingSystems.ANDROID,
            appId: this.bundleBMS
        },
        [OperatingSystems.IOS]: {
            display: "iOS",
            request: OperatingSystems.IOS,
            appId: this.bundleBIS
        }
    };

    allOsDisplayInfo = {
        ...this.compatibleOsInfo,
        [OperatingSystems.LINUX]: {
            display: "Linux",
        }
    }

    threatObjectType = {
        "file": "file",
        "app": "app",
        "url": "url",
        "behaviour": "behaviour",
        "process" : "process"
    };

    modalBufferKeys = {
        MSP_INSTALL_MODAL: '_mspInstalModal',
        MSP_ONBOARDING: '_onboardingMsp'
    };

    corespondingAppsToProductsTOInstall = {
        [ProductsToInstall.PROTECTION]: this.appProtection,
        [ProductsToInstall.VPN]: this.appVPN,
        [ProductsToInstall.PASSWORDMANAGER]: this.appPassManager,
        [ProductsToInstall.PASSWORDMANAGERSFR]: this.appPassManagerSfr
    };

    productDisplayName = {
        [ProductsToInstall.PARENTAL]: this.productNamePA,
        [ProductsToInstall.PARENTALNCC]: this.productNamePANCC,
        [ProductsToInstall.PROTECTION]: this.productNameSecurity,
        [ProductsToInstall.VPN]: this.productNameVPNBasic,
        [ProductsToInstall.PASSWORDMANAGERSFR]: this.productNamePasswordManager,
        [ProductsToInstall.PASSWORDMANAGER]: this.productNamePasswordManager
    };

    passManagerCompatibleBundles = new Set([
        this.bundleAVFM,
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleISMD,
        this.bundleTS,
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundleBMS,
        this.bundleBIP,
        this.bundleVPN,
        this.bundleBoxCl,
        this.bundleBOX2,
        this.bundleSOHO,
        this.bundleVPNSOHO,
        this.bundleDPI,
        this.bundleIOSBMS,
        this.bundleFP,
        this.bundleFPAV,
        this.bundleFPIS,
        this.bundleFPP,
        this.bundleBIS,
        this.bundleIS,
        this.bundleIDTPS,
        this.bundleIDTPP
    ]);

    productInstallationSupport = {
        [OperatingSystems.WINDOWS]: 'https://avfw.orange.fr/routeme/redirect.php?prd=bd-cp&target=support',
        [OperatingSystems.MAC]: 'https://avfw.orange.fr/routeme/redirect.php?prd=bd-cp&target=support',
        [OperatingSystems.ANDROID]: 'https://avfw.orange.fr/routeme/redirect.php?prd=bd-cp&target=support',
        [OperatingSystems.IOS]: 'https://avfw.orange.fr/routeme/redirect.php?prd=bd-cp&target=support'
    }

    openSupportFaqQueryParam = "openFaq";
    howToInstallSupportFaqIndex = "0"; // indexul intrebarii din this.faq din support.component.ts;
    supportContactSectionQueryParam = "contactSection";
    supportFormReasonQueryParam = "reason";

    appStoreLink = 'https://apps.apple.com/app/bitdefender-central/id969933082';
    googlePlayUtmSourceParam = 'central_web';

    //  ------------------------- Account -------------------------

    accountTypeSoho = 'soho';

    //  language_ids = ['en_US' ;'el_GR' ; 'es_ES';  'fr_CA'; 'fr_FR'; 'it_IT'; 'ko_KR'; 'nl_NL'; 'pl_PL';
    //  'pt_BR'; 'pt_PT'; 'ro_RO'; 'cs_CZ';'vi_VN';'ar_SA'; 'ja_JP'; 'tr_TR'];
    language_ids = [
        'de_DE', 'en_AU', 'en_GB', 'en_US', 'el_GR',
        'es_ES', 'fr_CA', 'fr_FR', 'it_IT', 'ko_KR',
        'nl_NL', 'pl_PL', 'pt_BR', 'pt_PT', 'ro_RO',
        'cs_CZ', 'vi_VN', 'ar_SA', 'ja_JP', 'tr_TR',
        'ru_RU', 'hu_HU', 'sv_SE'
    ];

    countryLanguage = {
        "gb": "uk",
        "ca": "fr",
        "de": "de",
        "nl": "nl",
        "ro": "ro",
        "au": "au",
        "br": "br",
        "fr": "fr",
        "es": "es",
        "pt": "pt",
        "it": "it",
        "gr": "gr"
    };

    /**
     * label: the language translated in that same language
     * value: index of the language
     * id: value saved inside cookie
     * flag: path to the image of the flag of country the language is spoken in
     * code: language code
     * isAvailable: flag used to enable/disable a language from the interface;
     *              if we want to remove a language from central, we put this flag on "false",
     *              no further changes are needed, we do not delete all references of that language
     *              from the project
     */
    languages = {
        'cs_CZ': {
            label: 'Česko - Česká',
            value: 0,
            id: 'cs_CZ',
            flag: this.configService.config.pathImages.concat('flags-svg/cz_flag.svg'),
            code: 'CS (CZ)',
            isAvailable: false
        },
        'de_DE': {
            label: 'Deutsch - Deutschland ',
            value: 1,
            id: 'de_DE',
            flag: this.configService.config.pathImages.concat('flags-svg/de_flag.svg'),
            code: 'DE (DE)',
            isAvailable: false
        },
        'ar_SA': {
            label: 'English - Saudi Arabia',
            value: 2,
            id: 'ar_SA',
            flag: this.configService.config.pathImages.concat('flags-svg/sa_flag.svg'),
            code: 'AR (SA)',
            isAvailable: false
        },
        'en_AU': {
            label: 'English - Australia',
            value: 3,
            id: 'en_AU',
            flag: this.configService.config.pathImages.concat('flags-svg/au_flag.svg'),
            code: 'EN (AU)',
            isAvailable: false
        },
        'en_GB': {
            label: 'English - United Kingdom',
            value: 4,
            id: 'en_GB',
            flag: this.configService.config.pathImages.concat('flags-svg/gb_flag.svg'),
            code: 'EN (GB)',
            isAvailable: false
        },
        'en_US': {
            label: 'English - United States',
            value: 5,
            id: 'en_US',
            flag: this.configService.config.pathImages.concat('flags-svg/us_flag.svg'),
            code: 'EN (US)',
            isAvailable: false
        },
        'el_GR': {
            label: 'Ελληνικά - Ελλάδα',
            value: 6,
            id: 'el_GR',
            flag: this.configService.config.pathImages.concat('flags-svg/gr_flag.svg'),
            code: 'EL (GR)',
            isAvailable: false
        },
        'es_ES': {
            label: 'Espa\xf1ol - Espa\xf1a',
            value: 7,
            id: 'es_ES',
            flag: this.configService.config.pathImages.concat('flags-svg/es_flag.svg'),
            code: 'ES (ES)',
            isAvailable: false
        },
        'fr_CA': {
            label: 'Français - Canada',
            value: 8,
            id: 'fr_CA',
            flag: this.configService.config.pathImages.concat('flags-svg/ca_flag.svg'),
            code: 'FR (CA)',
            isAvailable: false
        },
        'fr_FR': {
            label: 'Français - France',
            value: 9,
            id: 'fr_FR',
            flag: this.configService.config.pathImages.concat('flags-svg/fr_flag.svg'),
            code: 'FR (FR)',
            isAvailable: true
        },
        'it_IT': {
            label: 'Italiano - Italia',
            value: 10,
            id: 'it_IT',
            flag: this.configService.config.pathImages.concat('flags-svg/it_flag.svg'),
            code: 'IT (IT)',
            isAvailable: false
        },
        'ja_JP': {
            label: '日本語 - 日本',
            value: 11,
            id: 'ja_JP',
            flag: this.configService.config.pathImages.concat('flags-svg/jp_flag.svg'),
            code: 'JA (JP)',
            isAvailable: false
        },
        'ko_KR': {
            label: '한국어 - 한국',
            value: 12,
            id: 'ko_KR',
            flag: this.configService.config.pathImages.concat('flags-svg/kr_flag.svg'),
            code: 'KO (KR)',
            isAvailable: false
        },
        'nl_NL': {
            label: 'Dutch - Netherlands',
            value: 13,
            id: 'nl_NL',
            flag: this.configService.config.pathImages.concat('flags-svg/nl_flag.svg'),
            code: 'NL (NL)',
            isAvailable: false
        },
        'pl_PL': {
            label: 'Polski - Polska',
            value: 14,
            id: 'pl_PL',
            flag: this.configService.config.pathImages.concat('flags-svg/pl_flag.svg'),
            code: 'PL (PL)',
            isAvailable: false
        },
        'pt_BR': {
            label: 'Portugu\xeas - Brasil',
            value: 15,
            id: 'pt_BR',
            flag: this.configService.config.pathImages.concat('flags-svg/br_flag.svg'),
            code: 'PT (BR)',
            isAvailable: false
        },
        'pt_PT': {
            label: 'Portugu\xeas - Portugal',
            value: 16,
            id: 'pt_PT',
            flag: this.configService.config.pathImages.concat('flags-svg/pt_flag.svg'),
            code: 'PT (PT)',
            isAvailable: false
        },
        'ro_RO': {
            label: 'Rom\xe2n\xe3 - Rom\xe2nia',
            value: 17,
            id: 'ro_RO',
            flag: this.configService.config.pathImages.concat('flags-svg/ro_flag.svg'),
            code: 'RO (RO)',
            isAvailable: false
        },
        'tr_TR': {
            label: 'Türkçe - Türkiye',
            value: 18,
            id: 'tr_TR',
            flag: this.configService.config.pathImages.concat('flags-svg/tr_flag.svg'),
            code: 'TR (TR)',
            isAvailable: false
        },
        'vi_VN': {
            label: 'Vi\u1EC7t - Vi\u1EC7t Nam',
            value: 19,
            id: 'vi_VN',
            flag: this.configService.config.pathImages.concat('flags-svg/vn_flag.svg'),
            code: 'VI (VN)',
            isAvailable: false
        },
        'ru_RU': {
            label: 'Ру́сский Язы́к- Росси́я',
            value: 20,
            id: 'ru_RU',
            flag: this.configService.config.pathImages.concat('flags-svg/ru_flag.svg'),
            code: 'RU (RU)',
            isAvailable: false
        },
        'hu_HU': {
            label: 'Magyar Nyelv - Magyarország',
            value: 21,
            id: 'hu_HU',
            flag: this.configService.config.pathImages.concat('flags-svg/hu_flag.svg'),
            code: 'HU (HU)',
            isAvailable: false
        },
        'sv_SE': {
            label: 'Svenska - Sverige',
            value: 22,
            id: 'sv_SE',
            flag: this.configService.config.pathImages.concat('flags-svg/se_flag.svg'),
            code: 'SV (SE)',
            isAvailable: false
        },
        'th_TH': {
            label: 'ภาษาไทย - ประเทศไทย',
            value: 23,
            id: 'th_TH',
            flag: this.configService.config.pathImages.concat('flags-svg/th_flag.svg'),
            code: 'TH (TH)',
            isAvailable: false
        },
        'zh_TW': {
            label: '中文 - 繁體',
            value: 24,
            id: 'zh_TW',
            flag: this.configService.config.pathImages.concat('flags-svg/tw_flag.svg'),
            code: 'ZH (TW)',
            isAvailable: false
        }
    };

    defaultLanguage = "en_US";
    defaultShortLanguage = {
        en: this.languages.en_US.id,
        fr: this.languages.fr_FR.id,
        pt: this.languages.pt_PT.id
    };
    defaultCountry = "en";

    englishLanguages = new Set([this.languages.en_US.id,
                                this.languages.en_GB.id,
                                this.languages.en_AU.id,
                                this.languages.ar_SA.id]);

    frenchLanguages  = new Set([this.languages.fr_CA.id,
                                this.languages.fr_FR.id]);


    countries = [
        'Andorra', 'United Arab Emirates', 'Afghanistan',
        'Antigua and Barbuda', 'Anguilla', 'Albania', 'Armenia', 'Netherlands Antilles', 'Angola',
        'Antarctica', 'Argentina', 'American Samoa', 'Austria', 'Australia', 'Aruba', 'Azerbaijan',
        'Bosnia and Herzegovina', 'Barbados', 'Bangladesh', 'Belgium', 'Burkina Faso', 'Bulgaria',
        'Bahrain', 'Burundi', 'Benin', 'Bermuda', 'Brunei Darussalam', 'Bolivia', 'Brazil', 'Bahamas',
        'Bhutan', 'Bouvet Island', 'Botswana', 'Belarus', 'Belize', 'Canada', 'Cocos (Keeling) Islands',
        'Congo, The Democratic Republic of the', 'Central African Republic', 'Congo', 'Switzerland',
        'Cote D\'Ivoire', 'Cook Islands', 'Chile', 'Cameroon', 'China', 'Colombia', 'Costa Rica', 'Cuba',
        'Cape Verde', 'Christmas Island', 'Cyprus', 'Czech Republic', 'Germany', 'Djibouti', 'Denmark',
        'Dominica', 'Dominican Republic', 'Algeria', 'Ecuador', 'Estonia', 'Egypt', 'Western Sahara',
        'Eritrea', 'Spain', 'Ethiopia', 'Finland', 'Fiji', 'Falkland Islands (Malvinas)',
        'Micronesia, Federated States of', 'Faroe Islands', 'France', 'France, Metropolitan', 'Gabon',
        'United Kingdom', 'Grenada', 'Georgia', 'French Guiana', 'Ghana', 'Gibraltar', 'Greenland',
        'Gambia', 'Guinea', 'Guadeloupe', 'Equatorial Guinea', 'Greece',
        'South Georgia and the South Sandwich Islands', 'Guatemala', 'Guam', 'Guinea-Bissau', 'Guyana',
        'Hong Kong', 'Heard Island and McDonald Islands', 'Honduras', 'Croatia', 'Haiti', 'Hungary',
        'Indonesia', 'Ireland', 'Israel', 'India', 'British Indian Ocean Territory', 'Iraq',
        'Iran, Islamic Republic of', 'Iceland', 'Italy', 'Jamaica', 'Jordan', 'Japan', 'Kenya',
        'Kyrgyzstan', 'Cambodia', 'Kiribati', 'Comoros', 'Saint Kitts and Nevis',
        'Korea, Democratic People\'s Republic of', 'Korea, Republic of', 'Kuwait', 'Cayman Islands',
        'Kazakhstan', 'Lao People\'s Democratic Republic', 'Lebanon', 'Saint Lucia', 'Liechtenstein',
        'Sri Lanka', 'Liberia', 'Lesotho', 'Lithuania', 'Luxembourg', 'Latvia', 'Libyan Arab Jamahiriya',
        'Morocco', 'Monaco', 'Moldova, Republic of', 'Madagascar', 'Marshall Islands', 'Macedonia',
        'Mali', 'Myanmar', 'Mongolia', 'Macau', 'Northern Mariana Islands', 'Martinique', 'Mauritania',
        'Montserrat', 'Malta', 'Mauritius', 'Maldives', 'Malawi', 'Mexico', 'Malaysia', 'Mozambique',
        'Namibia', 'New Caledonia', 'Niger', 'Norfolk Island', 'Nigeria', 'Nicaragua', 'Netherlands',
        'Norway', 'Nepal', 'Nauru', 'Niue', 'New Zealand', 'Oman', 'Panama', 'Peru', 'French Polynesia',
        'Papua New Guinea', 'Philippines', 'Pakistan', 'Poland', 'Saint Pierre and Miquelon',
        'Pitcairn Islands', 'Puerto Rico', 'Palestinian Territory', 'Portugal', 'Palau', 'Paraguay',
        'Qatar', 'Reunion', 'Romania', 'Russian Federation', 'Rwanda', 'Saudi Arabia', 'Solomon Islands',
        'Seychelles', 'Sudan', 'Sweden', 'Singapore', 'Saint Helena', 'Slovenia',
        'Svalbard and Jan Mayen', 'Slovakia', 'Sierra Leone', 'San Marino', 'Senegal', 'Somalia',
        'Suriname', 'Sao Tome and Principe', 'El Salvador', 'Syrian Arab Republic', 'Swaziland',
        'Turks and Caicos Islands', 'Chad', 'French Southern Territories', 'Togo', 'Thailand',
        'Tajikistan', 'Tokelau', 'Turkmenistan', 'Tunisia', 'Tonga', 'Timor-Leste', 'Turkey',
        'Trinidad and Tobago', 'Tuvalu', 'Taiwan', 'Tanzania, United Republic of', 'Ukraine',
        'Uganda', 'United States Minor Outlying Islands', 'United States', 'Uruguay', 'Uzbekistan',
        'Holy See (Vatican City State)', 'Saint Vincent and the Grenadines', 'Venezuela',
        'Virgin Islands, British', 'Virgin Islands, U.S.', 'Vietnam', 'Vanuatu', 'Wallis and Futuna',
        'Samoa', 'Yemen', 'Mayotte', 'Serbia', 'South Africa', 'Zambia', 'Montenegro', 'Zimbabwe',
        'Aland Islands', 'Guernsey', 'Isle of Man', 'Jersey', 'Saint Barthelemy', 'Saint Martin'
    ];

    countriesISO = {
        'AF': 'Afghanistan',
        'AX': 'Åland Islands',
        'AL': 'Albania',
        'DZ': 'Algeria',
        'AS': 'American Samoa',
        'AD': 'Andorra',
        'AO': 'Angola',
        'AI': 'Anguilla',
        'AQ': 'Antarctica',
        'AG': 'Antigua and Barbuda',
        'AR': 'Argentina',
        'AM': 'Armenia',
        'AW': 'Aruba',
        'AU': 'Australia',
        'AT': 'Austria',
        'AZ': 'Azerbaijan',
        'BS': 'Bahamas',
        'BH': 'Bahrain',
        'BD': 'Bangladesh',
        'BB': 'Barbados',
        'BY': 'Belarus',
        'BE': 'Belgium',
        'BZ': 'Belize',
        'BJ': 'Benin',
        'BM': 'Bermuda',
        'BT': 'Bhutan',
        'BO': 'Bolivia, Plurinational State of',
        'BQ': 'Bonaire, Sint Eustatius and Saba',
        'BA': 'Bosnia and Herzegovina',
        'BW': 'Botswana',
        'BV': 'Bouvet Island',
        'BR': 'Brazil',
        'IO': 'British Indian Ocean Territory',
        'BN': 'Brunei Darussalam',
        'BG': 'Bulgaria',
        'BF': 'Burkina Faso',
        'BI': 'Burundi',
        'KH': 'Cambodia',
        'CM': 'Cameroon',
        'CA': 'Canada',
        'CV': 'Cape Verde',
        'KY': 'Cayman Islands',
        'CF': 'Central African Republic',
        'TD': 'Chad',
        'CL': 'Chile',
        'CN': 'China',
        'CX': 'Christmas Island',
        'CC': 'Cocos (Keeling) Islands',
        'CO': 'Colombia',
        'KM': 'Comoros',
        'CG': 'Congo',
        'CD': 'Congo, the Democratic Republic of the',
        'CK': 'Cook Islands',
        'CR': 'Costa Rica',
        'CI': 'Côte d\'Ivoire',
        'HR': 'Croatia',
        'CU': 'Cuba',
        'CW': 'Curaçao',
        'CY': 'Cyprus',
        'CZ': 'Czech Republic',
        'DK': 'Denmark',
        'DJ': 'Djibouti',
        'DM': 'Dominica',
        'DO': 'Dominican Republic',
        'EC': 'Ecuador',
        'EG': 'Egypt',
        'SV': 'El Salvador',
        'GQ': 'Equatorial Guinea',
        'ER': 'Eritrea',
        'EE': 'Estonia',
        'ET': 'Ethiopia',
        'FK': 'Falkland Islands (Malvinas)',
        'FO': 'Faroe Islands',
        'FJ': 'Fiji',
        'FI': 'Finland',
        'FR': 'France',
        'GF': 'French Guiana',
        'PF': 'French Polynesia',
        'TF': 'French Southern Territories',
        'GA': 'Gabon',
        'GM': 'Gambia',
        'GE': 'Georgia',
        'DE': 'Germany',
        'GH': 'Ghana',
        'GI': 'Gibraltar',
        'GR': 'Greece',
        'GL': 'Greenland',
        'GD': 'Grenada',
        'GP': 'Guadeloupe',
        'GU': 'Guam',
        'GT': 'Guatemala',
        'GG': 'Guernsey',
        'GN': 'Guinea',
        'GW': 'Guinea-Bissau',
        'GY': 'Guyana',
        'HT': 'Haiti',
        'HM': 'Heard Island and McDonald Islands',
        'VA': 'Holy See (Vatican City State)',
        'HN': 'Honduras',
        'HK': 'Hong Kong',
        'HU': 'Hungary',
        'IS': 'Iceland',
        'IN': 'India',
        'ID': 'Indonesia',
        'IR': 'Iran, Islamic Republic of',
        'IQ': 'Iraq',
        'IE': 'Ireland',
        'IM': 'Isle of Man',
        'IL': 'Israel',
        'IT': 'Italy',
        'JM': 'Jamaica',
        'JP': 'Japan',
        'JE': 'Jersey',
        'JO': 'Jordan',
        'KZ': 'Kazakhstan',
        'KE': 'Kenya',
        'KI': 'Kiribati',
        'KP': 'Korea, Democratic People\'s Republic of',
        'KR': 'Korea, Republic of',
        'KW': 'Kuwait',
        'KG': 'Kyrgyzstan',
        'LA': 'Lao People\'s Democratic Republic',
        'LV': 'Latvia',
        'LB': 'Lebanon',
        'LS': 'Lesotho',
        'LR': 'Liberia',
        'LY': 'Libya',
        'LI': 'Liechtenstein',
        'LT': 'Lithuania',
        'LU': 'Luxembourg',
        'MO': 'Macao',
        'MK': 'Macedonia, the Former Yugoslav Republic of',
        'MG': 'Madagascar',
        'MW': 'Malawi',
        'MY': 'Malaysia',
        'MV': 'Maldives',
        'ML': 'Mali',
        'MT': 'Malta',
        'MH': 'Marshall Islands',
        'MQ': 'Martinique',
        'MR': 'Mauritania',
        'MU': 'Mauritius',
        'YT': 'Mayotte',
        'MX': 'Mexico',
        'FM': 'Micronesia, Federated States of',
        'MD': 'Moldova, Republic of',
        'MC': 'Monaco',
        'MN': 'Mongolia',
        'ME': 'Montenegro',
        'MS': 'Montserrat',
        'MA': 'Morocco',
        'MZ': 'Mozambique',
        'MM': 'Myanmar',
        'NA': 'Namibia',
        'NR': 'Nauru',
        'NP': 'Nepal',
        'NL': 'Netherlands',
        'NC': 'New Caledonia',
        'NZ': 'New Zealand',
        'NI': 'Nicaragua',
        'NE': 'Niger',
        'NG': 'Nigeria',
        'NU': 'Niue',
        'NF': 'Norfolk Island',
        'MP': 'Northern Mariana Islands',
        'NO': 'Norway',
        'OM': 'Oman',
        'PK': 'Pakistan',
        'PW': 'Palau',
        'PS': 'Palestine, State of',
        'PA': 'Panama',
        'PG': 'Papua New Guinea',
        'PY': 'Paraguay',
        'PE': 'Peru',
        'PH': 'Philippines',
        'PN': 'Pitcairn',
        'PL': 'Poland',
        'PT': 'Portugal',
        'PR': 'Puerto Rico',
        'QA': 'Qatar',
        'RE': 'Réunion',
        'RO': 'Romania',
        'RU': 'Russian Federation',
        'RW': 'Rwanda',
        'BL': 'Saint Barthélemy',
        'SH': 'Saint Helena, Ascension and Tristan da Cunha',
        'KN': 'Saint Kitts and Nevis',
        'LC': 'Saint Lucia',
        'MF': 'Saint Martin (French part)',
        'PM': 'Saint Pierre and Miquelon',
        'VC': 'Saint Vincent and the Grenadines',
        'WS': 'Samoa',
        'SM': 'San Marino',
        'ST': 'Sao Tome and Principe',
        'SA': 'Saudi Arabia',
        'SN': 'Senegal',
        'RS': 'Serbia',
        'SC': 'Seychelles',
        'SL': 'Sierra Leone',
        'SG': 'Singapore',
        'SX': 'Sint Maarten (Dutch part)',
        'SK': 'Slovakia',
        'SI': 'Slovenia',
        'SB': 'Solomon Islands',
        'SO': 'Somalia',
        'ZA': 'South Africa',
        'GS': 'South Georgia and the South Sandwich Islands',
        'SS': 'South Sudan',
        'ES': 'Spain',
        'LK': 'Sri Lanka',
        'SD': 'Sudan',
        'SR': 'Suriname',
        'SJ': 'Svalbard and Jan Mayen',
        'SZ': 'Swaziland',
        'SE': 'Sweden',
        'CH': 'Switzerland',
        'SY': 'Syrian Arab Republic',
        'TW': 'Taiwan, Republic of China',
        'TJ': 'Tajikistan',
        'TZ': 'Tanzania, United Republic of',
        'TH': 'Thailand',
        'TL': 'Timor-Leste',
        'TG': 'Togo',
        'TK': 'Tokelau',
        'TO': 'Tonga',
        'TT': 'Trinidad and Tobago',
        'TN': 'Tunisia',
        'TR': 'Turkey',
        'TM': 'Turkmenistan',
        'TC': 'Turks and Caicos Islands',
        'TV': 'Tuvalu',
        'UG': 'Uganda',
        'UA': 'Ukraine',
        'AE': 'United Arab Emirates',
        'GB': 'United Kingdom',
        'US': 'United States',
        'UM': 'United States Minor Outlying Islands',
        'UY': 'Uruguay',
        'UZ': 'Uzbekistan',
        'VU': 'Vanuatu',
        'VE': 'Venezuela, Bolivarian Republic of',
        'VN': 'Viet Nam',
        'VG': 'Virgin Islands, British',
        'VI': 'Virgin Islands, U.S.',
        'WF': 'Wallis and Futuna',
        'EH': 'Western Sahara',
        'YE': 'Yemen',
        'ZM': 'Zambia',
        'ZW': 'Zimbabwe'
    };

    zuoraCountriesISO = [
        {key: 'AT', value: 'Austria'},
        {key: 'BE', value: 'Belgium'},
        {key: 'BG', value: 'Bulgaria'},
        {key: 'HR', value: 'Croatia'},
        {key: 'CY', value: 'Cyprus'},
        {key: 'CZ', value: 'Czech Republic'},
        {key: 'DK', value: 'Denmark'},
        {key: 'EE', value: 'Estonia'},
        {key: 'FI', value: 'Finland'},
        {key: 'FR', value: 'France'},
        {key: 'DE', value: 'Germany'},
        {key: 'GR', value: 'Greece'},
        {key: 'HU', value: 'Hungary'},
        {key: 'IE', value: 'Ireland'},
        {key: 'IT', value: 'Italy'},
        {key: 'LV', value: 'Latvia'},
        {key: 'LT', value: 'Lithuania'},
        {key: 'LU', value: 'Luxembourg'},
        {key: 'MT', value: 'Malta'},
        {key: 'NL', value: 'Netherlands'},
        {key: 'PL', value: 'Poland'},
        {key: 'PT', value: 'Portugal'},
        {key: 'SK', value: 'Slovakia'},
        {key: 'SI', value: 'Slovenia'},
        {key: 'ES', value: 'Spain'},
        {key: 'SE', value: 'Sweden'}
    ];

    //  ------------------------- END Account -------------------------


    //  ------------------------- Optimizer -------------------------
    diskIssues = [
        'windowsTemp',
        'windowsUpdate',
        'windowsDangerous',
        'recycleBin',
        'windowsDumps',
        'logFiles',
        'werFiles'
    ];

    registryIssues = [
        'softwareLocations',
        'customControls',
        'sharedDlls',
        'helpFiles',
        'missingFonts',
        'startupItems',
        'runList',
        'deletedApplications'
    ];

    privacyIssues = [
        'chromeCache',
        'chromeCookies',
        'chromeHistory',
        'chromeTemp',
        'firefoxCache',
        'firefoxCookies',
        'firefoxHistory',
        'internetCache',
        'internetCookies',
        'internetHistory'
    ];

    //  ------------------------- END Optimizer -------------------------


    //  ------------------------- END Issues -------------------------




    //  ------------------------- Links -------------------------

    avMacSubscription = 'https://www.bitdefender.com/solutions/antivirus-for-mac.html';
    parentalSubscription = 'https://www.bitdefender.com/solutions/total-security.html';

    //  ------------------------- END Links -------------------------


    //  ------------------------- Issues -------------------------

    bms_issues_ids_title: {
        1: 'dashboard.issues.bms_run_fullscan.title',
        2: 'dashboard.issues.bms_malicious_apps.title'
    };

    bms_issues_ids: {
        1: 'dashboard.issues.bms_run_fullscan',
        2: 'dashboard.issues.bms_malicious_apps'
    };

    cl_issues_ids_title: {
        1: 'dashboard.issues.onaccess_scanning.title',
        4: 'dashboard.issues.update4.title',
        6: 'dashboard.issues.antispam.title',
        10: 'dashboard.issues.firewall.title',
        44: 'dashboard.issues.update4.title',
        100: 'dashboard.issues.antiphishing.title',
        103: 'dashboard.issues.antimalware.title',
        104: 'dasboard.issues.ssl.title',
        105: 'dashboard.issues.mac_unresolved_items.title',
        400: 'dashboard.issues.active_threat.title',
        500: 'dashboard.issues.dsa.title'
    };

    cl_issues_ids: {
        1: 'dashboard.issues.onaccess_scanning',
        4: 'dashboard.issues.update4',
        6: 'dashboard.issues.antispam',
        10: 'dashboard.issues.firewall',
        44: 'dashboard.issues.update44',
        100: 'dashboard.issues.antiphishing',
        103: 'dashboard.issues.antimalware',
        104: 'dasboard.issues.ssl',
        105: 'dashboard.issues.mac_unresolved_items',
        400: 'dashboard.issues.active_threat'
    };

    mac_issues_ids_title: {
        1: 'dashboard.issues.mac_autopilot.title',
        2: 'dashboard.issues.scan_all_files.title',
        4: 'dashboard.issues.mac_malware_signatures.title'
    };

    mac_issues_ids: {
        1: 'dashboard.issues.mac_autopilot',
        2: 'dashboard.issues.scan_all_files',
        4: 'dashboard.issues.mac_malware_signatures'
    };

    //  ------------------------- END Issues -------------------------


    //  ------------------------- Subscriptions -------------------------

    subscriptionID = {
        [this.bundleAV]: 111,
        [this.bundleIS]: 112,
        [this.bundleTS]: 113,
        [this.bundleBMS]: 114,
        [this.bundleAVFM]: 115,
        [this.bundlePA]: 116,
        [this.bundlePANCC]: 117
    };

    EXPIRED = "EXPIRED";

    //  ------------------------- END Subscriptions -------------------------


    fnac = 'cc6ba9cb-6abe-4fc5-88b6-14ff0c93e597';

    techassistPathImages = 'subscriptions/bundles/';

    techassistScheduleTimes = {
        romaniaAvailableFrom: 11,
        romaniaAvailableTo: 7,
        hoursInADay: 24,
        minutesDetails: {
            minutesDiference: 15,
            minutesDefaultStart: 0,
            minutesDefaultEnd: 45,
        },
    };

    techassistLangs = [
        this.languages.en_US.id,
        this.languages.en_AU.id,
        this.languages.en_GB.id,
        this.languages.de_DE.id,
        this.languages.fr_FR.id,
        this.languages.es_ES.id,
        this.languages.ro_RO.id
    ];

    techassistPhoneNumbers = {
        [this.languages.en_US.id]: ['+1 954 678 0567'],
        [this.languages.en_AU.id]: ['+61 280 155029', '+61 280 144689'],
        [this.languages.en_GB.id]: ['+44 2033 187 971', '+44 2080 997 504'],
        [this.languages.ro_RO.id]: ['+4 0374 303 006']
    };

    techassistProductsDetails = {
        [`${this.configService.config.partner_id}.setupmultiplatform`]: {
            id: 'setupmultiplatform',
            app_id: `${this.configService.config.partner_id}.setupmultiplatform`,
            name: 'Install & Setup Multi-Platform',
            langs: [
                this.languages.en_US.id,
                this.languages.en_AU.id,
                this.languages.en_GB.id,
                this.languages.de_DE.id,
                this.languages.fr_FR.id,
                this.languages.es_ES.id,
            ],
            buy: 'https://www.bitdefender.com/premium-services/install-setup-multiplatform.html',
            pic: `${this.configService.config.pathImages}${this.techassistPathImages}multi-platform.png`
        },
        [`${this.configService.config.partner_id}.virusremoval`]: {
            id: 'virusremoval',
            app_id: `${this.configService.config.partner_id}.virusremoval`,
            name: 'Virus & Spyware Removal',
            langs: [
                this.languages.en_US.id,
                this.languages.en_AU.id,
                this.languages.en_GB.id,
                this.languages.de_DE.id,
                this.languages.fr_FR.id
            ],
            buy: 'https://www.bitdefender.com/premium-services/virus-and-spyware-removal.html',
            pic: `${this.configService.config.pathImages}${this.techassistPathImages}virus-exterminator.png`
        },
        [`${this.configService.config.partner_id}.computertuneup`]: {
            id: 'computertuneup',
            app_id: `${this.configService.config.partner_id}.computertuneup`,
            name: 'Computer Tune-up',
            langs: [
                this.languages.en_US.id,
                this.languages.en_AU.id,
                this.languages.en_GB.id,
                this.languages.de_DE.id,
                this.languages.fr_FR.id,
                this.languages.es_ES.id,
                this.languages.ro_RO.id
            ],
            buy: 'https://www.bitdefender.com/premium-services/computer-tune-up.html',
            pic: `${this.configService.config.pathImages}${this.techassistPathImages}computer-tuneup.png`
        },
        [`${this.configService.config.partner_id}.smarthomesecurity`]: {
            id: 'smarthomesecurity',
            app_id: `${this.configService.config.partner_id}.smarthomesecurity`,
            name: 'Smart Home Security',
            langs: [
                this.languages.en_US.id,
                this.languages.en_AU.id,
                this.languages.en_GB.id,
                this.languages.ro_RO.id
            ],
            buy: 'https://www.bitdefender.com/premium-services/home-network-support.html',
            pic: `${this.configService.config.pathImages}${this.techassistPathImages}smart-home-service.png`
        },
        [`${this.configService.config.partner_id}.vipsupport`]: {
            id: 'vipsupport',
            app_id: `${this.configService.config.partner_id}.vipsupport`,
            name: 'VIP Support',
            langs: [
                this.languages.en_US.id,
                this.languages.en_AU.id,
                this.languages.en_GB.id,
                this.languages.ro_RO.id
            ],
            buy: 'https://www.bitdefender.com/premium-services/vip-support.html',
            pic: `${this.configService.config.pathImages}${this.techassistPathImages}vip-support.png`
        }
    };

    defaultCommercialId = {
        buyadditional_url: 'https://www.bitdefender.com/links/en/buy-additional-devices.html'
    };

    techassistsChatLinks = {
        [this.languages.en_US.id]: 'https://www.bitdefender.com/PremiumServices-Chat/',
        [this.languages.de_DE.id]: 'https://www.bitdefender.de/PremiumServices-Chat/'
    };

    parentalControl = {
        'app_id': this.configService.config.partner_id.concat('.parentalcontrol'),
        'device_id': 'all'
    };

    parentalAdvisor = {
        'app_id': this.appPA,
        'device_id': 'all'
    };

    notificationsMaxCounter = 999;

    experiment = {
        varA: 'A',
        varB: 'B',
        varC: 'C',
        varD: 'D'
    };

    mspLevelTwo = 2;

    compatibleProtectionApps = {
        [OperatingSystems.WINDOWS]: [
            this.appCL,
            this.appAVFREE
        ],
        [OperatingSystems.ANDROID]: [
            this.appBMS
        ],
        [OperatingSystems.OSX]: [
            this.appAVFM
        ],
        [OperatingSystems.MAC]: [
            this.appAVFM
        ],
        [OperatingSystems.IOS]: [
            this.bundleBIP,
            this.appBIS
        ]
    };

    notificationsKeys = {
        empty: "notification.empty.string",
        statusSafe: 'notifications.device.status.safe',
        mainLinkBtn: 'notifications.main.link.btn',
        mainLinkDescription: 'notifications.main.link.desc_two',
        allowWebsiteBtn: 'notifications.main.website.btn',
        webCategoriesTips: 'notifications.parental.button.manage.web.categories.tips',
        networkSafe: 'notifications.device.network.safe',
        allowConnectionBtn: 'notifications.main.link.allow_connection.btn',
        allowConnectionBtnDesc: 'notifications.main.link.allow_connection.btn.desc',
        viewExceptionBtn: 'notifications.main.link.exception.btn',
        dataSafe: 'notifications.device.data.safe',
        tipsTitle: 'notifications.tips.title',
        deviceName: 'notifications.main.deviceName',
        displayName: 'notifications.main.displayName',
        threatName: 'notification.threatName',
        descriptionTitle: 'notifications.main.desc_title'
    };

    notificationsBlogArticles = {
        parentalPersonalInfo: 'https://www.bitdefender.com/box/blog/family/digital-parenting-tricks-child-asked-personal-information-including-cc-ssn-user-name-passwords',
        parentalVerbalAggression: 'https://www.bitdefender.com/box/blog/family/digital-parenting-tricks-child-verbally-aggressive-online-one-one-group'
    };

    notificationsSkipDeviceVerification = new Set([
        NotificationsTypes.SUBSCRIPTIONS,
        NotificationsTypes.REFERRAL_REWARD_APPLIED,
        NotificationsTypes.PARENTAL,
        NotificationsTypes.NCC
    ]);

    notificationsThreatErrors = {
        "32701": "notifications.error.threat.info_deleted",
        "default": "notifications.error.threat.request_fail"
    };

    //  notification module with details regarding type & subtype
    // titlul notificarilor afisat este cel venit din connect
    notificationModule = {
        [NotificationsTypes.CL_EXPLOIT]: {
            [NotificationsSubtypes.CL_SYSTEM_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_system_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_system_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_system_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.ATTACK_SOURCE]: {
                        [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_system_exploit.tips.attack'
                    },
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_system_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_LOGIN_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_login_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_login_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_login_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_login_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_GENERIC_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_generic_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_generic_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_generic_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_generic_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_TRAVERSAL_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_traversal_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_traversal_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_traversal_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_traversal_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_SQL_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_sql_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_sql_exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_sql_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_sql_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_sql_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_CMD_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_cmd_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_cmd_exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_cmd_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_cmd_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_cmd_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_EXTENSION_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_extension_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_extension_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_extension_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.ATTACK_SOURCE]: {
                        [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_extension_exploit.tips.attack'
                    },
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_extension_exploit.tips'
                }
            },
            [NotificationsSubtypes.CL_CRITICAL_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_critical_exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_critical_exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_critical_exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.ATTACK_SOURCE]: {
                        [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_critical_exploit.tips.attack'
                    },
                    [EventDescriptors.TEXT]: 'notifications.cl_exploit.cl_critical_exploit.tips'
                }
            },
        },
        [NotificationsTypes.CL_ATTACK]: {
            [NotificationsSubtypes.CL_BRUTEFORCE_ATTACK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_bruteforce_attack.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_bruteforce_attack.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_bruteforce_attack.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_bruteforce_attack.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_bruteforce_attack.tips.attack'
                }
            },
            [NotificationsSubtypes.CL_LFI_ATTACK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_lfi_attack.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.general.exploit.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_lfi_attack.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_lfi_attack.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_attack.cl_lfi_attack.tips'
                }
            },
        },
        [NotificationsTypes.CL_PRIVACY]: {
            [NotificationsSubtypes.CL_LOGIN_PRIVACY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.tips'
                }
            },
            [NotificationsSubtypes.CL_DATA_PRIVACY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_data_privacy.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_data_privacy.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_data_privacy.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_data_privacy.tips'
                }
            },
            [NotificationsSubtypes.CL_BANKING_PRIVACY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_banking_privacy.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_banking_privacy.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_banking_privacy.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_banking_privacy.tips'
                }
            },
            [NotificationsSubtypes.CL_PASSWORD_PRIVACY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_password_privacy.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_login_privacy.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_password_privacy.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_password_privacy.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_privacy.cl_password_privacy.tips'
                }
            },
        },
        [NotificationsTypes.CL_TROJAN]: {
            [NotificationsSubtypes.CL_GENERIC_TROJAN]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_trojan.cl_generic_trojan.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_trojan.cl_generic_trojan.desc_threat'
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_trojan.cl_generic_trojan.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_trojan.cl_generic_trojan.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_trojan.cl_generic_trojan.tips'
                }
            },
        },
        [NotificationsTypes.CL_BOT]: {
            [NotificationsSubtypes.CL_GENERIC_BOT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_bot.cl_generic_bot.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_bot.cl_generic_bot.desc_threat'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_bot.cl_generic_bot.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_bot.cl_generic_bot.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.cl_bot.cl_generic_bot.tips'
                }
            },
        },
        [NotificationsTypes.ANTITHEFT]: {
            [NotificationsSubtypes.DEVICE_LOCATE_FAILED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_locate_failed.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_locate_failed.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_locate_failed.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_COMMANDS
                }
            },
            [NotificationsSubtypes.DEVICE_LOCATED_NO_ADDRESS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_located_no_address.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_located_no_address.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_located_no_address.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_MAP
                }
            },
            [NotificationsSubtypes.DEVICE_LOCATED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_located.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_located.desc',
                    [OperatingSystems.ANDROID]: 'notifications.antitheft.device_located.desc.android'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.device_located_no_address.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_MAP
                }
            },
            [NotificationsSubtypes.WIPE_SUCCESS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.wipe_success.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.wipe_success.desc',
                    [OperatingSystems.ANDROID]: 'notifications.antitheft.wipe_success.desc.android',
                    [OperatingSystems.IOS]: 'notifications.antitheft.wipe_success.desc.android',
                }
            },
            [NotificationsSubtypes.WIPE_FAILED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.wipe_failed.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.wipe_failed.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.wipe_failed.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_WIPE
                }
            },
            [NotificationsSubtypes.UNLOCK_APP_ATTEMPT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.unlock_app_attempt.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.unlock_app_attempt.desc_new'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.unlock_app_attempt.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_SNAP
                }
            },
            [NotificationsSubtypes.UNLOCK_DEVICE_ATTEMPT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.unlock_device_attempt.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.unlock_device_attempt.desc_new'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.unlock_device_attempt.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_SNAP
                }
            },
            [NotificationsSubtypes.LOCK_SUCCESS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.lock_success.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.lock_success.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.lock_success.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_LOCK
                }
            },
            [NotificationsSubtypes.LOCK_FAILED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.lock_failed.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.lock_failed.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.antitheft.lock_failed.cta',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ANTITHEFT_LOCK
                }
            },
        },
        [NotificationsTypes.WEB_ALERT]: {
            [NotificationsSubtypes.WEB_ALERT_EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.web_alert.web_alert_exploit.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.web_alert.web_alert_exploit.desc'
                }
            }
        },
        [NotificationsTypes.MALICIOUS_HOST]: {
            [NotificationsTypes.MALICIOUS_HOST]: {
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_host.malicious_host.desc'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowWebsiteBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.DOMAIN]: {
                        [EventDescriptors.TEXT]: "notifications.malicious_host.malicious_host.tips.website"
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
            },
            [NotificationsSubtypes.MALICIOUS_HOST_BMS]: {
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.CONDITION]: EventConditions.STATUS,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    malware: 'notifications.general.desc_threat.malware',
                    miner: 'notifications.general.desc_threat.miner',
                    phishing: 'notifications.general.desc_threat.phishing',
                    fraud: 'notifications.general.desc_threat.fraud',
                    untrusted: 'notifications.general.desc_threat.untrusted',
                    pua: 'notifications.general.desc_threat.pua',
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.CONDITION]: EventConditions.STATUS,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    malware: 'notifications.malicious_host.malicious_host.desc.malware',
                    miner: 'notifications.malicious_host.malicious_host.desc.miner',
                    phishing: 'notifications.malicious_host.malicious_host.desc.phishing',
                    fraud: 'notifications.malicious_host.malicious_host.desc.fraud',
                    untrusted: 'notifications.malicious_host.malicious_host.desc.untrusted',
                    pua: 'notifications.malicious_host.malicious_host.desc.pua',
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.DOMAIN]: {
                        [EventDescriptors.TEXT]: "notifications.malicious_host.malicious_host.tips.domain"
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
            }
        },
        [NotificationsTypes.DANGEROUS_URL]: {
            [NotificationsSubtypes.DANGEROUS_URL]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.CONDITION]: EventConditions.THREAT_TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    spam: 'notifications.dangerous_url.dangerous_url.title.spam',
                    malware: 'notifications.dangerous_url.dangerous_url.title.malware',
                    miner: 'notifications.dangerous_url.dangerous_url.title.miner',
                    phishing: 'notifications.dangerous_url.dangerous_url.title.phishing',
                    fraud: 'notifications.dangerous_url.dangerous_url.title.fraud',
                    untrusted: 'notifications.dangerous_url.dangerous_url.title.untrusted',
                    infected: 'notifications.dangerous_url.dangerous_url.title.infected',
                    exploit: 'notifications.dangerous_url.dangerous_url.title.exploit',
                    pua: 'notifications.dangerous_url.dangerous_url.title.pua'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.CONDITION]: EventConditions.THREAT_TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    spam: 'notifications.dangerous_url.dangerous_url.desc.spam',
                    malware: 'notifications.dangerous_url.dangerous_url.desc.malware',
                    miner: 'notifications.dangerous_url.dangerous_url.desc.miner',
                    phishing: 'notifications.dangerous_url.dangerous_url.desc.phishing',
                    fraud: 'notifications.dangerous_url.dangerous_url.desc.fraud',
                    untrusted: 'notifications.dangerous_url.dangerous_url.desc.untrusted',
                    infected: 'notifications.dangerous_url.dangerous_url.desc.infected',
                    exploit: 'notifications.dangerous_url.dangerous_url.desc.exploit',
                    pua: 'notifications.dangerous_url.dangerous_url.desc.pua'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.ACCESSED_FROM]: {
                        [EventDescriptors.TEXT]: "notifications.dangerous_url.dangerous_url.tips.blocked_url.clean_url.accessed_from"
                    },
                    [EventDescriptors.BLOCKED_URL]: {
                        [EventDescriptors.CLEAN_URL]: {
                            [EventDescriptors.TEXT]: "notifications.dangerous_url.dangerous_url.tips.blocked_url.clean_url"
                        },
                        [EventDescriptors.TEXT]: "notifications.dangerous_url.dangerous_url.tips.blocked_url"
                    },
                    [EventDescriptors.CLEAN_URL]: {
                        [EventDescriptors.TEXT]: "notifications.dangerous_url.dangerous_url.tips.clean_url"
                    },
                    [EventDescriptors.TEXT]: "notifications.dangerous_url.dangerous_url.tips"
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.mainLinkBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            }
        },
        [NotificationsTypes.BOXSE_DHCP_NOTIFICATION]: {
            [NotificationsSubtypes.BOXSE_DHCP_NOTIFICATION_VENDOR]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_dhcp_notifcation.boxse_dhcp_notifcation_vendor.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: 'notifications.device.data.attention',
                    [EventDescriptors.ICON]: DeviceStatusIcons.TWO
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: 'notifications.boxse_dhcp_notifcation.boxse_dhcp_notifcation_vendor.desc.device'
                    },
                    [EventDescriptors.TEXT]: 'notifications.boxse_dhcp_notifcation.boxse_dhcp_notifcation_vendor.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_dhcp_notifcation.boxse_dhcp_notifcation_vendor.tips'
                }
            }
        },
        [NotificationsTypes.NETWORK_UPDATE]: {
            [NotificationsSubtypes.WIFI_RESET]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_update.wifi_reset.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_update.wifi_reset.desc'
                }
            }
        },
        [NotificationsTypes.BOXSE_IP_ALLOC_ERROR]: {
            [NotificationsSubtypes.BOXSE_IP_ALLOC_ERROR]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_ip_alloc_error.boxse_ip_alloc_error.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: 'notifications.device.data.attention',
                    [EventDescriptors.ICON]: DeviceStatusIcons.TWO
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: 'notifications.boxse_ip_alloc_error.boxse_ip_alloc_error.desc.device'
                    },
                    [EventDescriptors.TEXT]: 'notifications.boxse_ip_alloc_error.boxse_ip_alloc_error.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_port_fwd_error.boxse_port_fwd_error.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.DEVICE_VIEW,
                    [EventDescriptors.DESC]: this.notificationsKeys.webCategoriesTips
                }
            }
        },
        [NotificationsTypes.BOXSE_PORT_FWD_ERROR]: {
            [NotificationsSubtypes.BOXSE_PORT_FWD_ERROR]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_port_fwd_error.boxse_port_fwd_error.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: 'notifications.device.data.attention',
                    [EventDescriptors.ICON]: DeviceStatusIcons.TWO
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_port_fwd_error.boxse_port_fwd_error.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_port_fwd_error.boxse_port_fwd_error.tips'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_port_fwd_error.boxse_port_fwd_error.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.DEVICE_VIEW,
                    [EventDescriptors.DESC]: this.notificationsKeys.webCategoriesTips
                }
            }
        },
        [NotificationsTypes.BOTNET]: {
            [NotificationsSubtypes.BOTNET]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.botnet.botnet.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.networkSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.botnet.botnet.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.botnet.botnet.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.botnet.botnet.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            }
        },
        [NotificationsTypes.NETWORK_ANOMALY]: {
            [NotificationsSubtypes.NETWORK_ANOMALY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_anomaly.network_anomaly.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.networkSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_anomaly.network_anomaly.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_anomaly.network_anomaly.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_anomaly.network_anomaly.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            }
        },
        [NotificationsTypes.CERTIFICATE]: {
            [NotificationsSubtypes.CERTIFICATE_WRONG_HOST]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_wrong_host.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_wrong_host.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_wrong_host.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_wrong_host.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.CIPHER_SUITE_INSECURE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.cipher_suite_insecure.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.cipher_suite_insecure.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.cipher_suite_insecure.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.cipher_suite_insecure.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.KEY_EXCHANGE_INSECURE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.key_exchange_insecure.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.key_exchange_insecure.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.key_exchange_insecure.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.key_exchange_insecure.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.UNSAFE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.unsafe.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.unsafe.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.unsafe.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.unsafe.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.CERTIFICATE_EXPIRED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_expired.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_expired.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_expired.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.certificate_expired.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.UNTRUSTED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.unsafe.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: "notifications.general.desc_threat.untrusted_certificate"
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.untrusted.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.untrusted.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.untrusted.tips'
                }
            },
            [NotificationsSubtypes.WRONG_HOST]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.wrong_host.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: "notifications.general.desc_threat.wrong_host"
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.wrong_host.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.wrong_host.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.wrong_host.tips'
                }
            },
            [NotificationsSubtypes.SELF_SIGNED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.self_signed.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: "notifications.general.desc_threat.self_signed"
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.self_signed.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.self_signed.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.self_signed.tips'
                }
            },
            [NotificationsSubtypes.EXPIRED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.expired.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: "notifications.general.desc_threat.expired"
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.expired.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.expired.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.expired.tips'
                }
            },
            [NotificationsSubtypes.ERROR]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.error.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: "notifications.general.desc_threat.error"
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.error.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.error.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.error.tips'
                }
            },
            [NotificationsSubtypes.REVOKED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.revoked.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TEXT]: "notifications.general.desc_threat.revoked"
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.revoked.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.revoked.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.certificate.revoked.tips'
                }
            },
        },
        [NotificationsTypes.MALWARE]: {
            [NotificationsSubtypes.TROJAN]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malware.trojan.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malware.trojan.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.malware.trojan.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.METASPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malware.metasploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malware.metasploit.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.malware.metasploit.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            }
        },
        [NotificationsTypes.NETWORK_ATTACK]: {
            [NotificationsSubtypes.LFI_ATTACK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.lfi_attack.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.lfi_attack.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.lfi_attack.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.EXPLOIT_ATTACK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.exploit_attack.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.exploit_attack.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.exploit_attack.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.AUTH_BYPASS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.auth_bypass.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.auth_bypass.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.auth_bypass.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.DOS_OUTGOING]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_outgoing.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_outgoing.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_outgoing.tips'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_outgoing.scan.device',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.SCAN_DEVICE,
                    [EventDescriptors.DESC]: 'notifications.network_attack.dos_outgoing.scan.device.desc'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.DOS_INCOMING]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_incoming.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_incoming.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.dos_incoming.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.IP_REPUTATION_OUTGOING]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.ip_reputation_outgoing.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.ip_reputation_outgoing.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.ip_reputation_outgoing.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.IP_REPUTATION_INCOMING]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.ip_reputation_incoming.title'
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.ip_reputation_incoming.desc'
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.ip_reputation_incoming.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.BRUTE_FORCE_ATTACK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.brute_force_attack.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.brute_force_attack.desc'
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.PORT]: {
                        [EventDescriptors.PROTOCOL]: {
                            [EventDescriptors.TEXT]: 'notifications.network_attack.brute_force_attack.tips.port.protocol'
                        },
                        [EventDescriptors.TEXT]: 'notifications.network_attack.brute_force_attack.tips.port'
                    },
                    [EventDescriptors.PROTOCOL]: {
                        [EventDescriptors.TEXT]: 'notifications.network_attack.brute_force_attack.tips.protocol'
                    },
                    [EventDescriptors.TEXT]: 'notifications.network_attack.brute_force_attack.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.SCANNING_ATTACK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.scanning_attack.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.scanning_attack.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.network_attack.scanning_attack.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.allowConnectionBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            }
        },
        [NotificationsTypes.PRIVACY_THREAT]: {
            [NotificationsSubtypes.AUTH_PRIVACY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.auth_privacy.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.dataSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.auth_privacy.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.auth_privacy.tips_title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.auth_privacy.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.mainLinkBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            },
            [NotificationsSubtypes.BANKING_PRIVACY]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.banking_privacy.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.dataSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_DEVICE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.deviceName
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: this.notificationsKeys.displayName
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.banking_privacy.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.banking_privacy.tips_title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.privacy_threat.banking_privacy.tips'
                },
                [EventDescriptors.MAIN_LINK]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.mainLinkBtn,
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.ALLOW_ACCESS_PAGE,
                    [EventDescriptors.DESC]: this.notificationsKeys.mainLinkDescription
                }
            }
        },
        [NotificationsTypes.BOXSE_UPDATE]: {
            [NotificationsSubtypes.APPLICATION_UPDATED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.application_updated.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.application_updated.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.tips_title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.tips'
                }
            },
            [NotificationsSubtypes.NEW_UPDATE_AVAILABLE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.tips_title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.latest_version.tip'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_update.new_update_available.button.update',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.UPDATE_BOX
                }
            }
        },
        [NotificationsTypes.BOX_UPDATE]: {
            [NotificationsSubtypes.NEW_UPDATE_AVAILABLE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update.new_update_available.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update.new_update_available.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.tipsTitle
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update.new_update_available.tips'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update.new_update_available.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.UPDATE_BOX
                }
            }
        },
        [NotificationsTypes.BOX_UPDATE_FINISHED]: {
            [NotificationsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update_finished.default.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update_finished.default.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.box_update_finished.default.tips'
                }
            }
        },
        [NotificationsTypes.NEW_DEVICE]: {
            [NotificationsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.new_device.default.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.new_device.default.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.new_device.default.tips'
                }
            }
        },
        [NotificationsTypes.STATIC_IP_ALLOCATION_ERROR]: {
            [NotificationsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.static_ip_allocation_error.default.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.static_ip_allocation_error.default.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.static_ip_allocation_error.default.tips'
                }
            }
        },
        [NotificationsTypes.PORT_FWD_ERROR]: {
            [NotificationsSubtypes.DEFAULT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.port_fwd_error.default.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: 'notifications.device.data.attention',
                    [EventDescriptors.ICON]: DeviceStatusIcons.TWO
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.port_fwd_error.default.desc'
                }
            }
        },
        [NotificationsTypes.VULNERABILITY_REPORT]: {
            [NotificationsSubtypes.VA_SCAN_RESULT_SAFE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_result_safe.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.dataSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_result_safe.desc'
                }
            },
            [NotificationsSubtypes.VA_SCAN_STATUS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.CONDITION]: EventConditions.TARGETS,
                    [EventDescriptors.TYPE]: EventTypes.MULTIPLE_CONDITIONAL,
                    [EventDescriptors.NAME]: {
                        [EventDescriptors.VERSION]: {
                            [EventDescriptors.PORT]: {
                                [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.version.port.tips'
                            },
                            [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.version.tips'
                        },
                        [EventDescriptors.PORT]: {
                            [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.port.tips'
                        },
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.tips'
                    },
                    [EventDescriptors.VERSION]: {
                        [EventDescriptors.PORT]: {
                            [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.version.port.tips'
                        },
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.version.tips'
                    },
                    [EventDescriptors.PORT]: {
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.port.tips'
                    },
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.tips'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.VULNERABILITY_VIEW,
                    [EventDescriptors.DESC]: 'notifications.vulnerability_report.va_scan_status.main_button.desc'
                }
            },
            [NotificationsSubtypes.VA_SCAN_STATUS_SINGLE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.dataSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.CONDITION]: EventConditions.TARGETS,
                    [EventDescriptors.TYPE]: EventTypes.MULTIPLE_CONDITIONAL,
                    [EventDescriptors.NAME]: {
                        [EventDescriptors.VERSION]: {
                            [EventDescriptors.PORT]: {
                                [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.version.port.tips'
                            },
                            [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.version.tips'
                        },
                        [EventDescriptors.PORT]: {
                            [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.port.tips'
                        },
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.name.tips'
                    },
                    [EventDescriptors.VERSION]: {
                        [EventDescriptors.PORT]: {
                            [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.version.port.tips'
                        },
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.version.tips'
                    },
                    [EventDescriptors.PORT]: {
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.port.tips'
                    },
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.tips'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_scan_status_single.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.VULNERABILITY_VIEW,
                    [EventDescriptors.DESC]: 'notifications.vulnerability_report.va_scan_status_single.main_button.desc'
                }
            },
            [NotificationsSubtypes.VA_NETWORK_SCAN_SINGLE_RISK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_single_risk.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.networkSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_single_risk.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_single_risk.tips_title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.CONDITION]: EventConditions.TARGETS,
                    [EventDescriptors.TYPE]: EventTypes.MULTIPLE_CONDITIONAL,
                    [EventDescriptors.NAME]: {
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_single_risk.tips'
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_single_risk.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.VULNERABILITY_VIEW,
                    [EventDescriptors.DESC]: 'notifications.vulnerability_report.va_network_scan_single_risk.main_button.desc'
                }
            },
            [NotificationsSubtypes.VA_NETWORK_SCAN_MULTI_RISK]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_multi_risk.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.networkSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_multi_risk.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_multi_risk.tips_title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.CONDITION]: EventConditions.TARGETS,
                    [EventDescriptors.TYPE]: EventTypes.MULTIPLE_CONDITIONAL,
                    [EventDescriptors.NAME]: {
                        [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_multi_risk.tips'
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_multi_risk.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.VULNERABILITY_VIEW
                }
            },
            [NotificationsSubtypes.VA_NETWORK_SCAN_CLEAN]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_clean.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.networkSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.vulnerability_report.va_network_scan_clean.desc'
                }
            }
        },
        [NotificationsTypes.BOXSE_NEW_DEVICE]: {
            [NotificationsSubtypes.BOXSE_NEW_DEVICE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_new_device.boxse_new_device.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.DEVICE_NAME]: {
                        [EventDescriptors.TEXT]: "notifications.boxse_new_device.boxse_new_device.desc.title"
                    },
                    [EventDescriptors.DISPLAY_NAME]: {
                        [EventDescriptors.TEXT]: "notifications.boxse_new_device.boxse_new_device.desc.title.display_name"
                    },
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_new_device.boxse_new_device.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_new_device.boxse_new_device.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.DEVICE_VIEW
                },
                [EventDescriptors.SECONDARY_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.boxse_new_device.boxse_new_device.secondary_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.BLOCK_DEVICE
                }
            }
        },
        [NotificationsTypes.PARENTAL]: {
            [NotificationsSubtypes.DEVICE_ADMIN_DISABLED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: "notifications.parental.device_admin_disabled.title"
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: "notifications.parental.device_admin_disabled.desc"
                }
            },
            [NotificationsSubtypes.DEVICE_ADMIN_DISABLED_ATTEMPT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: "notifications.parental.device_admin_disabled_attempt.title"
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: "notifications.parental.device_admin_disabled_attempt.desc"
                }
            },
            [NotificationsSubtypes.BLOCKED_APP]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.blocked_app.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.blocked_app.title'
                }
            },
            [NotificationsSubtypes.APP_UNINSTALLED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.app_uninstalled.title' //  child_name is user_name in confluence table. why?
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.app_uninstalled.desc'
                }
            },
            [NotificationsSubtypes.ENTER_RESTRICTED_AREA]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.enter_restricted_area.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.enter_restricted_area.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.tipsTitle
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.enter_restricted_area.tips'
                }
            },
            [NotificationsSubtypes.SAFE_CHECKIN]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.safe_checkin.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.safe_checkin.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.tipsTitle
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.TEXT]: 'notifications.parental.safe_checkin.tips'
                }
            },
            [NotificationsSubtypes.NEED_MORE_TIME]: {
                [EventDescriptors.ICON]: EventIcons.SHIELD_UNPROTECTED
            }
        },
        [NotificationsTypes.NCC]: {
            [NotificationsSubtypes.NEED_MORE_TIME]: {
                [EventDescriptors.ICON]: EventIcons.SHIELD_UNPROTECTED
            }
        },
        [NotificationsTypes.MALICIOUS_URL]: {
            [NotificationsSubtypes.MALICIOUS_URL]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_url.malicious_url.title'
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.CONDITION]: EventConditions.THREAT_TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    malware: 'notifications.general.desc_threat.malware',
                    miner: 'notifications.general.desc_threat.miner',
                    phishing: 'notifications.general.desc_threat.phishing',
                    fraud: 'notifications.general.desc_threat.fraud',
                    untrusted: 'notifications.general.desc_threat.untrusted',
                    pua: 'notifications.general.desc_threat.pua',
                    certificate: 'notifications.general.desc_threat.certificate',
                    [EventDescriptors.TEXT]: this.notificationsKeys.empty
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.CONDITION]: EventConditions.THREAT_TYPE,
                    [EventDescriptors.TYPE]: EventTypes.CONDITIONAL,
                    malware: 'notifications.malicious_url.malicious_url.desc.malware',
                    miner: 'dashboard.threats.details.malicious_url.miner.desc',
                    phishing: 'notifications.malicious_url.malicious_url.desc.phishing',
                    fraud: 'notifications.malicious_url.malicious_url.desc.fraud',
                    untrusted: 'notifications.malicious_url.malicious_url.desc.untrusted',
                    pua: 'dashboard.threats.details.malicious_url.pua.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.VISITED_URL]: {
                        [EventDescriptors.ACCESSED_FROM]: {
                            [EventDescriptors.TEXT]: 'notifications.malicious_url.malicious_url.tips.visited.accessed'
                        },
                        [EventDescriptors.TEXT]: 'notifications.malicious_url.malicious_url.tips.visited'
                    },
                    [EventDescriptors.BLOCKED_URL]: {
                        [EventDescriptors.CLEAN_URL]: {
                            [EventDescriptors.TEXT]: 'notifications.malicious_url.malicious_url.tips.visited.blocked'
                        },
                        [EventDescriptors.TEXT]: 'notifications.malicious_url.malicious_url.tips.blocked'
                    },
                    [EventDescriptors.TEXT]: 'notifications.malicious_url.malicious_url.tips'
                }
            }
        },
        [NotificationsTypes.MALICIOUS_APP]: {
            [NotificationsSubtypes.MALICIOUS_APP]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_app.malicious_app.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TYPE]: EventTypes.GENERIC_THREAT,
                    [EventDescriptors.TEXT]: this.notificationsKeys.threatName
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_app.malicious_app.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.THREAT_NAME]: {
                        [EventDescriptors.BLOCKED_APP]: {
                            [EventDescriptors.TEXT]: 'notifications.malicious_app.malicious_app.tips.threat.blocked_app'
                        },
                        [EventDescriptors.FILE_NAME]: {
                            [EventDescriptors.TEXT]: 'notifications.malicious_app.malicious_app.tips.threat.file_name'
                        },
                        [EventDescriptors.TEXT]: 'notifications.malicious_app.malicious_app.tips.threat'
                    },
                    [EventDescriptors.TEXT]: 'notifications.malicious_app.malicious_app.tips.threat'
                }
            }
        },
        [NotificationsTypes.MALICIOUS_APPS]: {
            [NotificationsSubtypes.MALICIOUS_APPS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_apps.malicious_apps.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_apps.malicious_apps.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_apps.malicious_apps.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.CONDITION]: EventConditions.THREATS,
                    [EventDescriptors.TYPE]: EventTypes.MULTIPLE_CONDITIONAL,
                    [EventDescriptors.TEXT]: 'notifications.malicious_apps.threat.malicious_apps.tips'
                }
            },
        },
        [NotificationsTypes.MALICIOUS_FILE]: {
            [NotificationsSubtypes.MALICIOUS_FILE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_file.malicious_file.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TYPE]: EventTypes.GENERIC_THREAT,
                    [EventDescriptors.TEXT]: this.notificationsKeys.threatName
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_file.malicious_file.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.THREAT_NAME]: {
                        [EventDescriptors.BLOCKED_FILE]: {
                            [EventDescriptors.TEXT]: 'notifications.malicious_file.malicious_file.tips.threat_name.blocked_file'
                        },
                        [EventDescriptors.FILE_NAME]: {
                            [EventDescriptors.TEXT]: 'notifications.malicious_file.malicious_file.tips.threat_name.file_name'
                        },
                        [EventDescriptors.TEXT]: 'notifications.malicious_file.malicious_file.tips.threat_name'
                    },
                    [EventDescriptors.TEXT]: 'notifications.malicious_file.malicious_file.tips'
                }
            },
        },
        [NotificationsTypes.MALICIOUS_PROCESS]: {
            [NotificationsSubtypes.MALICIOUS_PROCESS]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_process.malicious_process.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TYPE]: EventTypes.GENERIC_THREAT,
                    [EventDescriptors.TEXT]: this.notificationsKeys.threatName
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_process.malicious_process.desc'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.BLOCKED_PROCESS]: {
                        [EventDescriptors.TEXT]: 'notifications.malicious_process.malicious_process.tips.blocked_process'
                    },
                    [EventDescriptors.TEXT]: 'notifications.malicious_process.malicious_process.tips'
                }
            }
        },
        [NotificationsTypes.MALICIOUS_PROCESSES]: {
            [NotificationsSubtypes.MALICIOUS_PROCESSES]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_processes.malicious_processes.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_processes.malicious_processes.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.malicious_processes.malicious_processes.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.CONDITION]: EventConditions.THREATS,
                    [EventDescriptors.TYPE]: EventTypes.MULTIPLE_CONDITIONAL,
                    [EventDescriptors.TEXT]: 'notifications.malicious_processes.malicious_processes.tips.blocked_process'
                }
            }
        },
        [NotificationsTypes.RANSOMWARE]: {
            [NotificationsSubtypes.RANSOMWARE]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.ransomware.ransomware.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TYPE]: EventTypes.GENERIC_THREAT,
                    [EventDescriptors.TEXT]: this.notificationsKeys.threatName
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.ransomware.ransomware.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.ransomware.ransomware.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.FILE_NAME]: {
                        [EventDescriptors.TEXT]: 'notifications.ransomware.ransomware.tips.blocked_app'
                    },
                    [EventDescriptors.TEXT]: 'notifications.ransomware.ransomware.tips'
                }
            },
        },
        [NotificationsTypes.EXPLOIT]: {
            [NotificationsSubtypes.EXPLOIT]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.exploit.exploit.title'
                },
                [EventDescriptors.DEVICE_STATUS]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.statusSafe,
                    [EventDescriptors.ICON]: DeviceStatusIcons.ONE
                },
                [EventDescriptors.DESC_THREAT]: {
                    [EventDescriptors.TYPE]: EventTypes.GENERIC_THREAT,
                    [EventDescriptors.TEXT]: this.notificationsKeys.threatName
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.exploit.exploit.desc'
                },
                [EventDescriptors.TIPS_TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.exploit.exploit.tips.title'
                },
                [EventDescriptors.TIPS]: {
                    [EventDescriptors.BLOCKED_APP]: {
                        [EventDescriptors.TEXT]: 'notifications.exploit.exploit.tips.blocked_app'
                    },
                    [EventDescriptors.TEXT]: 'notifications.exploit.exploit.tips'
                }
            },
        },
        [NotificationsTypes.SUBSCRIPTIONS] : {
            [NotificationsSubtypes.NEW_DEVICE_PROTECTED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.subscriptions.device_protected.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.subscriptions.new_device_protected.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.subscriptions.device_protected.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.INSTALL_PROTECTION,
                    [EventDescriptors.DESC]: 'notifications.subscriptions.new_device_protected.main_button.desc'
                }
            },
            [NotificationsSubtypes.SECONDARY_DEVICE_PROTECTED]: {
                [EventDescriptors.TITLE]: {
                    [EventDescriptors.TEXT]: 'notifications.subscriptions.device_protected.title'
                },
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.subscriptions.secondary_device_protected.desc'
                },
                [EventDescriptors.MAIN_BUTTON]: {
                    [EventDescriptors.TEXT]: 'notifications.subscriptions.device_protected.main_button',
                    [EventDescriptors.FUNCTION]: NotificationsFunctions.INSTALL_PROTECTION,
                    [EventDescriptors.DESC]: 'notifications.subscriptions.secondary_device_protected.main_button.desc'
                }
            },
        },
        [NotificationsTypes.REFERRAL_REWARD_APPLIED]: {
            [NotificationsSubtypes.GUEST_USER]: {
                [EventDescriptors.ICON]: EventIcons.TROPHY,
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT]: 'notifications.referral.desc.guest.congrats'
                },
            },
            [NotificationsSubtypes.SOURCE_USER]: {
                [EventDescriptors.ICON]: EventIcons.TROPHY,
                [EventDescriptors.DESC_TITLE]: {
                    [EventDescriptors.TEXT]: this.notificationsKeys.descriptionTitle
                },
                [EventDescriptors.DESC]: {
                    [EventDescriptors.TEXT_FIRST]: 'notifications.referral.desc.source.guest.first',
                    [EventDescriptors.TEXT_FIRST_ONE]: 'notifications.referral.desc.source.guest.first.one',
                    [EventDescriptors.TEXT_NEXT]: 'notifications.referral.desc.source.guest.next',
                    [EventDescriptors.TEXT_LAST]: 'notifications.referral.desc.source.guest.last',
                    [EventDescriptors.TEXT_CONGRATS]: 'notifications.referral.desc.source.congrats'
                }
            }
        },
        [NotificationsTypes.ISSUES]: {
            [NotificationsSubtypes.NEW_ISSUES]: {
                [EventDescriptors.ICON]: EventIcons.SHIELD_UNPROTECTED
            }
        }
    };

    paramsModalType = {
        install: 'install',
        redeem: 'redeem'
    };

    paramsModalService = {
        ADD_CODE: 'add_code',
        ADD_TRIAL: 'add_trial',
        ADD_INVITE: 'add_invite'
    };

    // Onboarding -------------------------------------------------------------------------------

    onboardingBenefitsIcons = {
        localProtection      : {type: 'feature', icon: 'local-protection'},
        phishing             : {type: 'threat', icon: 'banker'},
        firewall             : {type: 'feature', icon: 'firewall'},
        vpn                  : {type: 'feature', icon: 'globe-pin'},
        onlineProtection     : {type: 'feature', icon: 'online-protection'},
        completeProtection   : {type: 'feedback', icon: 'shield-check'},
        accountPrivacy       : {type: 'feature', icon: 'account-privacy'},
        androidProtection    : {type: 'feature', icon: 'android-protection'},
        dataAppControl       : {type: 'feature', icon: 'safe'},
        parentalControls     : {type: 'web', icon: 'kids'},
        premiunVpn           : {type: 'feature', icon: 'pin-unlimited'},
        prioritySupport      : {type: 'feature', icon: 'priority-support'},
        networkProtection    : {type: 'feature', icon: 'secure-wifi'},
        dataBreachProtection : {type: 'feature', icon: 'id-hook'},
        webcam               : {type: 'feature', icon: 'webcam-mic'},
        digitalIdentity      : {type: 'feature', icon: 'product-DIP'},
        safeOnlineMedia      : {type: 'feature', icon: 'video'},
        passManagerGenerator : {type: 'feature', icon: 'registry-keys'},
        passManagerAccess    : {type: 'menu', icon: 'devices'},
        passManagerAutofill  : {type: 'user-data', icon: 'password'},
        passManagerProduct   : {type: 'user-data', icon: 'net-worth'},
        shieldPassManager    : {type: 'feature', icon: 'product-Password-Manager'},
        shieldVpn            : {type: 'feature', icon: 'product-VPN'},
        shieldVpnFilled      : {type: 'generic', icon: 'shield-vpn-filled'},
        shieldIdtheft        : {type: 'feature', icon: 'product-IDTheft'},
        shieldIdtheftFilled  : {type: 'generic', icon: 'shield-idtheft-filled'}
    };

    onboardingBenefitsTitles = {
        localProtection      : 'onboarding.plan.benefits.protection.title',
        phishing             : 'onboarding.plan.benefits.phishing.title',
        firewall             : 'onboarding.plan.benefits.firewall.title',
        vpn                  : 'onboarding.plan.benefits.vpn.title',
        onlineProtection     : 'onboarding.plan.benefits.online.protection.title',
        accountPrivacy       : 'onboarding.plan.benefits.account.privacy.title',
        androidProtection    : 'onboarding.plan.benefits.android.protection.title',
        dataAppControl       : 'onboarding.plan.benefits.data.app.control.title',
        parentalControls     : 'onboarding.plan.benefits.parental.title',
        premiunVpn           : 'onboarding.plan.benefits.premiun.vpn.title',
        prioritySupport      : 'onboarding.plan.benefits.priority.support.title',
        networkProtection    : 'onboarding.plan.benefits.network.protection.title',
        dataBreachProtection : 'onboarding.plan.benefits.data.breach.protection.title',
        webcam               : 'onboarding.plan.benefits.webcam.title',
        digitalIdentity      : 'onboarding.plan.benefits.digital.identity.title',
        idTheftProtection    : 'onboarding.plan.benefits.idtheft.protection.title',
        passManagerGenerator : 'onboarding.plan.benefits.pm.generate.title',
        passManagerAccess    : 'onboarding.plan.benefits.pm.access.title',
        passManagerAutofill  : 'onboarding.plan.benefits.pm.autofill.title',
        passManagerProduct   : 'onboarding.plan.benefits.pm.product.title',
        security             : 'onboarding.plan.benefits.security.title',
        onlinePrivacy        : 'onboarding.plan.benefits.online.privacy.title'
    };

    onboardingBenefitsDescription = {
        vpnDesc                         : 'onboarding.plan.benefits.vpn.desc',
        essentialProtectionWinDesc      : 'onboarding.plan.benefits.essential.protection.win.desc',
        essentialProtectionDesc         : 'onboarding.plan.benefits.essential.protection.desc',
        preventPhishingDesc             : 'onboarding.plan.benefits.prevent.phishing.desc',
        bestSecurityDesc                : 'onboarding.plan.benefits.best.security.desc',
        protectYourDeviceDesc           : 'onboarding.plan.benefits.protect.device.desc',
        completeProtectionWinDesc       : 'onboarding.plan.benefits.complete.protection.win.desc',
        completeProtectionIosDesc       : 'onboarding.plan.benefits.complete.protection.ios.desc',
        verifyEmailDesc                 : 'onboarding.plan.benefits.verify.email.desc',
        essentialProtectionMacDesc      : 'onboarding.plan.benefits.essential.protection.mac.desc',
        bestProtectionAndroidDesc       : 'onboarding.plan.benefits.best.protection.android.desc',
        completeProtectionThreatsDesc   : 'onboarding.plan.benefits.complete.protection.threats.desc',
        keepSensitiveInfoDesc           : 'onboarding.plan.benefits.keep.sensitive.info.desc',
        completeProtectionDesc          : 'onboarding.plan.benefits.complete.protection.desc',
        parentalDesc                    : 'onboarding.plan.benefits.parental.desc',
        unlimitedTrafficDesc            : 'onboarding.plan.benefits.unlimited.traffic.desc',
        unlimitedTrafficDescMoreDevices : 'onboarding.plan.benefits.unlimited.traffic.desc.20',
        prioritySupportDesc             : 'onboarding.plan.benefits.priority.support.desc',
        firewallProtectsDesc            : 'onboarding.plan.benefits.firewall.protects.desc',
        secureInternetDesc              : 'onboarding.plan.benefits.secure.internet.desc',
        idTheftProtection               : 'onboarding.plan.benefits.idtheft.protection.desc',
        completeProtectionDeviceDesc    : 'onboarding.plan.benefits.complete.protection.device.desc',
        completeProtectionAnonymityDesc : 'onboarding.plan.benefits.complete.protection.anonymity.desc',
        safeOnlineMediaDesc             : 'onboarding.plan.benefits.safe.online.media.desc',
        preventDataBreachesDesc         : 'onboarding.plan.benefits.prevent.data.breaches.desc',
        dailyTrafficDesc                : 'onboarding.plan.benefits.daily.traffic.desc',
        webProtectionDesc               : 'onboarding.plan.benefits.web.protection.desc',
        protectOnlinePrivacyDesc        : 'onboarding.plan.benefits..protect.online.privacy.desc',
        passManagerGenerator            : 'onboarding.plan.benefits.pm.generate.desc',
        digitalIdentity                 : 'onboarding.plan.benefits.digital.identity.desc',
        passManagerAccess               : 'onboarding.plan.benefits.pm.access.desc',
        passManagerAutofill             : 'onboarding.plan.benefits.pm.autofill.desc',
        passManagerProduct              : 'onboarding.plan.benefits.pm.product.desc',
        securityDesc                    : 'onboarding.plan.benefits.security.desc',
        privateBrowsingDesc             : 'onboarding.plan.benefits.private.browsing.desc',
        defenseDesc                     : 'onboarding.plan.benefits.defense.desc',
        passManagerDesc                 : 'onboarding.plan.benefits.passManager.desc',
        unlimitedBrowsingDesc           : 'onboarding.plan.benefits.unlimited.browsning.desc',
        dipDesc                         : 'onboarding.plan.benefits.dip.desc',
        idTheftDesc                     : 'onboarding.plan.benefits.idtheft.desc',
        onlinePrivacyDesc               : 'onboarding.plan.benefits.online.privacy.desc'
    };

    onboardingBenefits = {
        [this.bundleAV]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.essentialProtectionWinDesc
            },
            {
                icon:  this.onboardingBenefitsIcons.phishing,
                title: this.onboardingBenefitsTitles.phishing,
                description: this.onboardingBenefitsDescription.preventPhishingDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundleAVNEW]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.security,
                description: this.onboardingBenefitsDescription.securityDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldVpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.privateBrowsingDesc
            }
        ],
        [this.bundleIS]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.bestSecurityDesc
            },
            {
                icon: this.onboardingBenefitsIcons.firewall,
                title: this.onboardingBenefitsTitles.firewall,
                description: this.onboardingBenefitsDescription.protectYourDeviceDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            },
        ],
        [this.bundleTS]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionWinDesc
            },
            {
                icon: this.onboardingBenefitsIcons.firewall,
                title: this.onboardingBenefitsTitles.firewall,
                description: this.onboardingBenefitsDescription.protectYourDeviceDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundleBIP]: [
            {
                icon: this.onboardingBenefitsIcons.onlineProtection,
                title: this.onboardingBenefitsTitles.onlineProtection,
                description: this.onboardingBenefitsDescription.completeProtectionIosDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            },
            {
                icon: this.onboardingBenefitsIcons.accountPrivacy,
                title: this.onboardingBenefitsTitles.accountPrivacy,
                description: this.onboardingBenefitsDescription.verifyEmailDesc
            }
        ],
        [this.bundleAVFM]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.essentialProtectionMacDesc
            },
            {
                icon:  this.onboardingBenefitsIcons.phishing,
                title: this.onboardingBenefitsTitles.phishing,
                description: this.onboardingBenefitsDescription.preventPhishingDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundleBMS]: [
            {
                icon: this.onboardingBenefitsIcons.androidProtection,
                title: this.onboardingBenefitsTitles.androidProtection,
                description: this.onboardingBenefitsDescription.bestProtectionAndroidDesc
            },
            {
                icon: this.onboardingBenefitsIcons.onlineProtection,
                title: this.onboardingBenefitsTitles.onlineProtection,
                description: this.onboardingBenefitsDescription.completeProtectionThreatsDesc
            },
            {
                icon: this.onboardingBenefitsIcons.dataAppControl,
                title: this.onboardingBenefitsTitles.dataAppControl,
                description: this.onboardingBenefitsDescription.keepSensitiveInfoDesc
            }
        ],
        [this.bundleFP]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.parentalControls,
                title: this.onboardingBenefitsTitles.parentalControls,
                description: this.onboardingBenefitsDescription.parentalDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundlePSec]:{
            [this.appPassManager]: [
                {
                    icon: this.onboardingBenefitsIcons.localProtection,
                    title: this.onboardingBenefitsTitles.localProtection,
                    description: this.onboardingBenefitsDescription.completeProtectionDesc
                },
                {
                    icon: this.onboardingBenefitsIcons.premiunVpn,
                    title: this.onboardingBenefitsTitles.premiunVpn,
                    description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
                },
                {
                    icon: this.onboardingBenefitsIcons.passManagerProduct,
                    title: this.onboardingBenefitsTitles.passManagerProduct,
                    description: this.onboardingBenefitsDescription.passManagerProduct
                },
            ],
            default: [
                {
                    icon: this.onboardingBenefitsIcons.localProtection,
                    title: this.onboardingBenefitsTitles.localProtection,
                    description: this.onboardingBenefitsDescription.completeProtectionDesc
                },
                {
                    icon: this.onboardingBenefitsIcons.premiunVpn,
                    title: this.onboardingBenefitsTitles.premiunVpn,
                    description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
                },
                {
                    icon: this.onboardingBenefitsIcons.prioritySupport,
                    title: this.onboardingBenefitsTitles.prioritySupport,
                    description: this.onboardingBenefitsDescription.prioritySupportDesc
                },
            ]
        },
        [this.bundlePSecNew]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.security,
                description: this.onboardingBenefitsDescription.defenseDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldPassManager,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldVpnFilled,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedBrowsingDesc
            },
        ],
        [this.bundlePSecPlus]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
            },
            {
                icon: this.onboardingBenefitsIcons.passManagerProduct,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerProduct
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.digitalIdentity,
                description: this.onboardingBenefitsDescription.digitalIdentity
            },
            {
                icon: this.onboardingBenefitsIcons.prioritySupport,
                title: this.onboardingBenefitsTitles.prioritySupport,
                description: this.onboardingBenefitsDescription.prioritySupportDesc
            }
        ],
        [this.bundleUSEUNEW]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.security,
                description: this.onboardingBenefitsDescription.defenseDesc
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.digitalIdentity,
                description: this.onboardingBenefitsDescription.dipDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldPassManager,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldVpnFilled,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedBrowsingDesc
            },
        ],
        [this.bundleSecIdentity]: [
            {
                icon: this.onboardingBenefitsIcons.passManagerProduct,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerProduct
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.idTheftProtection,
                description: this.onboardingBenefitsDescription.idTheftProtection
            }
        ],
        [this.bundleTSVPN]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
            },
            {
                icon: this.onboardingBenefitsIcons.prioritySupport,
                title: this.onboardingBenefitsTitles.prioritySupport,
                description: this.onboardingBenefitsDescription.prioritySupportDesc
            }
        ],
        [this.bundleTSMD]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.firewall,
                title: this.onboardingBenefitsTitles.firewall,
                description: this.onboardingBenefitsDescription.firewallProtectsDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            },
        ],
        [this.bundleTSMDNEW]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.security,
                description: this.onboardingBenefitsDescription.defenseDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldPassManager,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldVpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.privateBrowsingDesc
            },
        ],
        [this.bundleBOX2]: [
            {
                icon: this.onboardingBenefitsIcons.networkProtection,
                title: this.onboardingBenefitsTitles.networkProtection,
                description: this.onboardingBenefitsDescription.secureInternetDesc
            },
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDeviceDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            },
        ],
        [this.bundleVPN]: [
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
            },
            {
                icon: this.onboardingBenefitsIcons.onlineProtection,
                description: this.onboardingBenefitsDescription.completeProtectionAnonymityDesc
            },
            {
                icon: this.onboardingBenefitsIcons.safeOnlineMedia,
                description: this.onboardingBenefitsDescription.safeOnlineMediaDesc
            }
        ],
        [this.bundleSOHO]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.dataBreachProtection,
                title: this.onboardingBenefitsTitles.dataBreachProtection,
                description: this.onboardingBenefitsDescription.preventDataBreachesDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.dailyTrafficDesc
            }
        ],
        [this.bundleVPNSOHO]: [
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDescMoreDevices
            },
            {
                icon: this.onboardingBenefitsIcons.onlineProtection,
                description: this.onboardingBenefitsDescription.completeProtectionAnonymityDesc
            },
            {
                icon: this.onboardingBenefitsIcons.safeOnlineMedia,
                description: this.onboardingBenefitsDescription.safeOnlineMediaDesc
            }
        ],
        [this.bundleStartPlan]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.essentialProtectionDesc
            },
            {
                icon:  this.onboardingBenefitsIcons.phishing,
                title: this.onboardingBenefitsTitles.phishing,
                description: this.onboardingBenefitsDescription.preventPhishingDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundlePersonalPlan]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.webcam,
                title: this.onboardingBenefitsTitles.webcam,
                description: this.onboardingBenefitsDescription.webProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundleFamilyPlan]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.parentalControls,
                title: this.onboardingBenefitsTitles.parentalControls,
                description: this.onboardingBenefitsDescription.parentalDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.vpn,
                description: this.onboardingBenefitsDescription.vpnDesc
            }
        ],
        [this.bundleUltimatePlan]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.digitalIdentity,
                description: this.onboardingBenefitsDescription.protectOnlinePrivacyDesc
            },
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
            },
        ],
        [this.bundlePM]: [
            {
                icon: this.onboardingBenefitsIcons.passManagerGenerator,
                title: this.onboardingBenefitsTitles.passManagerGenerator,
                description: this.onboardingBenefitsDescription.passManagerGenerator
            },
            {
                icon: this.onboardingBenefitsIcons.passManagerAccess,
                title: this.onboardingBenefitsTitles.passManagerAccess,
                description: this.onboardingBenefitsDescription.passManagerAccess
            },
            {
                icon: this.onboardingBenefitsIcons.passManagerAutofill,
                title: this.onboardingBenefitsTitles.passManagerAutofill,
                description: this.onboardingBenefitsDescription.passManagerAutofill
            },
        ],
        [this.bundleUSUS]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
            },
            {
                icon: this.onboardingBenefitsIcons.passManagerProduct,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerProduct
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.idTheftProtection,
                description: this.onboardingBenefitsDescription.idTheftProtection
            }
        ],
        [this.bundleUSUSNEW]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.security,
                description: this.onboardingBenefitsDescription.defenseDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldIdtheft,
                title: this.onboardingBenefitsTitles.idTheftProtection,
                description: this.onboardingBenefitsDescription.idTheftDesc,
                hideForSecondary: true
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.digitalIdentity,
                description: this.onboardingBenefitsDescription.dipDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.onlinePrivacy,
                description: this.onboardingBenefitsDescription.onlinePrivacyDesc
            }
        ],
        [this.bundleUSPUS]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.localProtection,
                description: this.onboardingBenefitsDescription.completeProtectionDesc
            },
            {
                icon: this.onboardingBenefitsIcons.premiunVpn,
                title: this.onboardingBenefitsTitles.premiunVpn,
                description: this.onboardingBenefitsDescription.unlimitedTrafficDesc
            },
            {
                icon: this.onboardingBenefitsIcons.passManagerProduct,
                title: this.onboardingBenefitsTitles.passManagerProduct,
                description: this.onboardingBenefitsDescription.passManagerProduct
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.idTheftProtection,
                description: this.onboardingBenefitsDescription.idTheftProtection
            }
        ],
        [this.bundleUSPUSNEW]: [
            {
                icon: this.onboardingBenefitsIcons.localProtection,
                title: this.onboardingBenefitsTitles.security,
                description: this.onboardingBenefitsDescription.defenseDesc
            },
            {
                icon: this.onboardingBenefitsIcons.shieldIdtheftFilled,
                title: this.onboardingBenefitsTitles.idTheftProtection,
                description: this.onboardingBenefitsDescription.idTheftDesc,
                hideForSecondary: true
            },
            {
                icon: this.onboardingBenefitsIcons.digitalIdentity,
                title: this.onboardingBenefitsTitles.digitalIdentity,
                description: this.onboardingBenefitsDescription.dipDesc
            },
            {
                icon: this.onboardingBenefitsIcons.vpn,
                title: this.onboardingBenefitsTitles.onlinePrivacy,
                description: this.onboardingBenefitsDescription.onlinePrivacyDesc
            }
        ]
    };

    onboardingWelcomeDetailsDescription = {
        controlDigital            : 'onboarding.welcome.description.control.digital',
        installProtection         : 'onboarding.welcome.description.instal.protection',
        manageBox                 : 'onboarding.welcome.description.manage.box',
        setUpPrivacy              : 'onboarding.welcome.description.set-up.privacy',
        installVPN                : 'onboarding.welcome.description.install.vpn',
        monitorIdentity           : 'onboarding.welcome.description.monitor.identity',
        controlDigitalSmallOffice : 'onboarding.welcome.description.control.digital.small.office',
        controlSecurity           : 'onboarding.welcome.description.control.security',
    };

    // l-am pus in obiect, poate se doreste alt text pt anumite bundles
    onboardingButtonsName = {
        getStarted: 'onboarding.action.getStarted'
    };

    onboardingWelcomeDetails = {
        [this.bundleAV]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleAVNEW]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleIS]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleTS]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleBIP]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleAVFM]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleBMS]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleFP]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundlePSec]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundlePSecNew]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundlePSecPlus]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleUSEUNEW]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleUSUS]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleUSUSNEW]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleUSPUS]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleUSPUSNEW]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleTSVPN]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleTSMD]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleTSMDNEW]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleBOX2]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.manageBox,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleVPN]: {
            description       : this.onboardingWelcomeDetailsDescription.setUpPrivacy,
            secondDescription : this.onboardingWelcomeDetailsDescription.installVPN,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleDPI]: {
            description : this.onboardingWelcomeDetailsDescription.monitorIdentity,
            buttonName  : this.onboardingButtonsName.getStarted
        },
        [this.bundleSOHO]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigitalSmallOffice,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleVPNSOHO]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigitalSmallOffice,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleIS]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleStartPlan] : {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundlePersonalPlan]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleFamilyPlan]: {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        },
        [this.bundleUltimatePlan]: {
            description        : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription  : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName         : this.onboardingButtonsName.getStarted
        },
        [this.bundlePM]: {
            description        : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription  : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName         : this.onboardingButtonsName.getStarted
        },
        'default': {
            description       : this.onboardingWelcomeDetailsDescription.controlDigital,
            secondDescription : this.onboardingWelcomeDetailsDescription.installProtection,
            buttonName        : this.onboardingButtonsName.getStarted
        }
    };

    freeProtectionAppsArray = [
        this.appBIS,
        this.appAVFREE
    ];

    freeProtectionApps = new Set(this.freeProtectionAppsArray);

    paidProtectionAppsArray = [
        this.appCL,
        this.appAVFM,
        this.appBMS,
        this.appBIP
    ];

    vpnAppsArray = [
        this.appVPN,
    ];

    parentalAppsArray = [this.appPA, this.appPANCC];

    vpnApps = new Set(this.vpnAppsArray);

    singlePlatformBundles = new Set([
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleIS,
        this.bundleTS,
        this.bundleAVLITE,
        this.bundleAVFREE,
        this.bundleBIS,
        this.bundleBIP,
        this.bundleAVFM,
        this.bundleBMS
    ]);

    securityBundles = new Set([
        this.bundleAV,
        this.bundleAVNEW,
        this.bundleIS,
        this.bundleTS,
        this.bundleAVLITE,
        this.bundleAVFREE,
        this.bundleBIS,
        this.bundleBIP,
        this.bundleAVFM,
        this.bundleBMS,
        this.bundleFP,
        this.bundleFPAV,
        this.bundleFPIS,
        this.bundleFPP,
        this.bundlePSec,
        this.bundlePSecNew,
        this.bundlePSecPlus,
        this.bundleUSEUNEW,
        this.bundleISMD,
        this.bundleTSMD,
        this.bundleTSMDNEW,
        this.bundleBOX2,
        this.bundleBoxCl,
        this.bundleStartPlan,
        this.bundlePersonalPlan,
        this.bundleFamilyPlan,
        this.bundleUltimatePlan,
        this.bundlePersonalVpnPlan,
        this.bundleIOSBMS,
        this.bundleTSVPN,
        this.bundleSOHO,
        this.bundleVPNSOHO,
        this.bundleUSUS,
        this.bundleUSUSNEW,
        this.bundleUSPUS,
        this.bundleUSPUSNEW
    ]);

    singlePlatformOs = {
        [this.bundleAV]: 'Windows',
        [this.bundleAVNEW]: 'Windows',
        [this.bundleIS]: 'Windows',
        [this.bundleTS]: 'Windows',
        [this.bundleAVLITE]: 'Windows',
        [this.bundleAVFREE]: 'Windows',
        [this.bundleBIS]: 'iOS',
        [this.bundleBIP]: 'iOS',
        [this.bundleAVFM]: 'Mac',
        [this.bundleBMS]: 'Android'
    };

    upgradeToTotalSecurityBundles = new Set([
        this.bundleAVFM,
        this.bundleAVFREE,
        this.bundleBMS,
        this.bundleBIS,
        this.bundleBIP,
        this.bundleAV,
        this.bundleIS,
        this.bundleIOSBMS,
        this.bundleISMD
    ]);

    upgradeToTotalSecurityNewBundles = new Set([
        this.bundleAVNEW
    ]);

    upgradeToPremiumSecurityBundles = new Set([
        this.bundleTS,
        this.bundleFP,
        this.bundleFPAV,
        this.bundleFPIS,
        this.bundleFPP,
        this.bundlePM,
        this.bundleStartPlan,
        this.bundlePersonalPlan,
        this.bundleFamilyPlan,
        this.bundlePersonalVpnPlan,
        this.bundleTSVPN,
        this.bundleTSMD,
        this.bundleVPN
    ]);

    public readonly upgradeToPremiumSecurityNewBundles = new Set([
        this.bundleTSMDNEW
    ]);

    public readonly upgradeToUltimateSecurityNewBundles = new Set([
        this.bundlePSecNew
    ]);

    desktopApps = new Set ([
        this.appAVFM,
        this.appCL,
        this.appAVFREE
    ]);

    mobileApps = new Set ([
        this.appBMS,
        this.appBIS,
        this.appBIP
    ]);

    protectionAppsPriority = {
        [ProductsToInstall.PROTECTION]: this.compatibleProtectionApps,
        [ProductsToInstall.VPN]: this.vpnAppsArray,
        [ProductsToInstall.PARENTAL]: this.parentalAppsArray
    };

    appsIcons = {
        PROTECTION: ProductsToInstall.PROTECTION,
        VPN: ProductsToInstall.VPN,
        PARENTAL: ProductsToInstall.PARENTAL,
        PARENTALNCC: ProductsToInstall.PARENTALNCC
    };

    protectionApps = new Set([
        ...this.freeProtectionAppsArray, ...this.paidProtectionAppsArray
    ]);

    onboardingScenario = {
        AUTOMATIC_ACTIVATION_UNKNOWN     : 'automaticActivationUnknown',
        AUTOMATIC_ACTIVATION             : 'automaticActivation',
        AUTOMATIC_ACTIVATION_IDENTITY    : 'automaticActivationIdentity',
        AUTOMATIC_ACTIVATION_PASSMANAGER : 'automaticActivationPassManager',
        AUTOMATIC_ACTIVATION_INVITE      : 'activationInvite',
        ACTIVATION                       : 'activation',
        TRIAL                            : 'trial',
        EXPIRED                          : 'expired',
        JUST_ACTIVATION                  : 'justActivation',
        CONFIRM_ACTIVATION               : 'confirmActivation',
        TRIAL_TS                         : 'trialTS',
        PARENTAL                         : 'parental',
        PARENTALNCC                      : 'nccparental',
        AUTO_RENEWAL                     : 'autoRenewal',
        SECURED_PAYMENT                  : 'securedPayment',
        CONGRATULATIONS                  : 'congratulations',
        CONGRATULATIONS_ACTIVATE         : 'congratulations_activate',
        ERROR                            : 'error',
        ERROR_TRIAL                      : 'error_trial',
        ERROR_INVITE                     : 'error_invite'
    };

    identityProducts = new Set([
        this.bundleDPI,
        this.bundleIDTPP,
        this.bundleIDTPS,
        this.bundleSecIdentity
    ]);

    recommendationScenario = {
        FULL: 'full',
        SECURITY_UPGRADE: 'securityUpgrade',
        SECURITY_VPN: 'securityVpn',
        UPGRADE_VPN: 'upgradeVPN',
        SECURITY_PLAN: 'security',
        UPGRADE_PLAN: 'upgradePlan',
        VPN_PLAN: 'installVpn'
    };

    congratsFooterPremiumServicesLang= new Set([
        this.languages.de_DE.id,
        this.languages.es_ES.id,
        this.languages.fr_FR.id
    ]);

    userGuideLocalizeLang = new Set([
        this.languages.en_US.id,
        this.languages.de_DE.id,
        this.languages.es_ES.id,
        this.languages.fr_FR.id,
        this.languages.fr_CA.id,
        this.languages.it_IT.id,
        this.languages.nl_NL.id,
        this.languages.pt_PT.id,
        this.languages.pt_BR.id,
        this.languages.ro_RO.id,
        this.languages.sv_SE.id
    ]);

    omnitureDeploy = {
        chooseAProduct: 'choose-a-product',
        selectDevice: 'select-device',
        thisDevice: 'this-device',
        otherDevice: 'other-device',
        passwordManager: 'password-manager',
        createProfile: 'create-profile',
        setProfileAge: 'set-profile-age',
        selectProfile: 'select-profile',
        setParentalPin: 'set-parental-pin',
        pinCreated: 'pin-created',
        vpn: 'vpn',
        nccParental: 'nccparental',
        security: 'security'
    };

    omnitureNotificationsSections = {
        notifications: 'notifications'
    };

    omnitureOnboarding = {
        welcome         : 'welcome',
        redeem          : 'redeem',
        included        : 'included',
        safety          : 'safety',
        code            : 'code',
        activate        : 'activate',
        activated       : 'activated',
        extended        : 'extended',
        try             : 'try',
        congratulations : 'congratulations',
        expired         : 'expired',
        reactivate      : 'reactivate',
        trial           : 'trial',
        error           : 'error'
    };

    omnitureAutoRenewal = {
        autoRenewOffer: 'auto-renew-offer',
        enterBilling: 'enter-billing',
        billingError: 'billing-error'
    };

    omnitureSecuredPayment = {
        success: 'success',
        error: 'error'
    };

    mobileStore = {
        [OperatingSystems.ANDROID] : 'googlePlay',
        [OperatingSystems.IOS]     : 'appStore'
    };

    passwordManagerBrowsers = {
        'chrome'  : 'chrome',
        'safari'  : 'safari',
        'firefox' : 'firefox',
        'edge'    : 'edge',
        'ms-edge-chromium' : 'ms-edge-chromium'
    };

    passwordManagerExtensions = {
        [this.passwordManagerBrowsers['ms-edge-chromium']]: {
            title       : 'Microsoft Edge',
            extension   : 'Edge',
            id          : this.passwordManagerBrowsers['ms-edge-chromium'],
            img         : this.centralImages.edgeBrowser,
            url         : 'https://microsoftedge.microsoft.com/addons/detail/hjiaiapigbnlahicmcacnmhfckgjdgbl'
        },
        [this.passwordManagerBrowsers.chrome]: {
            title       : 'Google Chrome',
            extension   : 'Chrome',
            id          : this.passwordManagerBrowsers.chrome,
            img         : this.centralImages.chromeBrowser,
            url         : 'https://chrome.google.com/webstore/detail/bitdefender-password-mana/ibkoenhablealnikeefmjineccmgegmh'
        },
        [this.passwordManagerBrowsers.safari]: {
            title           : 'Safari',
            extension       : 'Safari',
            id              : this.passwordManagerBrowsers.safari,
            img             : this.centralImages.safariBrowser,
            showComingSoon  : true
        },
        [this.passwordManagerBrowsers.firefox]: {
            title       : 'Mozilla Firefox',
            extension   : 'Firefox',
            id          : this.passwordManagerBrowsers.firefox,
            img         : this.centralImages.firefoxBrowser,
            url         : 'https://download.bitdefender.com/passwordmanager/firefox/bdpassmanager.xpi'
        }
    };

    availableDevices = {
        default: { img: this.configService.config.pathImages.concat('devices/box.png') }
    }

    emptyStateActivationRedirectUrl = ""

    connectErrorIds = {
        DATABASE_ERROR: 32701,
        COMMUNICATION_ERROR: 32801,
        UNKNOWN_PLATFORM: 32005
    }

    requestStatuses = {
        ERROR_STATUS: 1,
        SUCCESS_STATUS: 0
    }

    notificationsPageCounter = 15;

    sideMenuServices = new Set([
        this.appSecurity,
        this.appVPN,
        this.appPA,
        this.appPANCC,
        this.appIdTheft,
        this.appDIP,
        this.appPassManager
    ]);
}

export interface IName {
    basic: string;
    premium: string;
    default: string;
}



export class IdentityModel {
    identity_id: string;
    status: number;
    date_created: Date;
    onboarding?: string;
    init_started: boolean;
    init_done: boolean;
    scan_info: ScanInfo;
    first_name: string;
    last_name: string;
    middle_name: string;
    emails: Metadata[];
    phones: Metadata[];
    sm: Metadata[];
    lang: string;
    validate_required: any[];
    required_metadata: any;
    feature_set: FeatureSetEnum[];
    allowed_metadata;
    migrated: boolean;
    parking_details?: ParkingDetailsModel;
    partner_id
}

export class ParkingDetailsModel {
    active: boolean;
    remove_at: Date;
}

export enum FeatureSetEnum {
    EXPOSURE = "exposure",
    BREACH = "breach",
    IMPERSONATION = "impersonation",
    DATABROKERS = "databrokers",
    EDUCATION = "education"
}

export class Metadata {
    validated?: boolean;
    value?: any;
    info_id?: string;
    type?: MetadataType;
    subtype?: MetadataPhoneSubtype;
    source?: MetadataSource;
    metadata_id?: string;
}

export class ScanInfo {
    status: ScanStatus;
    next_scan: Date;
    previous_scan: Date;
}

export enum ScanStatus {
    DONE = 'done',
    IN_PROGRESS = 'in_progress'
}

export enum MetadataSource {
    ONBOARDING = 'ONBOARDING',
    CONFIRMED = 'USER_CONFIRMATION',
    ADDED = 'USER_ADDED'
}

export enum MetadataPhoneType {
    MOBILE = 'phone',
    LANDLINE = 'landline'
}

export enum MetadataPhoneSubtype {
    MOBILE = 'mobile',
    LANDLINE = 'landline'
}

export enum MetadataType {
    NAMES = 'names',
    ADDRESSES = 'addresses',
    PHONES = 'phones',
    EMAILS = 'emails',
    USERNAMES = 'usernames',
    JOBS = 'jobs',
    EDUCATIONS = 'educations',
    IMAGES = 'images',
    GENDER = 'gender',
    USER_IDS = "user_ids",
    URLS = "urls",
    DOB = "dob",
    LANGUAGES = 'languages',
    REFERENCES = 'references'
}

export enum MetadataTypeSingular {
    NAMES = 'name',
    ADDRESSES = 'address',
    PHONES = 'phone',
    EMAILS = 'email',
    USERNAMES = 'username',
    JOBS = 'job',
    EDUCATIONS = 'education',
    IMAGES = 'image',
    GENDER = 'gender',
    USER_IDS = "user id",
    URLS = "url",
    DOB = "dob",
    LANGUAGES = 'language',
    REFERENCES = 'reference'
}

export enum MetaDataEmailTypes {
    GMAIL = 'gmail',
    OUTLOOK = 'outlook',
}

export enum MonitoringStateStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export interface IErrorText {
    translationId: string;
    props?: object
}
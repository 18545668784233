// External
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

// Internal
import { SubscriptionsService } from './subscriptions.service';
import { MessageService } from '../../core/message.service';
import { ValuesService } from '../../../../common/values/values.service';
import { ISubscriptionsService } from '../../interfaces/isubscriptions/isubscriptions.service';
import { SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';
import { UsefulService } from '../../global/useful/useful.service';
import { UtilsCommonService } from '../../../../common/utils/utils-common.service';
import { SubscriptionMgmtService } from '../../requests/connect-subscription-service/connect-subscription.service';
import { SubscriptionsHelperService } from '../../../../pages/subscriptions/subscriptions.helper.service';
import { ProfilesService } from '../profiles/profiles.service';
import { RedeemDataModel } from '../../../models/Services.model';

@Injectable({
    providedIn: 'root'
})

export class RedeemService {

    newDeviceIds = new Set([]);

    constructor(
        private readonly subscriptionsService: SubscriptionsService,
        private readonly isubscriptionsService: ISubscriptionsService,
        private readonly messageService: MessageService,
        private readonly valuesService: ValuesService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService,
        private readonly usefulService: UsefulService,
        private readonly utilsCommonService: UtilsCommonService,
        private readonly subscriptionMgmtService: SubscriptionMgmtService,
        private readonly subscriptionsHelperService: SubscriptionsHelperService,
        private readonly profilesService: ProfilesService
    ) { }

    hasActiveSohoSubscription(item) {
        return this.valuesService.sohoBundles.includes(item.bundle_id) && item.status === this.subscriptionsValuesService.status.active;
    }

    computeActivationHintSoho() {
        let hint = 'subscription.redeem.error.SOHO_INCOMPATIBLE';
        if (this.subscriptionsService.getAllServices().some(item => this.hasActiveSohoSubscription(item)) ) {
            hint = 'subscription.redeem.error.SOHO_INCOMPATIBLE_ACTIVE';
        }
        return hint;
    }

    handleRedeemResponse(reason) {
        const redeemResponse = {
            // Should make request again:
            [this.subscriptionsValuesService.redeemReasons.noOptions]: {
                valid: false,
                codeError: false,
                activationHint: 'onboarding.activation.no.option.selected',
                reason: this.subscriptionsValuesService.redeemReasons.noOptions
            },
            [this.subscriptionsValuesService.redeemReasons.empty]: {
                valid: false,
                codeError: true,
                activationHintErrorScreen: 'onboarding.error.empty.description',
                activationHint: 'subscription.redeem.error.EMPTY',
                reason: this.subscriptionsValuesService.redeemReasons.empty
            },
            [this.subscriptionsValuesService.redeemReasons.notSuitableForMigration]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.NOT_SUITABLE_FOR_MIGRATION',
                reason: this.subscriptionsValuesService.redeemReasons.notSuitableForMigration
            },
            [this.subscriptionsValuesService.redeemReasons.unexpectedError]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.UNEXPECTED_ERROR',
                reason: this.subscriptionsValuesService.redeemReasons.unexpectedError
            },
            [this.subscriptionsValuesService.redeemReasons.malformedSubscriptionRequest]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.MALFORMED_SUBSCRIPTION_REQUEST',
                reason: this.subscriptionsValuesService.redeemReasons.malformedSubscriptionRequest
            },
            [this.subscriptionsValuesService.redeemReasons.subscriptionDBError]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.SUBSCRIPTION_DB_ERROR',
                reason: this.subscriptionsValuesService.redeemReasons.subscriptionDBError
            },
            [this.subscriptionsValuesService.redeemReasons.systemError]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.SYSTEM_ERROR',
                reason: this.subscriptionsValuesService.redeemReasons.systemError
            },
            [this.subscriptionsValuesService.redeemReasons.unknownLicenseStatus]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.UNKNOWN_LICENSE_STATUS',
                reason: this.subscriptionsValuesService.redeemReasons.unknownLicenseStatus
            },
            [this.subscriptionsValuesService.redeemReasons.forbidenMSPRedeem]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.FORBIDDEN_MSP_REDEEM',
                reason: this.subscriptionsValuesService.redeemReasons.forbidenMSPRedeem
            },
            [this.subscriptionsValuesService.redeemReasons.partenerRedeemMatchFailed]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.PARTNER_REDEEM_MATCH_FAILED',
                reason: this.subscriptionsValuesService.redeemReasons.partenerRedeemMatchFailed
            },
            [this.subscriptionsValuesService.redeemReasons.nfrExtensionsLimitation]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.NFR_EXTENSION_LIMITATION',
                reason: this.subscriptionsValuesService.redeemReasons.nfrExtensionsLimitation
            },
            [this.subscriptionsValuesService.redeemReasons.someUnknownError]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.SOME_UNKNOWN_ERROR',
                reason: this.subscriptionsValuesService.redeemReasons.someUnknownError
            },
            // Shouldn't make request again:
            [this.subscriptionsValuesService.redeemReasons.geolocationLimitation]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.GEOLOCATION_LIMITATION',
                reason: this.subscriptionsValuesService.redeemReasons.geolocationLimitation
            },
            [this.subscriptionsValuesService.redeemReasons.expired]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.EXPIRED',
                reason: this.subscriptionsValuesService.redeemReasons.expired
            },
            [this.subscriptionsValuesService.redeemReasons.handlerError]: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.error.undefined',
                reason: this.subscriptionsValuesService.redeemReasons.handlerError
            },
            [this.subscriptionsValuesService.redeemReasons.nfrActivationLimitation]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.NFR_ACTIVATION_LIMITATION',
                reason: this.subscriptionsValuesService.redeemReasons.nfrActivationLimitation
            },
            [this.subscriptionsValuesService.redeemReasons.eolProductDisabled]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.EOL_PRODUCT_DISABLED',
                reason: this.subscriptionsValuesService.redeemReasons.eolProductDisabled
            },
            [this.subscriptionsValuesService.redeemReasons.sohoIncompatible]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.SOHO_INCOMPATIBLE',
                action: () => this.computeActivationHintSoho(),
                reason: this.subscriptionsValuesService.redeemReasons.sohoIncompatible
            },
            [this.subscriptionsValuesService.redeemReasons.consumerIncompatible]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.CONSUMER_INCOMPATIBLE',
                reason: this.subscriptionsValuesService.redeemReasons.consumerIncompatible
            },
            [this.subscriptionsValuesService.redeemReasons.levelTwo]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.LEVEL_TWO',
                reason: this.subscriptionsValuesService.redeemReasons.levelTwo
            },
            // More frequent cases:
            [this.subscriptionsValuesService.redeemReasons.alreadyUsed]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.ALREADY_USED',
                reason: this.subscriptionsValuesService.redeemReasons.alreadyUsed
            },
            [this.subscriptionsValuesService.redeemReasons.alreadyUsedSameUser]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.ALREADY_USED_SAME_USER',
                reason: this.subscriptionsValuesService.redeemReasons.alreadyUsedSameUser
            },
            [this.subscriptionsValuesService.redeemReasons.invalid]: {
                valid: false,
                codeError: true,
                activationHint: 'subscription.redeem.error.INVALID',
                reason: this.subscriptionsValuesService.redeemReasons.invalid
            },
            [this.subscriptionsValuesService.redeemReasons.valid]: {
                valid: true,
                codeError: false,
                makeDryRunTrueRequest: true,
                reason: this.subscriptionsValuesService.redeemReasons.valid
            },
            default: {
                valid: false,
                codeError: false,
                activationHint: 'subscription.redeem.modal.problem',
                reason: this.subscriptionsValuesService.redeemReasons.unexpectedError
            }
        };

        const reasonInfo = redeemResponse[reason];
        if (reasonInfo) {
            if (reasonInfo.hasOwnProperty('action')) {
                reasonInfo.activationHint = reasonInfo.action();
            }
            return reasonInfo;
        }

        return redeemResponse.default;
    }

    checkRedeemCode(redeemCode): Observable<any> {
        const params = {
            code   : redeemCode,
            dry_run: true
        };

        return this.subscriptionMgmtService.redeem(params)
        .pipe(
            map(
                resp => {
                    const reason = resp?.reason;
                    let bundle = resp?.data;
                    let bundleId = resp?.data?.bundle_id;

                    if (!bundle?.server_time) {
                        bundle.server_time = resp?.server_time ?? Math.floor(new Date().getTime()/1000);
                    }

                    if (!this.profilesService.ownerHasSubsV4()) {
                        bundle = resp?.subscriptions;
                        if (bundle) {
                            bundle = this.subscriptionsService.v3tov4(bundle)[0];
                            bundleId = bundle?.bundle_id;
                            resp = { ...resp, data: bundle };
                        }
                    }
                    if (reason === this.subscriptionsValuesService.redeemReasons.valid
                        && bundleId
                        && !this.utilsCommonService.isEmptyObject(bundle)) {
                        return {...resp, ...this.addRedeemInfo(bundle)};
                    }
                    return resp;
                }
            ),
            catchError(
                err => {
                    throw err;
                }
            )
        );
    }

    activateRedeemCode(redeemCode, serviceId?): Observable<any> {
        const configObject = {
            code: redeemCode,
            dry_run: false
        };

        if (serviceId) {
            configObject['service_id'] = serviceId;
        }

        // this.commercialidsService.updateCommercialIds(); ?? nu stiu daca e nevoie de asta
        return this.subscriptionMgmtService.redeem(configObject).pipe(
            map(
                resp => {
                    const reason = this.usefulService.getNested(resp, null, 'reason');
                    if (reason && reason === this.subscriptionsValuesService.redeemReasons.valid) {
                        // uneori nu vine eventul de subscriptions changed
                        this.messageService.sendMessage(this.valuesService.events.subscriptionChanged, {});
                    }
                    return resp;
                }
            ),
            catchError(
                error => {
                    throw error;
                }
            )
        );
    }

    addRedeemInfo(bundle) {
        return {
            endDateFormatted: this.subscriptionsHelperService.getDateFormatted(bundle.end_date),
            image: this.isubscriptionsService.getImage(bundle),
            daysRemaining: this.isubscriptionsService.daysRemaining(bundle),
            serviceTypeLocal: bundle.service_type === this.subscriptionsValuesService.serviceType.license ? 'subscription.type.license' : 'subscription.type.subscription',
            hideEndDate: this.subscriptionsService.hideEndDate(bundle)
        };
    }

    getServicesByServiceId(reedemResponse) {
        const bundles = this.subscriptionsService.getFiltered();
        const compatibleServiceIds = reedemResponse?.data?.compatible_services ?? [];
        const serviceType = reedemResponse?.data?.service_type ?? null;

        if (!compatibleServiceIds.length || !serviceType) {
            return [];
        }

        if (!serviceType) {
            return [];
        }

        return bundles[serviceType].filter(bundle => compatibleServiceIds.includes(bundle.service_id));
    }

    getService(serviceId) {
        const bundles = this.subscriptionsService.get();

        for (const service of bundles[this.subscriptionsValuesService.serviceType.license]) {
            if (service.service_id === serviceId) {
                return {...service};
            }
        }

        for (const service of bundles[this.subscriptionsValuesService.serviceType.subscription]) {
            if (service.service_id === serviceId) {
                return {...service};
            }
        }
        return null;
    }

    setNewDeviceId(deviceId) {
        this.newDeviceIds.add(deviceId);
    }

    getNewDevicesIds() {
        return this.newDeviceIds;
    }

    removeNewDeviceId(deviceId) {
        this.newDeviceIds.delete(deviceId);
    }

    /**
     * Checks if subscription has compatible bundles
     * @public
     * @memberof RedeemService
     * @param {RedeemDataModel} redeem The redeem data
     * @returns {boolean} Returns true if subscription has compatible bundles
     */
    public hasCompatibleBundles(redeem: RedeemDataModel): boolean {
        const compatibleBundles = this.getServicesByServiceId(redeem);
        return !!(compatibleBundles.length && !this.valuesService.techassistProductsDetails[redeem.data.bundle_id]);
    }

}

// External
import { Injectable } from '@angular/core';

// Internal
import { environment } from '../../../../environments/environment';
import { ValuesService } from '../../values/values.service';
import { ConfigService, DeployType, Hosts } from '../../config/config.service';
import { Scripts } from './models/Scripts.model';

@Injectable({
    providedIn: 'root'
})

export class ScriptService {

    private readonly scripts: any = {};

    generateAdobeSrc = () => {
        if (!environment.production) {
            return 'https://assets.adobedtm.com/8a93f8486ba4/5492896ad67e/launch-3e7065dd10db-staging.min.js';
        } else if ((window.location.hostname).indexOf('nmbapp.net') !== -1 || window.location.hostname === 'alpha-central.bitdefender.com') {
            return 'https://assets.adobedtm.com/8a93f8486ba4/5492896ad67e/launch-3e7065dd10db-staging.min.js';
        } else {
            return 'https://assets.adobedtm.com/8a93f8486ba4/5492896ad67e/launch-b1f76be4d2ee.min.js';
        }
    };

    generateCheckoutSdkSrc = () => {
        let url = '';
        const hostname = window?.location?.hostname;

        if (window?.['_glCnfg']?.type === 311) {
            if (this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.BETA]?.url) {
                url = this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.BETA]?.url;
            } else {
                url = this.valuesService.checkoutSDKMapping?.[Hosts.DEFAULT]?.[DeployType.BETA]?.url;
            }
        } else {
            if (this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.PROD]?.url) {
                url = this.valuesService.checkoutSDKMapping?.[hostname]?.[DeployType.PROD]?.url;
            } else {
                url = this.valuesService.checkoutSDKMapping?.[Hosts.DEFAULT]?.[DeployType.PROD]?.url;
            }
        }
        return url;
    };

    adobeActiveStatus = () => {
        if (!this.configService.getOmniture()) {
            return false;
        }

        if (window.hasOwnProperty('_glCnfg')) {
            return window['_glCnfg'];
        } else {
            if ((window.location.hostname).indexOf('central.bitdefender.com') !== -1 && environment.production) {
                return true;
            } else if(!environment.production){
                return true;
            } else {
                return false;
            }
        }
    };

    private readonly myScripts = {
        [Scripts.LiveChat]: {
            name: Scripts.LiveChat,
            src: 'https://cdn.livechatinc.com/tracking.js',
            active: this.configService.getPrivacyChat(),
            consentDependable: false
        },
        [Scripts.Config]: {
            name: Scripts.Config,
            src: 'config.js',
            active: true,
            consentDependable: false
        },
        [Scripts.Adobe]: {
            name: Scripts.Adobe,
            src: this.generateAdobeSrc(),
            active: this.adobeActiveStatus(),
            consentDependable: false
        },
        [Scripts.UpdateBillingSdk]: {
            name: Scripts.UpdateBillingSdk,
            src: this.generateCheckoutSdkSrc(),
            active: this.configService.getSubscriptionsSdk(),
            consentDependable: false
        }, //NIMBUS LOCATION. THIS IS FOR BETA
        [Scripts.Consent]: {
            name: Scripts.Consent,
            src: 'https://tags.tiqcdn.com/utag/orange/securitee/prod/utag.js',
            active: true,
            consentDependable: false
        }
    };

    constructor(
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService
    ) {
        for (const script in this.myScripts) {
            this.scripts[this.myScripts[script].name] = {
                loaded: false,
                src: this.myScripts[script].src,
                active: this.myScripts[script].active,
                consentDependable: this.myScripts[script].consentDependable,
                attributes: this.myScripts[script].attributes
            };
        }
    }

    parseCustomJSON(stringJSON) {
        // remove both ends
        const tempString = stringJSON.slice(1).slice(0,-1);
        const tempArr = tempString.split(',');
        const tempObj = {};
        for (const tempElement of tempArr) {
            tempObj[tempElement.split(':')[0]] = tempElement.split(':')[1];
        }
        return tempObj;
    }

    // load a single or multiple scripts
    load(...scripts: Scripts[]) {
        const promises: any[] = [];
        // push the returned promise of each loadScript call
        scripts.forEach(script => {
            promises.push(this.loadScript(script));
        });
        // return promise.all that resolves when all promises are resolved
        return Promise.all(promises);
    }

    // load the script
    loadScript(name: Scripts) {
        return new Promise((resolve, reject) => {

            //> Cod care poate fi folosit daca adaugam scripturi care depind de consent. Momentan nu este nevoie.
            // let cookieBotConsent = this.cookieService.get("CookieConsent");
            // if(this.scripts[name].consentDependable && cookieBotConsent) {
            //     let consent = this.parseCustomJSON(cookieBotConsent);
            //     if(consent['marketing'] === 'false' || consent['statistics'] === 'false') {
            //         resolve({ script: name, loaded: true, active: false, status: 'Script disabled' });
            //         return;
            //     }
            // }
            // resolve if already loaded
            if (!this.scripts[name].active) {
                resolve({ script: name, loaded: false, active: false, status: 'Script disabled' });
            } else if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (this.myScripts.hasOwnProperty(name) && this.myScripts[name].hasOwnProperty('attributes')) {
                    const _attrs = this.myScripts[name]['attributes'];
                    for (const attribute in _attrs) {
                        script.setAttribute(attribute, _attrs[attribute]);
                    }
                }
                document.getElementsByTagName('head')[0].appendChild(script);
                // cross browser handling of onLoaded event
                // if (script.readyState) {  // IE
                //     script.onreadystatechange = () => {
                //         if (script.readyState === 'loaded' || script.readyState === 'complete') {
                //             script.onreadystatechange = null;
                //             this.scripts[name].loaded = true;
                //             resolve({ script: name, loaded: true, status: 'Loaded' });
                //         }
                //     };
                // } else {  // Others
                script.onload = () => {
                    this.scripts[name].loaded = true;
                    resolve({ script: name, loaded: true, status: 'Loaded' });
                };
                // }
                script.onerror = () => {
                    resolve({ script: name, loaded: false, status: 'Loaded' });
                };
                // finally append the script tag in the DOM
            }
        });
    }

    // return ADobe script loaded status
    isAdobeScriptLoaded() {
        return this.scripts?.adobe?.loaded;
    }
}

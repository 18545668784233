// External
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, skipWhile } from 'rxjs/operators';

// Internal
import { ValuesService } from '../../../values/values.service';
import { RenewalInfoService } from '../../requests/connect-renewal-info-service/connect-renewal-info.service';
import { RenewalInfoModel } from '../../../../common/models/Services.model';
import { ConfigService, BITDEFENDER_PARTNER_ID } from '../../../../common/config/config.service';

@Injectable({
    providedIn: 'root'
})

export class PaymentsService {

    private readonly onlistSubscriptions$: BehaviorSubject<string> = new BehaviorSubject<string>(this.valuesService.processServiceState.WAITING);

    private subscriptions: RenewalInfoModel[];
    private markToUpdateBiSubs = true;

    constructor(
        private readonly valuesService: ValuesService,
        private readonly cookieService: CookieService,
        private readonly renewalInfoService: RenewalInfoService,
        private readonly configService: ConfigService
    ) {}

    /**
     * Computes payment list
     * @public
     * @memberof PaymentsService
     * @returns {Observable} The request response
     */
    public list(): Observable<any> {
        if (!this.configService.config.subscriptionsTabs.payments) {
            return of(this.subscriptions);
        }

        if (!this.markToUpdateBiSubs) {
            return of(this.subscriptions);
        }

        if (this.onlistSubscriptions$.value === this.valuesService.processServiceState.INPROGRESS) {
            return this.onlistSubscriptions$.asObservable()
            .pipe(
                skipWhile(res => res !== this.valuesService.processServiceState.DONE)
            );
        } else {
            this.onlistSubscriptions$.next(this.valuesService.processServiceState.INPROGRESS);
            return this.renewalInfoService.getUserSubscriptions()
            .pipe(
                map(resp => {
                    this.subscriptions = this.getFilteredServices(resp);

                    if (this.subscriptions.length >= 1) {
                        const currentDate = new Date();
                        const timeToExpire = new Date(currentDate.getTime() + this.valuesService.MILISECONDS_IN_A_WEEK);
                        this.cookieService.set(this.valuesService.cookieShowAr, 'true', timeToExpire, '/', '', true);
                        sessionStorage.setItem(this.valuesService.cookieShowAr, JSON.stringify({ resp, expiry: timeToExpire.getTime() }));
                    } else {
                        const currentDate = new Date();
                        const timeToExpire = new Date(currentDate.getTime() + (this.valuesService.MILISECONDS_IN_AN_HOUR / 2));
                        this.cookieService.set(this.valuesService.cookieShowAr, 'false', timeToExpire, '/', '', true);
                        sessionStorage.setItem(this.valuesService.cookieShowAr, JSON.stringify({ resp, expiry: timeToExpire.getTime() }));
                    }
                    this.markToUpdateBiSubs = false;
                    return of(this.subscriptions);
                }),
                catchError(err => {
                    this.markToUpdateBiSubs = true;
                    return of(err);
                }),
                finalize(() => {
                    this.onlistSubscriptions$.next(this.valuesService.processServiceState.DONE);
                })
            );
        }
    }

    /**
     * Gets the payment subscriptions
     * @public
     * @memberof PaymentsService
     * @returns {RenewalInfoModel[]} The payment subscriptions array
     */
    public getRenewalSubscriptions(): RenewalInfoModel[] {
        return this.subscriptions?.length ? this.subscriptions : [];
    }

    /**
     * Check if auto renewal feature exists
     * @public
     * @memberof PaymentsService
     * @returns {boolean} Reutnrs true if auto renewal feature exists
     */
    public hasRenewalCookie(): boolean {
        const cookie = this.cookieService.get(this.valuesService.cookieShowAr);
        const storage = sessionStorage.getItem(this.valuesService.cookieShowAr);

        return !!(cookie === 'true' && storage);
    }

    /**
     * Computes the array of services that are Bitdefender
     * @private
     * @memberof PaymentsService
     * @param {RenewalInfoModel[]} services The array of all services
     * @returns {RenewalInfoModel[]} The array of filtered Bitdefender services
     */
    private getFilteredServices(services: RenewalInfoModel[]): RenewalInfoModel[] {
        const filteresServices = [];
        for (const service of services) {
            if (service.BundleId.includes(BITDEFENDER_PARTNER_ID)) {
                filteresServices.push(service);
            }
        }
        return filteresServices;
    }
}

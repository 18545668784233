// External
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, catchError, map } from 'rxjs';
import { tv4 } from 'tv4';

// Internal
import { RequestsService } from '../../global/request-service/requests.service';
import { UtilsCommonService } from '../../../utils/utils-common.service';
import { ValuesService } from '../../../../common/values/values.service';
import { schemas } from '../../../../common/models/schemas';
import { ConfigService } from '../../../../common/config/config.service';
import { DeviceAllocationMgmtResponseModel } from '../../../../common/models/Services.model';
import { SubscriptionsValuesService } from '../../../../common/values/subscriptions.values.service';

@Injectable({
    providedIn: 'root'
})

export class SubscriptionMgmtService {

    constructor(
        private readonly cookieService: CookieService,
        private readonly requestService: RequestsService,
        private readonly utilsCommon: UtilsCommonService,
        private readonly valuesService: ValuesService,
        private readonly configService: ConfigService,
        private readonly subscriptionsValuesService: SubscriptionsValuesService
    ) { }

    /**
     * This method is used to get list of subscriptions
     * @returns Response from server
     */
    list(): Observable<any> {
        let json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "list",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                }
            }
        };

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (res: any) => {
                    if (res.error) {
                        throw res.error
                    }
                    return res.result;
                }
            ),
            catchError((error) => {
                console.error("Error mgmt >> dupa req", error);
                throw error;
            }),
        )
    }

    /**
     * This method is used to remove the device from subscription
     * @param {object} Contains mandatory `devices`
     * @returns Response from server
     */
    removeDeviceFromSubscription(devices): Observable<any> {
        if (this.utilsCommon.isEmptyObject(devices)) {
            return of("Devices object empty");
        }

        const arr = [];
        let id = parseInt((Math.random() * 100).toString(), 10);
        if (devices.apps && !this.utilsCommon.isEmptyObject(devices.apps)) {
            for (let dev in devices.apps) {
                let json = {
                    id: id++,
                    jsonrpc: this.valuesService.jsonrpc,
                    method: "remove_device",
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(this.valuesService.cookieToken),
                            device_id: this.valuesService.connectDeviceId,
                            app_id: this.valuesService.connectAppId
                        },
                        connect_destination: {
                            device_id: devices.device_id,
                            app_id: devices.apps[dev].app_id
                        }
                    }
                };
                arr.push(json);
            }
        } else {
            return of([]);
        }

        if (arr.length !== 0) {
            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.subscriptionMgmtService,
                arr,
                "POST"
            );
        }
    }

    codeIsEmpty(params) {
        return params && params.hasOwnProperty("code") && !params.code;
    }

    codeIsInvalid(params) {
        return !params || !params.code || (!tv4.validate(params.code, schemas.schemaREDEEM) && !tv4.validate(params.code, schemas.schemaUuid));
    }

    /**
     * Check if redeem code is LV2
     *
     * @private
     * @memberof SubscriptionMgmtService
     * @param {Object} params
     * @returns {Boolean}
     */
    private isLevelTwoCode(params): boolean {
        return params?.code && tv4.validate(params.code, schemas.schemaLevelTwoRedeem);
    }

    noOptions(params) {
        return params && params.service_id === this.valuesService.onboardingCompatibleOptions.noOption;
    }

    /**
     * This method is used to redeem a code with dry_run - if the subscription will be applied or if a view will be returned
     * @param {object} Contains mandatory `params.code`
     * @returns Response from server
     */
    redeem(params): Observable<any> {
        if (this.noOptions(params)) {
            return of({
                reason: this.subscriptionsValuesService.redeemReasons.noOptions
            });
        }

        if (this.codeIsEmpty(params)) {
            return of({
                reason: this.subscriptionsValuesService.redeemReasons.empty
            });
        }

        if (this.codeIsInvalid(params)) {
            if (this.isLevelTwoCode(params)) {
                return of({
                    reason: this.subscriptionsValuesService.redeemReasons.levelTwo
                });
            } else {
                return of({
                    reason: this.subscriptionsValuesService.redeemReasons.invalid
                });
            }
        }

        const json = {
            id: parseInt((Math.random() * 100).toString(), 10),
            jsonrpc: this.valuesService.jsonrpc,
            method: "redeem",
            params: {
                connect_source: {
                    user_token: this.cookieService.get(this.valuesService.cookieToken),
                    device_id: this.valuesService.connectDeviceId,
                    app_id: this.valuesService.connectAppId
                },
                redeem_code: params.code,
                dry_run: params.dry_run ? params.dry_run : false
            }
        };

        if (params.service_id) {
            json.params['service_id'] = params.service_id;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionMgmtService,
            json,
            "POST"
        ).pipe(
            map(
                (resp: any) => {
                    if (Array.isArray(resp) && resp.length === 0) {
                        resp = {
                            result: {
                                reason: this.subscriptionsValuesService.redeemReasons.unexpectedError
                            }
                        }
                    }
                    return resp.result;
                }
            ),
            catchError((error) => {
                let resp = null;
                if (error.message && error.message.statusText) {
                    resp = {
                        result: {
                            reason: error.message.statusText
                        }
                    }
                } else if (error.error && error.error.message) {
                    resp = {
                        result: {
                            reason: error.error.message
                        }
                    }
                } else {
                    resp = {
                        result: {
                            reason: this.subscriptionsValuesService.redeemReasons.unexpectedError
                        }
                    }
                }
                return of(resp.result);
            })
        );
    }

    switchDeviceService(configObject): Observable<DeviceAllocationMgmtResponseModel[]> {
        let arr = [];
        for (let i = 0; i < configObject.app_ids.length; i++) {
            let json = {
                id: 1+i,
                jsonrpc: this.valuesService.jsonrpc,
                method: "switch_device_service",
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        app_id: configObject.app_ids[i],
                        device_id: configObject.device_id
                    },
                service_id: configObject.service_id
                }
            }
            arr.push(json);
        }

        if (arr) {
            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.subscriptionMgmtService,
                arr,
                "POST"
            );
        }
    }

    reallocateDevicesBatch(deviceIds, applicationsIds, serviceId): Observable<any> {
        const jsons = [];
        let id = parseInt((Math.random() * 100000).toString(), 10);

        for (const device of deviceIds) {
            for (const app of applicationsIds[device].appIds) {
                let json = {
                    id: id++,
                    jsonrpc: this.valuesService.jsonrpc,
                    method: "switch_device_service",
                    params: {
                        connect_source: {
                            user_token: this.cookieService.get(this.valuesService.cookieToken),
                            app_id: app,
                            device_id: device
                        },
                    service_id: serviceId
                    }
                }
                jsons.push(json);
            }
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionMgmtService,
            jsons,
            'POST'
        ).pipe(
            map(
                results => {
                    let response = {
                        error: false,
                        success: false
                    };

                    if (Array.isArray(results)) {
                        for (const resp of results) {
                            if (resp?.result?.status === 0) {
                                response.success = true;
                            } else {
                                response.error = true;
                            }
                        }
                    }

                    // No valid response - throw error
                    if (!response.success) {
                        throw 'error';
                    }
                    return response;
                }
            ),
            catchError(
                err => {
                    throw err;
                }
            )
        );
    }

    removeDeviceProtection(configObject): Observable<DeviceAllocationMgmtResponseModel[]> {
        let arr = [];
        for (let i = 0; i < configObject.appIds.length; i++) {
            let json = {
                id: 1+i,
                jsonrpc: this.valuesService.jsonrpc,
                method: "remove_device",
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        app_id: this.valuesService.connectAppId,
                        device_id: this.valuesService.connectDeviceId
                    },
                    connect_destination: {
                        device_id: configObject.deviceId,
                        app_id: configObject.appIds[i]
                    }
                }
            }
            arr.push(json);
        }

        if (arr) {
            return this.requestService.make(
                this.configService.config.nimbusServer,
                this.valuesService.subscriptionMgmtService,
                arr,
                "POST"
            );
        }
    }

    /**
     * Method sends a batch request to remove the secondary of a service.
     * This method can only be used by the primary of the service.
     * @public
     * @memberof SubscriptionMgmtService
     *
     * @param {string} serviceId
     * @param {Array} shareIds
     * @returns {Observable} request response
     */
    public invalidateSecondaryBatch(serviceId: string, shareIds: Array<string>): Observable<any> {
        if (!serviceId || !Array.isArray(shareIds) || shareIds?.length === 0) {
            return of('Invalid params');
        }

        let counter = parseInt((Math.random() * 100).toString(), 10);
        const batch = [];

        for (const shareId of shareIds) {
            const json = {
                id: counter,
                jsonrpc: this.valuesService.jsonrpc,
                method: 'invalidate_secondary',
                params: {
                    connect_source: {
                        user_token: this.cookieService.get(this.valuesService.cookieToken),
                        device_id: this.valuesService.connectDeviceId,
                        app_id: this.valuesService.connectAppId
                    },
                    managed_share_id: shareId,
                    service_id: serviceId
                }
            };
            batch.push(json);
            counter++;
        }

        return this.requestService.make(
            this.configService.config.nimbusServer,
            this.valuesService.subscriptionMgmtService,
            batch,
            'POST'
        )
        .pipe(
            map(results => {
                const response = {
                    error: false,
                    success: false
                };

                if (!Array.isArray(results)) {
                    throw results;
                }

                for (const resp of results) {
                    if (resp?.result?.status === 0) {
                        response.success = true;
                    } else {
                        response.error = true;
                    }
                }

                // If no valid response was received, throw error
                if (!response.success) {
                    throw new Error('error');
                }
                return response;
            }),
            catchError(err => {
                throw err;
            })
        );
    }

}
